import { useState, type ReactNode } from 'react';
import { EditorSteps } from '../constants';

export const EDITOR_MODE = 'editorMode';

export interface StepWithCondition {
    name: EditorSteps;
    isEnabled: boolean;
    sublabel?: ReactNode;
    component?: ReactNode;
}

export interface TileEditorStepsValue {
    currentEditorStep: EditorSteps | undefined;
    editorSteps: StepWithCondition[];
    canMoveToNextStep: boolean;
    isDocked?: boolean;
    setCurrentEditorStep: (step: string | undefined) => void;
    setIsDocked?: (isNowDocked: boolean) => void;
    nextStep: () => void;
}

/**
 * Handles state management for a set of steps
 */
export const useTileEditorSteps = (
    steps: StepWithCondition[], 
    initialStep: EditorSteps | undefined,
    onDockEditorStep?: () => void,
    onReleaseEditorStep?: () => void,
    onStepChange?: (newStep: EditorSteps | undefined) => void
): TileEditorStepsValue => {
    const [currentStepName, setCurrentStepName] = useState(function findInitialStep(): EditorSteps | undefined {
        // Editing an existing tile and therefore we can use undefined
        if (!initialStep) {
            return undefined;
        }

        const foundStepIndex = steps.findIndex((s) => s.name === initialStep);
        const foundStep = foundStepIndex === -1 ? undefined : steps[foundStepIndex];

        if (foundStep != null && foundStep.isEnabled) {
            return foundStep.name;
        }

        const closestAvailableStep = steps.reduce(
            ({ availableStep, index }, step, i) => {
                if (availableStep == null && step.isEnabled) {
                    return { availableStep: step.name, index: i };
                }

                if (step.isEnabled && i < foundStepIndex) {
                    return { availableStep: step.name, index: i };
                }

                return { availableStep, index };
            },
            { availableStep: undefined, index: -1 } as { availableStep?: EditorSteps; index: number }
        )?.availableStep;

        onStepChange?.(closestAvailableStep ?? foundStep?.name);

        // If we have no available step, pick the found one even if it's disabled
        // to match previous behaviour
        return closestAvailableStep ?? foundStep?.name ?? undefined;
    });

    const handleSetCurrentStepName = (nextEditorStep?: EditorSteps | undefined) => {
        setCurrentStepName(nextEditorStep);
        onStepChange?.(nextEditorStep);
    };

    const availableSteps = steps.filter((step) => step.isEnabled);
    const stepInFlow = availableSteps.findIndex((step) => step.name === currentStepName);

    const canMoveToNextStep = stepInFlow < availableSteps.length - 1;

    const [isDocked, setIsDockedState] = useState<boolean>(false);

    const setIsDocked = (isNowDocked: boolean) => {
        // Handle layout based on docking/undocking
        if (isNowDocked) {
            onDockEditorStep?.();
        } else {
            onReleaseEditorStep?.();
        }

        setIsDockedState(isNowDocked);
    };

    const nextStep = () => {
        if (canMoveToNextStep) {
            handleSetCurrentStepName(availableSteps[stepInFlow + 1]?.name);
        }
    };

    return {
        currentEditorStep: currentStepName,
        canMoveToNextStep,
        isDocked, 
        setCurrentEditorStep: (stepName) => {
            const newCurrentStep = steps.find((s) => s.name === stepName);

            if (newCurrentStep && newCurrentStep?.isEnabled === false) {
                return;
            }

            return handleSetCurrentStepName(newCurrentStep?.name);
        },
        nextStep,
        setIsDocked,
        editorSteps: steps
    };
};
