import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import * as Popover from '@radix-ui/react-popover';
import { useDOMElement } from 'components/hooks/useDOMElement';
import { useCallback, useEffect, useRef } from 'react';
import { useTileEditorStepsContext } from 'ui/editor/dataStream/TileEditor/contexts/TileEditorStepsContext';
import { EditorSteps } from '../constants';

const radixPointerEventType = 'dismissableLayer.pointerDownOutside';

/**
 * @returns Steps such as: Data Stream, Objects, Configure, Shaping (step names subject to change)
 */
export const DataStreamTileEditorStepSelector = () => {
    const stepComponentRef = useRef<HTMLDivElement>(null);

    const { 
        currentEditorStep, 
        editorSteps, 
        isDocked,
        setCurrentEditorStep
    } = useTileEditorStepsContext();

    const container = useDOMElement('dialogContent');

    // Set up esc closing step popover
    const handleClose = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setCurrentEditorStep(undefined);
        }
    }, [setCurrentEditorStep]);

    useEffect(() => {
        window.addEventListener('keyup', handleClose);
        return () => window.removeEventListener('keyup', handleClose);
    });

    return (
        <div 
            className='w-64 overflow-auto text-base border border-dividerTertiary tile-scroll-overflow scrollbar-thumb-statusUnknownPrimary'
            id='dataStreamEditorSteps'
        >
            <div 
                className='flex flex-col'
                ref={stepComponentRef}
            >
                {editorSteps
                    .filter(({ isEnabled }) => isEnabled)
                    .map(({ name, sublabel, component }) => {
                        const isOpen = currentEditorStep === name;

                        return (
                            <Popover.Root 
                                key={name} 
                                open={isOpen}
                            >
                                <Popover.Trigger
                                    className={cn(
                                        'font-semibold outline-none w-full text-left py-4 px-[23px] shrink-0 h-[79px] text-textPrimary border-b-[1px] border-dividerTertiary disabled:text-textDisabled',
                                        name === currentEditorStep && 'bg-tagBackground'
                                    )}
                                    data-testid={`step-${name}`}
                                    onClick={() => setCurrentEditorStep(isOpen ? undefined : name)}
                                >
                                    <Text.H4 className='text-textSecondary'>{name}</Text.H4>
                                    <Text.H4 className='font-semibold'>
                                        {sublabel}
                                    </Text.H4>
                                </Popover.Trigger>

                                {/* When a step is pinned we don't want to show the popover 
                                    as it will be in the main visualisation panel */}
                                {!isDocked && 
                                    <Popover.Portal container={container} >
                                        <Popover.Content 
                                            side='right'
                                            align='start'
                                            sideOffset={3}
                                            alignOffset={-1}
                                            collisionPadding={14}
                                            onInteractOutside={(e) => {
                                                if (
                                                    e.type === radixPointerEventType && 
                                                    !stepComponentRef.current?.contains(e.target as any)
                                                ) {
                                                    setCurrentEditorStep(undefined);
                                                }
                                            }}
                                            className={cn(
                                                'border bg-tileBackground z-10 flex flex-col border-dividerTertiary text-textSecondary shadow-even shadow-tileEditorShadow min-h-[200px] max-h-[min(60vh,650px)] w-[57.5vw] max-w-[900px)',
                                                currentEditorStep === EditorSteps.timeframe && 'max-w-[600px]',
                                                currentEditorStep === EditorSteps.objects && '[min(70vh,850px)]'
                                            )}>
                                            {component}
                                            
                                            <Popover.Arrow className='fill-dividerTertiary' />
                                        </Popover.Content>
                                    </Popover.Portal>
                                }
                            </Popover.Root>
                        );
                    })}
            </div>
        </div>
    );
};
