import VisualisationSkeleton from 'components/VisualisationSkeleton';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { Button } from '@/components/Button';
import { EditorSteps } from '../constants';
import NoDataPlaceholder from 'components/NoDataPlaceholder';

export const NewTilePrompt = () => {
    const { setCurrentEditorStep, currentEditorStep } = useTileEditorStepsContext();

    return (
        <div className='relative flex flex-col justify-center w-full h-full min-w-0 min-h-0 isolate'>
            <div className='absolute inset-0 blur-[10px] -z-10'>
                <VisualisationSkeleton 
                    type='data-stream-line-graph'
                    shimmer={false}
                />
            </div>
            
            <div className='flex flex-col space-y-4'>
                <NoDataPlaceholder message='No data stream selected' />
                
                {currentEditorStep !== EditorSteps.dataStream &&
                    <Button 
                        onClick={() => setCurrentEditorStep(EditorSteps.dataStream)}
                        className='mx-auto mt-8 text-base'
                    >
                        Browse data streams
                    </Button>
                }
            </div>
        </div>      
    );
};
