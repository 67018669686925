import { formatValueAsColumn, ResolvedShapeConfig, ShapeName } from '@squaredup/data-streams';
import { isDefined } from '@squaredup/utilities';
import Button from 'components/button';
import Field from 'components/forms/field/Field';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Modal, { ModalButtons } from 'components/Modal';
import { Dispatch } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { getFilterColumns } from 'ui/editor/dataStream/utilities/filters';
import { DataStreamMetadataAction } from './MetadataEditorReducer';
import { CloneStateColumn, ComparisonStateColumn, StateColumn } from './MetadataEditorState';

export type ComparisonForm = {
    comparisonName?: string;
    sourceColumn: string;
    compareTo: string;
    comparisonType: 'percentage' | 'absolute';
    shape?: {
        name: ShapeName;
        config: Record<string, unknown>;
    };
};

interface MetadataEditorComparisonModalProps {
    existingComparison?: ComparisonForm;
    selectedComparisonColumn?: string;
    columns: (StateColumn | CloneStateColumn | ComparisonStateColumn)[];
    dispatch: Dispatch<DataStreamMetadataAction>;
    onClose: () => void;
}

type ComparisonSampleColumn = StateColumn | CloneStateColumn | ComparisonStateColumn | undefined;

const getSampleShapeConfig = (
    comparisonType: 'absolute' | 'percentage',
    existingComparison?: ComparisonForm,
    sampleColumn?: ComparisonSampleColumn
) => {
    if (!existingComparison && comparisonType === 'percentage') {
        return {
            decimalPlaces: 0
        };
    }

    return (
        existingComparison?.shape?.config ??
        sampleColumn?.metadata?.rawShapeConfig ?? {
            decimalPlaces: 2
        }
    );
};

const getComparisonSampleValue = (
    columnA: ComparisonSampleColumn,
    columnB: ComparisonSampleColumn,
    comparisonType: 'percentage' | 'absolute',
    sampleValueShape: ShapeName,
    sampleValueConfig: Record<string, unknown>
) => {
    if (
        columnA &&
        columnB &&
        typeof columnA.sampleValue.value === 'number' &&
        typeof columnB.sampleValue.value === 'number'
    ) {
        const valueA = columnA.sampleValue.value;
        const valueB = columnB.sampleValue.value;

        if (comparisonType === 'percentage' && valueB === 0) {
            return '-';
        }

        const calculatedValue = comparisonType === 'absolute' ? valueA - valueB : (100 * (valueA - valueB)) / valueB;

        return formatValueAsColumn(
            {
                value: calculatedValue,
                column: {
                    name: 'sample',
                    displayName: 'Sample',
                    role: 'value' as const,
                    valueShapeName: 'shape_number' as const,
                    shapeName: sampleValueShape,
                    displayIndex: 0,
                    rawShapeConfig: sampleValueConfig,
                    visible: true,
                    expand: []
                }
            },
            {
                rows: [],
                columns: [],
                formatSpec: {},
                resolvedShapeConfig: sampleValueConfig as ResolvedShapeConfig
            }
        );
    }

    return undefined;
};

export const MetadataEditorComparisonModal: React.FC<MetadataEditorComparisonModalProps> = ({
    existingComparison,
    selectedComparisonColumn,
    columns,
    dispatch,
    onClose
}) => {
    const container = useDOMElement('dialogContent');

    const methods = useForm<Omit<ComparisonForm, 'shape'>>({
        defaultValues: {
            comparisonType: 'absolute',
            sourceColumn: selectedComparisonColumn,
            ...(existingComparison ?? {})
        }
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting }
    } = methods;

    const values = methods.watch();

    // For comparison columns, ignore any non-number columns that are already clones/comparisons
    const comparisonColumns = columns
        .filter((c) => !c.isClone && !c.isComparison && c.metadata?.valueShapeName === 'shape_number')
        .map((c) => c.metadata)
        .filter(isDefined);

    const columnA = columns.find((c) => c.columnName === values.sourceColumn);
    const columnB = columns.find((c) => c.columnName === values.compareTo);

    // Filter out the selected column from the comparison options, and ensure that the selected columns are of the same shape
    const columnAOptions = comparisonColumns.filter(
        (c) => c.name !== values.compareTo && (columnB ? c.shapeName === columnB.shape.name : true)
    );
    const columnBOptions = comparisonColumns.filter(
        (c) => c.name !== values.sourceColumn && (columnA ? c.shapeName === columnA.shape.name : true)
    );

    const sampleValueShape =
        values.comparisonType === 'percentage' ? 'shape_percent' : columnA?.metadata?.targetShapeName ?? 'shape_number';

    const shapeConfig = getSampleShapeConfig(values.comparisonType, existingComparison, columnA);

    const sampleValue = getComparisonSampleValue(
        columnA,
        columnB,
        values.comparisonType,
        sampleValueShape,
        shapeConfig
    );

    const onSubmit = (formValues: ComparisonForm) => {
        dispatch({
            type: existingComparison?.comparisonName ? 'updateComparisonColumn' : 'compareColumn',
            comparisonName: existingComparison?.comparisonName,
            sourceColumnName: formValues.sourceColumn,
            compareTo: formValues.compareTo,
            comparisonType: formValues.comparisonType,
            shape: {
                name: sampleValueShape,
                config: shapeConfig
            }
        });
        onClose();
    };

    return (
        <Modal
            title={existingComparison ? 'Edit comparison' : 'Add comparison'}
            modalClassName='w-full max-w-[644px]'
            headerBorder={true}
            container={container}
            close={onClose}
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='px-8 py-6'>
                        <div className='mb-6'>
                            <p>
                                Comparisons compare two numerical columns, outputting either an absolute or percentage
                                change.{' '}
                                <a
                                    href='https://docs.squaredup.com/first-steps/tiles/data-tiles#comparison-columns'
                                    target='_blank'
                                    rel='noreferrer'
                                    className='text-textLink'
                                >
                                    Learn more.
                                </a>
                            </p>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            <Field.Label label='Column A' className='mt-0 w-full'>
                                <EditorSelect
                                    name='sourceColumn'
                                    options={getFilterColumns(columnAOptions)}
                                    placeholder='Select column'
                                    validation={{ required: true }}
                                    isClearable={true}
                                />
                            </Field.Label>

                            <Field.Label label='Column B' className='mt-0 w-full'>
                                <EditorSelect
                                    name='compareTo'
                                    options={getFilterColumns(columnBOptions)}
                                    placeholder='Select column'
                                    validation={{ required: true }}
                                    isClearable={true}
                                />
                            </Field.Label>

                            <Field.Label label='Output' className='mt-0 w-full'>
                                <span>
                                    <Field.Switch
                                        name='comparisonType'
                                        allowNone={false}
                                        options={[
                                            { label: 'Absolute', value: 'absolute' },
                                            { label: 'Percentage', value: 'percentage' }
                                        ]}
                                    />
                                </span>
                            </Field.Label>

                            <Field.Label label='Preview' className='mt-0 w-full'>
                                <p className='text-sm text-textSecondary'>{sampleValue ?? '-'}</p>
                            </Field.Label>
                        </div>
                    </div>
                    <ModalButtons hideTopMargin={true}>
                        <Button variant='tertiary' type='button' disabled={isSubmitting} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={isSubmitting || !isValid}>
                            {existingComparison ? 'Update' : 'Add'}
                        </Button>
                    </ModalButtons>
                </form>
            </FormProvider>
        </Modal>
    );
};
