import { useFlag } from 'lib/useFlag';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

/**
 * Hook to determine if the new editor layout should be used. This relies on TileEditorContext so cannot be
 * used at the top level of an editor (before the context is defined).
 */
export const useIsNewEditorLayout = () => {
    const { isDatasetMode } = useTileEditorContext();
    const flagEnabled = useFlag('newDataStreamEditor');

    return Boolean(!isDatasetMode && flagEnabled);
};
