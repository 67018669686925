import type { ComponentProps, FC } from 'react';
import type { UnionToIntersection } from 'type-fest';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';

/**
 * Displays the component for the current step according to the TileEditorStepsContext.
 * 
 * `stepProps` contains values for all possible props the steps might need, as we don't
 * know which one will end up being rendered.
 */
export const DataStreamTileEditorCurrentStep = <T extends FC<any>>({
    stepComponents,
    stepProps
}: {
    stepComponents: Record<string, T>;
    stepProps: Omit<UnionToIntersection<ComponentProps<T>>, 'children'>;
}) => {
    const { currentEditorStep } = useTileEditorStepsContext();

    const CurrentStep: FC<typeof stepProps> | undefined = 
        currentEditorStep == null ? undefined : stepComponents[currentEditorStep];

    return (
        <div className='flex-1 min-w-0 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
            {CurrentStep && <CurrentStep {...stepProps} />}
        </div>
    );
};
