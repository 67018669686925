import { Node } from '@squaredup/graph';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface ObjectListProps {
    objects: Node[];
    resetScrollKey: string;
    hasNextObjectsPage?: boolean;
    isFetchingNextObjectsPage: boolean;
    isFetchingObjects: boolean;
    fetchNextObjectsPage: () => void;
    renderObjectRow: (object: Node, index: number) => React.ReactNode;
}

export const ObjectList: React.FC<ObjectListProps> = ({
    objects,
    resetScrollKey,
    hasNextObjectsPage,
    isFetchingNextObjectsPage,
    isFetchingObjects,
    fetchNextObjectsPage,
    renderObjectRow
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const currentObjectsCount = objects.length;

    const rowVirtualizer = useVirtualizer({
        count: hasNextObjectsPage ? currentObjectsCount + 1 : currentObjectsCount,
        overscan: 25,
        getScrollElement: () => scrollRef.current,
        estimateSize: () => 25 // Height of each row in px
    });

    useEffect(() => {
        // When a filter is changed, scroll the user to the first item in the list
        if (rowVirtualizer.getVirtualItems().length > 0) {
            rowVirtualizer.scrollToIndex(0);
        }
    }, [resetScrollKey]);

    useEffect(() => {
        const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

        if (!lastItem || !objects) {
            return;
        }

        const nextPageBuffer = 6;
        if (lastItem.index >= objects.length - nextPageBuffer && hasNextObjectsPage && !isFetchingNextObjectsPage) {
            fetchNextObjectsPage();
        }
    }, [
        hasNextObjectsPage,
        fetchNextObjectsPage,
        objects?.length,
        isFetchingNextObjectsPage,
        rowVirtualizer.getVirtualItems()
    ]);

    return (
        <div
            ref={scrollRef}
            className={clsx(
                'min-h-0 pr-4 h-full overflow-auto scrollbar-thin relative scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary [scrollbar-gutter:stable]',
                isFetchingObjects && 'pointer-events-none'
            )}
        >
            <div className='absolute top-0 left-0 right-4' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                    const object = objects[virtualItem.index];
                    return (
                        <div
                            key={virtualItem.key.toString()}
                            className='absolute top-0 left-0 w-full'
                            style={{
                                height: `${virtualItem.size}px`,
                                transform: `translateY(${virtualItem.start}px)`
                            }}
                        >
                            {object && renderObjectRow(object, virtualItem.index)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
