import { buildQuery, Node } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { useInfiniteQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';

interface useObjectFilterObjectsProps {
    scopeBaseQuery: string;
    queryParams: FilterQueryParameters;
    isFilterQueryReady: boolean;
    sortOrder?: { property: string; descending?: boolean };
    options?: { keepPreviousData?: boolean };
}

export const OBJECTS_PER_PAGE = 50;
// Property to use for sorting objects
export const DEFAULT_OBJECTS_SORT_ORDER_PROPERTY = '__name';

export const filterObjectsQueryKeys = {
    /**
     * Only for invalidation.
     */
    forAllFilterObjectQueriesInWorkspace: (workspace: string) => ['pagedObjects', workspace],
    forQuery: ({
        scopeBaseQuery,
        workspace,
        queryParams,
        sortOrder
    }: {
        scopeBaseQuery: string;
        workspace: string;
        queryParams: FilterQueryParameters;
        sortOrder?: { property: string; descending?: boolean };
    }) => ['pagedObjects', workspace, scopeBaseQuery, stringify(queryParams), stringify(sortOrder)]
};

/**
 * Gets objects (object data) for a data stream based on filtered sources, types, properties, and pagination
 */
export const useObjectFilterObjects = ({
    scopeBaseQuery,
    queryParams,
    sortOrder,
    isFilterQueryReady,
    options
}: useObjectFilterObjectsProps) => {
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();

    const {
        data: pagedData,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
        fetchNextPage,
        isPreviousData
    } = useInfiniteQuery(filterObjectsQueryKeys.forQuery({ scopeBaseQuery, workspace, queryParams, sortOrder }), {
        queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
            const startOfRange = pageParam * OBJECTS_PER_PAGE;
            const endOfRange = (pageParam + 1) * OBJECTS_PER_PAGE;

            const orderBy = `.order()
                .by(values("${sortOrder?.property || DEFAULT_OBJECTS_SORT_ORDER_PROPERTY}"), ${
                    sortOrder?.descending ? 'decr' : 'incr'
                })
            `;

            const objectsQuery =
                scopeBaseQuery +
                orderBy +
                `.valueMap(true)
                    .fold()
                .as("objects", "count")
                .select("objects", "count")
                    .by(range(local, ${startOfRange}, ${endOfRange}))
                    .by(count(local))`;

            const { gremlinQueryResults } = await Query(
                buildQuery(queryParams, objectsQuery),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined,
                true // Skip the access control cache, as we are sometimes querying for recently-added plugin configs
            );

            const { objects, count } = gremlinQueryResults[0];

            return {
                objects: objects as Node[],
                count: count as number,
                nextPage: pageParam + 1
            };
        },
        getNextPageParam: (lastPage) => (lastPage.objects.length >= OBJECTS_PER_PAGE ? lastPage.nextPage : undefined),
        keepPreviousData: true,
        ...options,
        enabled: isFilterQueryReady,
        cacheTime: 120_000,
        staleTime: 120_000
    });

    const flatResults = pagedData?.pages.flatMap((v) => v.objects);

    return {
        objects: flatResults,
        isFetchingObjects: isFetching,
        isFetchingNextObjectsPage: isFetchingNextPage,
        isLoadingObjects: isLoading,
        hasNextObjectsPage: hasNextPage,
        count: pagedData?.pages[0]?.count,
        fetchNextObjectsPage: fetchNextPage,
        isPreviousData
    };
};
