import { cn } from '@/lib/cn';
import { stateStrings } from '@squaredup/monitoring';
import { useNetworkBackground } from 'components/map/context/NetworkMapStoreContext';
import { PinnableNodeData } from 'components/map/data/types';
import { useStoreHealthStateNode } from 'components/map/hooks/useStoreHealthStateNode';
import { TruncatedText } from 'components/TruncatedText';
import { mapBackgroundColors, mapFillColors } from 'dashboard-engine/visualisations/Network/utils/mapColors';
import { useDashboardHealthStateRollup } from 'queries/hooks/useDashboardHealthStateRollup';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { useHover } from 'ui/hooks/useHover';
import { NodeToolbar } from '../../nodeToolbar/NodeToolbar';
import { MONITOR_NODE_SIZE } from '../MonitorNode';

const healthStateStyles = {
    [stateStrings.error]: 'bg-statusErrorPrimary',
    [stateStrings.warning]: 'bg-statusWarningPrimary',
    [stateStrings.success]: 'bg-statusHealthyPrimary',
    [stateStrings.unknown]: 'bg-statusUnknownPrimary',
    [stateStrings.unmonitored]: 'stroke-statusUnmonitoredPrimary'
};

const stokeHealthStateStyles = {
    [stateStrings.error]: 'stroke-statusErrorPrimary',
    [stateStrings.warning]: 'stroke-statusWarningPrimary',
    [stateStrings.success]: 'stroke-statusHealthyPrimary',
    [stateStrings.unknown]: 'stroke-statusUnknownPrimary',
    [stateStrings.unmonitored]: 'border-statusUnmonitoredPrimary'
};

interface MonitorNodeInternalProps extends PinnableNodeData {
    id: string;
}

export const MonitorNodeInternal: React.FC<MonitorNodeInternalProps> = memo(
    ({ id, label, pinned, expanded, fixedPosition, tileId, dashId, initialState, hiddenConnections }) => {
        const background = useNetworkBackground();
        const { isHovered, ...hoverProps } = useHover();

        const { data: dashboardHealthStates } = useDashboardHealthStateRollup(dashId);

        const healthState =
            (dashboardHealthStates?.tileStates ?? {})[tileId?.split('/')?.[1] ?? '']?.state ?? initialState;
        useStoreHealthStateNode(id, healthState);

        const [, realTileId] = tileId?.split('/') || [];

        return (
            <div
                className={cn('relative rounded-full', mapBackgroundColors[background])}
                style={{ width: MONITOR_NODE_SIZE, height: MONITOR_NODE_SIZE }}
                {...hoverProps}
            >
                <NodeToolbar
                    id={id}
                    isVisible={isHovered}
                    isPinned={pinned}
                    isFixed={fixedPosition}
                    isExpanded={expanded}
                    label={label}
                    hiddenConnections={hiddenConnections}
                    url={dashId ? `/dashboard/${dashId}?tile=${realTileId}` : `/drilldown/node/${id}`}
                />

                <svg
                    viewBox='0 0 100 100'
                    className={cn(
                        'absolute z-10 w-[calc(100%-2px)] left-px top-px opacity-0 transition-opacity',
                        healthState && 'opacity-100'
                    )}
                >
                    <circle
                        cx='50%'
                        cy='50%'
                        r='44'
                        vectorEffect='non-scaling-stroke'
                        strokeWidth={2}
                        className={cn(mapFillColors[background], stokeHealthStateStyles[healthState])}
                        {...(!pinned && { strokeDasharray: '1 1' })}
                    />
                </svg>

                <div
                    className={cn(
                        'absolute z-20 w-[8px] h-[8px] left-[5px] top-[5px] rounded-full bg-[#3ABACD] transition-colors',
                        healthState && healthStateStyles[healthState]
                    )}
                />

                <Handle
                    type='target'
                    position={Position.Top}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    className='absolute z-10 invisible -translate-x-1/2 translate-y-1/2 opacity-0 top-1/2 left-1/2'
                />
                <span className='sr-only'>{label}</span>

                {label && (
                    <div
                        className={cn(
                            'text-primary rounded-sm font-bold px-1 py-0.5 leading-tight text-[8px] max-w-[120px] absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 text-center',
                            mapBackgroundColors[background]
                        )}
                    >
                        <TruncatedText title={label}>{label}</TruncatedText>
                    </div>
                )}
            </div>
        );
    }
);
