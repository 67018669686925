import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureKey } from '@squaredup/tenants';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import { useDOMElement } from 'components/hooks/useDOMElement';
import Config from 'config';
import { tenantQueryKeys } from 'queries/queryKeys/tenantKeys';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import Auth from 'services/Auth';
import { ProjectedTenantResponse, SetUpgradePending, UpdateSubscription } from 'services/TenantService';
import { CurrencyCode, getPaddlePricePreview, PricePreviewOptions, usePaddleCheckout } from './Paddle';
import { plans } from './Plans';
import { CheckoutPanel } from './panels/CheckoutPanel';
import { ComparePlanPanel } from './panels/ComparePlanPanel';
import { useSubscription } from './useSubscription';
import { useSubscriptionPrices } from './useSubscriptionPrices';
import { useTenantLicencedCounts } from './useTenantLicencedCounts';

type PlanUpgradeModalProps = {
    onClose: () => void;
    onPurchase?: () => void;
    tenant: ProjectedTenantResponse;
    featureKey?: FeatureKey;
    currentUsage?: number;
};

type currentModal = 'compare' | 'checkout';
type modalDetails = {
    currentModal: currentModal;
    selectedPrice?: string;
};

export const PlanUpgradeModal = ({
    onClose: close,
    onPurchase,
    tenant,
    featureKey,
    currentUsage
}: PlanUpgradeModalProps) => {
    const [modalDetails, setModalDetails] = useState<modalDetails>({ currentModal: 'compare' });
    const [currencyCode, setCurrencyCode] = useState<CurrencyCode>('USD');
    const queryClient = useQueryClient();
    const { paddle, handlers: paddleHandlers } = usePaddleCheckout();
    const { maxUsers, userCount } = useTenantLicencedCounts();
    const hasExistingSubscription = Boolean(tenant.licenceData?.paddleSubscriptionId);
    const { data: subscription } = useSubscription({ enabled: hasExistingSubscription });
    const { data: prices } = useSubscriptionPrices(plans.map((p) => p.productId));

    const container = useDOMElement('dialogContent');
    const onSuccessfulPurchase = () => {
        // Set update pending on local copy (in-case websocket notification doesn't update)
        queryClient.setQueryData(tenantQueryKeys.tenant, {
            ...tenant,
            licenceData: {
                ...tenant.licenceData,
                upgradePending: true
            }
        });

        // Update server
        SetUpgradePending();

        if (onPurchase && typeof onPurchase === 'function') {
            onPurchase();
        }
    };

    paddleHandlers.set('checkout.completed', onSuccessfulPurchase);
    const submitUpgradeRequest = (planId: string, users: number) => {
        if (hasExistingSubscription) {
            setModalDetails({ currentModal: 'checkout', selectedPrice: planId });
        } else {
            paddle?.Checkout.open({
                items: [{ priceId: planId, quantity: users }],
                customer: { email: Auth.user?.name || '' },
                customData: { tenantId: tenant.id, environment: Config.Environment },
                settings: {
                    theme: 'light'
                }
            });
        }
    };

    const handleUpdateSubscription = async (request: Parameters<typeof UpdateSubscription>[0]) => {
        await UpdateSubscription(request);
        onSuccessfulPurchase();
    };

    return (
        <Modal
            title={<ModalTitle currentModal={modalDetails.currentModal} onBack={() => setModalDetails({ currentModal: 'compare' })} />}
            close={close}
            container={container}
            fullWidth
            maxWidth={modalDetails.currentModal === 'compare' ? 'max-w-[1372px]' : 'max-w-[470px]'}
        >
            {!paddle && <LoadingSpinner />}
            {paddle && modalDetails.currentModal === 'compare' && (
                <ComparePlanPanel
                    onClose={close}
                    onSubmit={submitUpgradeRequest}
                    organisation={tenant.displayName}
                    featureKey={featureKey}
                    currentUsage={currentUsage}
                    tenant={tenant}
                    currencyCode={currencyCode}
                    setCurrencyCode={setCurrencyCode}
                    subscription={subscription}
                    getPrices={(options: PricePreviewOptions) => getPaddlePricePreview(paddle, options)}
                />
            )}
            {modalDetails.currentModal === 'checkout' && modalDetails.selectedPrice && (
                <CheckoutPanel
                    onSubmit={handleUpdateSubscription}
                    currentUserLimit={maxUsers}
                    currentUsers={userCount?.[0]?.count}
                    initialPriceId={modalDetails.selectedPrice}
                    onBack={() => setModalDetails({ currentModal: 'compare' })}
                    currentPlan={subscription?.items[0]}
                    subscription={subscription}
                    prices={prices}
                />
            )}
        </Modal>
    );
};

const ModalTitle = ({currentModal, onBack} : { currentModal: string, onBack: () => void}) => {
    return currentModal === 'checkout' ? (
        <span className='flex items-center gap-3'>
            <FontAwesomeIcon
                icon={faChevronLeft}
                className='h-4 transition-colors cursor-pointer text-tertiaryButton hover:text-tertiaryButtonHover'
                role='button'
                onClick={onBack} />
            Checkout
        </span>
    ) : (
        <span>
            Compare plans
        </span>
    );
};
