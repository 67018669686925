import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getSavedScopeId } from '../../utilities/getSavedScopeId';
import { getScopeQueryDetailValue } from '../../utilities/getScopeQueryDetailValue';

export type ObjectFilters = {
    selectedObjects: string[];
    query: string;
    sources: string[];
    types: string[];
    properties: Record<string, any[]>;
    scope: string | undefined;
};

/**
 * These options are used if we don't have any object filter or scope query values
 * Otherwise they will be defaulted to empty values.
 * Their primary purpose is populating the type filter value from the data stream filters
 */
export type FallbackDefaults = {
    type?: string;
};

export type ObjectFilterState = {
    /**
     * The search string or query entered by the user via the search bar on the objects step.
     */
    searchString: string;
    plugins: string[];
    types: string[];
    properties: Record<string, string[]>;
    hasNonScopeFilter: boolean;
    hasFilter: boolean;
    sort?: {
        property: string;
        descending: boolean;
    };
} & (
    | {
          /**
           * Id of the scope being used as a filter, optionally in combination with other filters.
           */
          scopeId: string;
          hasScopeFilter: true;
      }
    | {
          scopeId: undefined;
          hasScopeFilter: false;
      }
);

export const emptyFilters: ObjectFilterState = {
    searchString: '',
    plugins: [],
    types: [],
    properties: {},
    scopeId: undefined,
    hasNonScopeFilter: false,
    hasScopeFilter: false,
    hasFilter: false
};

/**
 * Create an initial object filter state object based on the given config.
 */
export const createObjectFilterState = ({
    scopeConfig,
    defaults,
    fallbackDefaults,
    workspaceId
}: {
    scopeConfig: DataStreamBaseTileConfig['scope'];
    defaults?: ObjectFilters;
    fallbackDefaults?: FallbackDefaults;
    workspaceId: string;
}): ObjectFilterState => {
    const scopeQueryDetail = scopeConfig != null && 'queryDetail' in scopeConfig ? scopeConfig.queryDetail : undefined;

    const scopeId = getSavedScopeId(scopeConfig, workspaceId) || defaults?.scope || '';

    const filters: Omit<ObjectFilterState, 'hasNonScopeFilter' | 'hasScopeFilter' | 'hasFilter'> = {
        searchString: defaults?.query || getScopeQueryDetailValue(scopeQueryDetail, 'booleanQuery') || '',
        plugins: defaults?.sources || getScopeQueryDetailValue(scopeQueryDetail, 'plugins') || [],
        types: defaults?.types || getScopeQueryDetailValue(scopeQueryDetail, 'types') || fallbackDefaults?.type || [],
        properties: defaults?.properties || getScopeQueryDetailValue(scopeQueryDetail, 'properties') || {},
        scopeId
    };

    const hasNonScopeFilter =
        filters.plugins.length > 0 ||
        filters.types.length > 0 ||
        filters.searchString.length > 0 ||
        Object.values(filters.properties).some((v) => v.length > 0);
    const scopeFilter = scopeId
        ? { scopeId, hasScopeFilter: true as const }
        : { scopeId: undefined, hasScopeFilter: false as const };

    return {
        ...filters,
        ...scopeFilter,
        hasNonScopeFilter,
        hasFilter: hasNonScopeFilter || scopeFilter.hasScopeFilter
    };
};
