import { ComputedDatum } from '@nivo/pie';
import {
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    StreamData,
    StreamDataColumn,
    date,
    findColumn,
    number,
    preferred,
    required,
    state,
    string
} from '@squaredup/data-streams';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { hasUniqueValues, validateNonEmptyData } from 'dashboard-engine/dataStreams/dataMatchingUtils';
import { findDrilldownSourceIdColumn, getDrilldownUrlForRow } from 'dashboard-engine/util/drilldown';
import { ThemeOptions } from 'ui/hooks/useTheme';
import { DataStreamDonutConfig } from './Config';

export interface DonutComposition {
    label: string;
    value: number;
    percentage: number;
}

export interface Donut {
    id: number;
    index: number;
    label: string;
    value: number;
    percentage: number;
    link?: string;
    composition?: DonutComposition[];
    rows: FormattedStreamValue[][];
}

export type DonutColumns = {
    valueColumn: FoundColumn;
    labelColumn: FoundColumn;
};

export const getAllDonutData = (data: StreamData, donutColumns: DonutColumns): Donut[] => {
    const { valueColumn, labelColumn } = donutColumns;

    const sourceIdColumn = findDrilldownSourceIdColumn(data.metadata.columns, labelColumn?.column);

    // Calculate the total value of all the rows to use for calculating the percentage
    const total = data.rows
        .filter((r) => typeof r[valueColumn.dataIndex].value === 'number')
        .reduce((acc, row) => acc + (row[valueColumn.dataIndex].value as number), 0);

    return Array.isArray(data.rows)
        ? data.rows
              // values in a number column are not always numbers (they can be missing values
              // e.g. if the external system returned no data because a VM was turned off etc.)
              .filter((r) => typeof r[valueColumn.dataIndex].value === 'number')
              .map((r, i) => ({ row: r, rowData: data.metadata.rowData[i] }))
              .map(({ row: r, rowData }, index) => {
                  const link = sourceIdColumn ? getDrilldownUrlForRow(r, rowData, sourceIdColumn) : null;
                  const value = r[valueColumn.dataIndex].value as number;

                  return {
                      id: index,
                      index: index,
                      percentage: (value / total) * 100,
                      label: r[labelColumn.dataIndex].formatted,
                      value: value as number,
                      rows: [r],
                      ...(link ? { link } : {})
                  };
              })
        : [];
};

export const validateDonutData = (data: FormattedStreamData, columns: DonutColumns) => {
    const series = getAllDonutData(data, columns);
    return validateNonEmptyData(series);
};

export const findDonutValueColumn = (columns: StreamDataColumn[]) =>
    findColumn(columns, required('valueShapeName', number.name), preferred('role', 'value'));

export const findDonutLabelColumn = (columns: StreamDataColumn[]) =>
    findColumn(
        columns,
        required('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name),
        preferred('role', 'label')
    );

export const getDonutColumns = (columns: StreamDataColumn[], config: DataStreamDonutConfig) => {
    const donutCriteria = dataMatchCriteria<DonutColumns>();

    let valueColumn = findDonutValueColumn(columns);
    let labelColumn = findDonutLabelColumn(columns);

    if (config?.valueColumn) {
        const column = findColumn(columns, required('name', config.valueColumn));
        if (column.succeeded) {
            valueColumn = column;
        }
    }

    if (config?.labelColumn) {
        const column = findColumn(columns, required('name', config.labelColumn));
        if (column.succeeded) {
            labelColumn = column;
        }
    }

    if (valueColumn.failed) {
        donutCriteria.fail('Missing Value column', valueColumn.reason);
    } else {
        donutCriteria.pass('Automatically selected Value', {
            valueColumn: valueColumn.value
        });
    }

    if (labelColumn.failed) {
        donutCriteria.fail('Missing Label column', labelColumn.reason);
    } else {
        donutCriteria.pass('Automatically selected Label', {
            labelColumn: labelColumn.value
        });
    }

    return donutCriteria;
};

export const checkSingleRowForEachLabel = (rows: FormattedStreamValue[][], visualisationColumns: DonutColumns) => {
    const { labelColumn } = visualisationColumns;

    return hasUniqueValues(rows, labelColumn.dataIndex);
};

export const matchesData = (data: FormattedStreamData, config: DataStreamDonutConfig) => {
    const columns = data?.metadata?.columns ?? [];
    const rows = data?.rows ?? [];
    const donutCriteria = getDonutColumns(columns, config);

    if (data == null || rows.length === 0) {
        donutCriteria.fail('At least 1 row of data is required');
    }

    if (
        data != null &&
        rows.length > 0 &&
        donutCriteria.value.labelColumn &&
        !checkSingleRowForEachLabel(rows, donutCriteria.value)
    ) {
        donutCriteria.fail('Duplicate Label values detected, should be unique');
    }

    if (
        data != null &&
        rows.length > 0 &&
        donutCriteria.value.valueColumn &&
        donutCriteria.value.labelColumn &&
        !validateDonutData(data, donutCriteria.value)
    ) {
        donutCriteria.fail('At least 1 row of non-zero data is required');
    }

    return donutCriteria;
};

// These must be changed to system colours once new design system colours can use opacity
export const getStateColors = (theme: ThemeOptions) =>
    theme === 'light'
        ? {
              Success: '#259a51',
              Warning: '#e18700',
              Error: '#de0038',
              Unknown: '#adaeb2',
              'Not monitored': '#eeeeee'
          }
        : {
              Success: '#2bb660',
              Warning: '#ffaa00',
              Error: '#f2164d',
              Unknown: '#76767e',
              'Not monitored': '#20202e'
          };

export function convertToRgba(color: string, opacity: number) {
    const tempElement = document.createElement('div');

    tempElement.style.color = color;

    document.body.appendChild(tempElement);
    const rgbColor = window.getComputedStyle(tempElement).color.replace(')', `, ${opacity})`).replace('rgb', 'rgba');
    document.body.removeChild(tempElement);

    return rgbColor;
}

/**
 * Formats the tooltip value to show the percentage and the original value
 * i.e. 47% ($400)
 */
export function formatPercentageTooltipValue(
    percentage: number,
    originalFormattedValue: string,
    percentageFormatter: (v: unknown) => string
) {
    return `${percentageFormatter(percentage)} (${originalFormattedValue})`;
}

/**
 * Get the tooltip points for a donut chart
 * @param datum - Bar data
 * @param formatValue - Function to format the value
 * @param formatPercentage - Function to format the percentage
 * @param dataIndex - currently highlighed data index
 * @param showValuesAsPercentage - Whether to format the values as a percentage
 */
export function formatDonutTooltipPoints(
    datum: ComputedDatum<Donut>,
    formatValue: (v: unknown) => string,
    formatPercentage: (v: unknown) => string,
    dataIndex: number,
    showValuesAsPercentage?: boolean
) {
    const pointData = datum.data;

    // If more than 1 row was used to create this value,
    // the combined value has to be formatted as if it were a value in
    // the value column
    const formattedValue =
        pointData.rows.length > 1
            ? formatValue(datum.value)
            : pointData.rows[0]?.[dataIndex]?.formatted ?? pointData.label;

    // The values that make up that combined value have already been formatted
    // in the original data
    const formattedCompositions = pointData.composition?.map((c, i) => {
        const pointValue = pointData.rows[i][dataIndex].formatted;

        return {
            ...c,
            value: showValuesAsPercentage
                ? formatPercentageTooltipValue(c.percentage, pointValue, formatPercentage)
                : pointValue
        };
    });

    return [
        {
            label: datum.label.toString(),
            value: showValuesAsPercentage
                ? formatPercentageTooltipValue(pointData.percentage, formattedValue, formatPercentage)
                : formattedValue,
            composition: formattedCompositions,
            color: datum.color,
            highlight: true
        }
    ];
}
