import { scopeLimitMaximum } from '@squaredup/constants';
import type { Node } from '@squaredup/graph';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import type { DataStream } from 'services/DataStreamDefinitionService';
import { emptyFilters, type ObjectFilterState } from '../state/scope/ObjectFilterState';
import type { ScopeState } from '../state/scope/ScopeState';
import type { ScopeStateWithDispatch } from '../state/scope/ScopeStore';
import { getObjectFilters } from '../utilities/getObjectFilters';
import { useObjectFilterObjects } from './objectFilters/useObjectFilterObjects';
import { useObjectFilterProperties } from './objectFilters/useObjectFilterProperties';
import { useObjectFilterPropertyValues } from './objectFilters/useObjectFilterPropertyValues';
import { useObjectFilterScopeNodes } from './objectFilters/useObjectFilterScopeNodes';
import { useObjectFilterSources } from './objectFilters/useObjectFilterSources';
import { useObjectFilterTypes } from './objectFilters/useObjectFilterTypes';
import { useDataStreamMatchesToGremlin } from './useDataStreamMatchesToGremlin';
import { useDataStreamTemplate } from './useDataStreamTemplate';
import { useTileEditorScopes } from './useScopes';

/**
 * Get the Id of the selected scope from the scope state.
 * This works whether we're using a predefined scope or filtering by a scope.
 */
const selectScopeId = (scope: ScopeState): string | undefined => {
    if (scope.type === 'predefinedScope') {
        return scope.scopeId;
    }

    if (!scope.canFilter) {
        return undefined;
    }

    return scope.filters.scopeId;
};

const getSelectedObjects = (
    scope: ScopeState,
    objects: Node[]
): { selectedObjects: Node[]; selectedObjectIds: string[] } => {
    if (scope.isVariableScope) {
        return {
            selectedObjectIds: scope.variableSelectedNodeIds,
            selectedObjects: objects.filter((o) => scope.variableSelectedNodeIds.includes(o.id))
        };
    }

    if (scope.type === 'objectList') {
        return {
            selectedObjectIds: scope.selectedNodeIds,
            selectedObjects: objects.filter((o) => scope.selectedNodeIds.includes(o.id))
        };
    }

    if (scope.isDynamic) {
        return {
            selectedObjectIds: objects.map((o) => o.id),
            selectedObjects: objects
        };
    }

    // No scope
    return {
        selectedObjectIds: [],
        selectedObjects: []
    };
};

/**
 * Load a gremlin condition which filters objects to those intersecting with the given scope.
 */
const useScopeFilterQuery = (scopeId: string | undefined) => {
    const { data: scopes, isLoading: isLoadingScopes } = useTileEditorScopes();
    const filterScopeWorkspaceId = scopes?.find(({ id }) => id === scopeId)?.workspaceId;

    const { data: scopeFilterQueryCondition, isFetching: isFetchingScopeObjectQuery } = useObjectFilterScopeNodes(
        scopeId,
        filterScopeWorkspaceId
    );

    return {
        /**
         * The gremlin condition which filters objects to those intersecting with the given scope.
         */
        scopeFilterQueryCondition: scopeId == null ? '' : scopeFilterQueryCondition ?? '',
        /**
         * The Id of the workspace the given scope belongs to.
         */
        filterScopeWorkspaceId,
        /**
         * True if the condition for filtering by the given scope is being fetched.
         */
        isFetchingScopeObjectQuery,
        isLoadingScopes
    };
};

/**
 * Load the objects that match the given filters.
 * @param filters The filters that the objects must match.
 * @param scopeBaseQuery The gremlin condition that filters objects to those intersecting with the given scope
 * see ({@link useScopeFilterQuery}).
 * @param canQuery True if the filters and {@link scopeBaseQuery} are ready to be used to query objects.
 * @param options Query options passed to the objects query.
 */
const useFilteredObjects = (
    filters: ObjectFilterState,
    scopeBaseQuery: string,
    canQuery: boolean,
    options: { enabled?: boolean; keepPreviousData?: boolean } = {}
) => {
    // Gets all the types for a datastream on the filtered sources and properties
    const types = useObjectFilterTypes({
        scopeBaseQuery,
        queryParams: getObjectFilters(filters.plugins, [], filters.properties, ''),
        filterTypes: filters.types,
        isFilterQueryReady: canQuery
    });

    // Gets all properties for a datastream based on filtered sources, types, and properties
    const properties = useObjectFilterProperties({
        scopeBaseQuery,
        queryParams: getObjectFilters(filters.plugins, filters.types, {}, ''),
        isFilterQueryReady: canQuery
    });

    // Gets all possible values for the properties we're filtering with
    const propertyValues = useObjectFilterPropertyValues({
        scopeBaseQuery,
        queryParams: getObjectFilters(filters.plugins, filters.types, {}, ''),
        filterProperties: filters.properties
    });

    const filterConfig = getObjectFilters(filters.plugins, filters.types, filters.properties, filters.searchString);

    // Gets objects (object data) for a data stream based on filtered sources, types, properties, and pagination
    const filteredObjects = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: filterConfig,
        isFilterQueryReady: canQuery,
        sortOrder: filters.sort,
        options
    });

    return {
        /**
         * The objects that match the given filters, and information about the query the produces them.
         */
        filteredObjects,
        /**
         * The queries that get the types, properties, and property values that can be used to filter objects.
         */
        filterQueries: { types, properties, propertyValues },
        isFetchingFilteredObjects:
            filteredObjects.isFetchingObjects || types.isLoading || properties.isLoading || propertyValues.isFetching
    };
};

/**
 * Create the base gremlin query used to load the objects that match a given data stream.
 * @param selectedDataStream
 * @param scopeId The Id of the scope/collection to limit the results to.
 */
export const useScopeBaseQuery = (selectedDataStream: DataStream | undefined, scopeId: string | undefined) => {
    const { scopeFilterQueryCondition, filterScopeWorkspaceId, isFetchingScopeObjectQuery, isLoadingScopes } =
        useScopeFilterQuery(scopeId);

    const { isLoading: isLoadingDataStreamQuery, query: dataStreamQuery } = useDataStreamMatchesToGremlin(
        selectedDataStream?.id
    );

    const scopeBaseQuery = `${dataStreamQuery ?? ''}${scopeFilterQueryCondition}`;

    return {
        scopeBaseQuery,
        scopeBaseQueryComponents: {
            dataStreamQuery,
            scopeFilterQueryCondition
        },
        filterScopeWorkspaceId,
        isLoading: isLoadingDataStreamQuery || isLoadingScopes,
        isFetchingScopeObjectQuery
    };
};

/**
 * Generates and executes queries to get object filter values and matching objects for a scope.
 * Also provides functions to update the scope and filters.
 * @param scope The current scope state.
 * @param dispatch The dispatch function used to change the state.
 * @param selectedDataStream
 */
export const useDataStreamObjectFilters = (
    { dispatch, ...scope }: ScopeStateWithDispatch,
    selectedDataStream: DataStream | undefined,
    {
        loadWithoutDataStream = false,
        ...queryOptions
    }: { enabled?: boolean; keepPreviousData?: boolean; loadWithoutDataStream?: boolean } = {}
) => {
    const { workspace } = useDataStreamWorkspaceContext();
    const { variables = [] } = useDashboardContext();

    const filters: ObjectFilterState = scope.canFilter
        ? scope.filters
        : { ...emptyFilters, searchString: scope.searchString };

    const hasDataStream = Boolean(selectedDataStream);

    const {
        filterScopeWorkspaceId,
        scopeBaseQuery,
        scopeBaseQueryComponents: { dataStreamQuery, scopeFilterQueryCondition },
        isLoading: isLoadingScopeBaseQuery,
        isFetchingScopeObjectQuery
    } = useScopeBaseQuery(selectedDataStream, selectScopeId(scope));

    const canQuery =
        ((selectedDataStream != null && dataStreamQuery !== undefined) || loadWithoutDataStream) &&
        (!filters.scopeId || scopeFilterQueryCondition !== undefined);

    const { filteredObjects, filterQueries } = useFilteredObjects(filters, scopeBaseQuery, canQuery, queryOptions);

    // Gets all the sources for a data stream, filters them based on source IDs
    const { data: sources, isLoading: isLoadingSources } = useObjectFilterSources({
        dataStreamQuery: dataStreamQuery ?? '',
        queryParams: getObjectFilters([], [], {}, '')
    });

    const { dataStream, pluginDataSource } = useDataStreamTemplate();

    // Lookup pluginName and sourceName by sourceId
    const pluginLookup = new Map(
        sources
            ?.filter((source) => Boolean(source.plugin?.pluginId))
            ?.map((source) => [
                source.id as string,
                {
                    pluginName: source.plugin?.name ?? (source.displayName as string),
                    sourceName: source.displayName as string
                }
            ])
    );

    /**
     * Update the filters and/or set whether we have a dynamic or fixed scope.
     * Filters not provided will not be changed.
     *
     * Setting a dynamic scope with a scope Id leads to a predefined scope, and all non-scope filters are removed.
     */
    const updateScope = ({
        filters: newFilters = {},
        selectedNodeIds,
        isDynamic: setIsDynamic,
        pruneInteractedNodeIds = false
    }: {
        filters?: Partial<Omit<ObjectFilterState, 'properties'> & { properties: Record<string, string[] | undefined> }>;
        selectedNodeIds?: string[];
        isDynamic?: boolean;
        pruneInteractedNodeIds?: boolean;
    }) => {
        const filterUpdateRequired = newFilters != null && Object.keys(newFilters).length > 0;
        const isDynamic = setIsDynamic ?? scope.isDynamic;

        if (!isDynamic) {
            if (setIsDynamic === false) {
                // Actively setting a fixed scope
                dispatch({ type: 'setObjectListScope', selectedNodeIds: selectedNodeIds ?? [] });
            } else if (selectedNodeIds != null) {
                // Updating an existing fixed scope
                if (selectedNodeIds.length === 0 && pruneInteractedNodeIds) {
                    // If we're clearing the selected objects we may also want to reset the interacted objects
                    dispatch({ type: 'setSelectedObjects', selectedNodeIds: [], pruneInteractedNodeIds });
                } else {
                    dispatch({ type: 'setSelectedObjects', selectedNodeIds });
                }
            }

            if (filterUpdateRequired) {
                dispatch({ type: 'updateFilters', filters: newFilters, baseQuery: dataStreamQuery ?? '' });
            }
            return;
        }

        const scopeFilter = 'scopeId' in newFilters ? newFilters.scopeId : selectScopeId(scope);

        // Dynamic + scope filter = predefined scope, no matter what other filters are set
        if (scopeFilter) {
            dispatch({
                type: 'setPredefinedScope',
                scopeId: scopeFilter,
                workspaceId: filterScopeWorkspaceId ?? workspace,
                variables,
                // Search string is the only valid filter for a predefined scope
                // because it only affects the UI
                searchString: newFilters.searchString
            });

            // Can't have a predefined scope and non-scope filters
            return;
        }

        // Standard dynamic scope based on filters
        dispatch({ type: 'setDynamicScope', baseQuery: dataStreamQuery ?? '' });

        if (filterUpdateRequired) {
            dispatch({ type: 'updateFilters', filters: newFilters, baseQuery: dataStreamQuery ?? '' });
        }
    };

    const scopesDisabled = hasDataStream ? scope.isDynamic && filters.hasNonScopeFilter : false;

    const isLoadingFilters = isLoadingScopeBaseQuery || isLoadingSources;

    const { selectedObjects, selectedObjectIds } = getSelectedObjects(scope, filteredObjects.objects ?? []);

    const objectLimit = dataStream?.definition?.objectLimit ?? pluginDataSource?.objectLimit;

    const selectedObjectsCount = Math.min(
        scope.type === 'objectList' ? scope.selectedNodeIds.length : 0,
        scopeLimitMaximum
    );
    const dynamicObjectsCount = Math.min(filteredObjects.count ?? 0, objectLimit ?? scopeLimitMaximum);

    const isFiltered = scope.type === 'predefinedScope' || filters.hasFilter;

    return {
        isConfigured: scope.type !== 'none',
        selectedObjectsCount,
        dynamicObjectsCount,
        objectLimit,
        pluginLookup,
        isFiltered,
        isLoadingFilters,
        sources,
        isDynamic: scope.isDynamic,
        selectedObjects,
        selectedObjectIds,
        selectedAllObjects: scope.isVariableScope && scope.variableIsSelectedAll,
        filtersDisabled: !scope.canFilter,
        scopesDisabled,
        filterScope: selectScopeId(scope),
        interactedObjects: scope.interactedNodeIds,
        sortOrder: filters.sort,
        setSortOrder: (sortOrder: { property: string; descending: boolean } | undefined) => {
            if (sortOrder == null) {
                return dispatch({ type: 'clearSort' });
            }

            const { property, descending } = sortOrder;
            return dispatch({ type: 'sort', property, descending });
        },
        updateScope,
        canResetFilters: isFiltered,
        resetFilters: () => {
            dispatch({ type: 'clearFilters' });
        },
        filters,
        filterQueries,
        filteredObjects: {
            ...filteredObjects,
            isFetchingObjects: isFetchingScopeObjectQuery || filteredObjects.isFetchingObjects
        },
        scope
    };
};
