import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { createContext, useContext } from 'react';
import { useDatasets } from 'ui/editor/dataStream/TileEditor/hooks/useDatasets';

export const datasetContextDefaultValue = {
    datasets: [],
    datasetTabs: [],
    activeDataset: 0,
    activeDataTab: 0,
    config: baseDataStreamConfig,
    setConfig: () => undefined,
    addDataset: () => undefined,
    renameDataset: () => undefined,
    setActiveDataset: () => undefined,
    setActiveDataTab: () => undefined
};

const DatasetContext = createContext<ReturnType<typeof useDatasets>>(datasetContextDefaultValue);

DatasetContext.displayName = 'DatasetContext';

/**
 *
 * @returns config, savedTileConfig, setConfig, onClose
 */
export const useDatasetContext = () => useContext(DatasetContext);

export default DatasetContext;
