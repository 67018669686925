import { getFeatureLimit } from '@squaredup/tenants';
import { useDatasourceConfigsCount } from 'queries/hooks/useDatasourceConfigsCount';
import { useTier } from 'queries/hooks/useTier';
import { UsageQueryKeys } from 'queries/queryKeys/usageQueryKeys';
import { useQuery } from 'react-query';
import { GetCountOfMonitors, UserCount, WorkspacesCount } from 'services/UsageService';

export const useTenantLicencedCounts = () => {
    const { data: configCount, isLoading: isConfigLoading } = useDatasourceConfigsCount();
    const { data: userCount, isLoading: isUserCountLoading } = useQuery(UsageQueryKeys.Users, UserCount);
    const { data: monitorCount, isLoading: isMonitorCountLoading } = useQuery(
        UsageQueryKeys.Monitors,
        GetCountOfMonitors
    );
    const { data: workspacesCount, isLoading: isWorkspacesCountLoading } = useQuery(
        UsageQueryKeys.Workspaces,
        WorkspacesCount
    );
    const { data: tier, isLoading: isTierLoading } = useTier();
    const userLimit = tier ? getFeatureLimit(tier, 'users') : { value: 0 };
    const maxUsers = 'value' in userLimit ? userLimit.value : Number.POSITIVE_INFINITY;

    const isLoading =
        isConfigLoading || isUserCountLoading || isMonitorCountLoading || isWorkspacesCountLoading || isTierLoading;

    return {
        isLoading,
        configCount,
        userCount,
        monitorCount,
        workspacesCount,
        maxUsers
    };
};
