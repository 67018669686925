import { orderBy } from 'lodash';
import type { DataStream } from 'services/DataStreamDefinitionService';
import { DataStreamListItem } from '../../components/DataStreamListItem';

export type ConfigurationPresetListProps<T extends DataStream> = {
    selectedDataStream: T | undefined;
    dataStreams: T[];
    onChange: (dataStream: T) => void;
};

export const ConfigurationPresetList = <T extends DataStream>({
    selectedDataStream,
    dataStreams,
    onChange
}: ConfigurationPresetListProps<T>) => {
    if (selectedDataStream?.template == null && selectedDataStream?.definition?.presetOf == null) {
        return <></>;
    }

    const parentConfigurableDataStreamName =
        selectedDataStream.template != null
            ? selectedDataStream.definition.name
            : selectedDataStream?.definition?.presetOf;

    const presets = orderBy(
        dataStreams.filter(
            (ds) =>
                ds.definition.presetOf === parentConfigurableDataStreamName &&
                ds.pluginId === selectedDataStream.pluginId
        ),
        'displayName'
    );

    return (
        <div className='flex flex-col justify-items-stretch' role='listbox'>
            {presets.map((p) => {
                return (
                    <DataStreamListItem
                        key={p.id}
                        className='grid items-center grid-cols-2'
                        isSelected={p.id === selectedDataStream.id}
                        onClick={() => onChange(p)}
                        data-testid='dataStreamSelectPreset'
                    >
                        <span className='pr-2 font-semibold truncate'>{p.displayName}</span>
                        <span className='truncate text-textSecondary'>{p.description}</span>
                    </DataStreamListItem>
                );
            })}
        </div>
    );
};
