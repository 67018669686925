import Text from '@/components/Text';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SqUpPluginConfigId } from '@squaredup/ids';
import Button from 'components/button';
import Tooltip from 'components/tooltip/Tooltip';
import { useDashboardContext } from 'contexts/DashboardContext';
import trackEvent from 'lib/analytics';
import { getIconForType, getNameForType } from 'lib/types';
import { orderBy } from 'lodash';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import PluginIcon from 'pages/scope/PluginIcon';
import { useMemo, useRef, useState } from 'react';
import { CollapsibleFilterPanel } from 'ui/editor/components/CollapsibleFilterPanel/CollapsibleFilterPanel';
import { EditFilterOptionWrapper } from 'ui/editor/components/CollapsibleFilterPanel/EditFilterOptionWrapper';
import { FilterOption } from 'ui/editor/components/CollapsibleFilterPanel/FilterOption';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';
import { useTileEditorScopes } from '../hooks/useScopes';
import { getNewFilterValue } from '../utilities/getNewFilterValue';
import { AddPropertyFilter } from './filter/AddPropertyFilter';
import { PropertyFilters } from './filter/PropertyFilters';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';

interface ObjectFiltersProps {
    allowCreateScope?: boolean;
    allowEditScope?: boolean;
}

export const ObjectFilters: React.FC<ObjectFiltersProps> = ({ allowCreateScope, allowEditScope }) => {
    const {
        filters,
        filterQueries,
        sources,
        pluginLookup,
        canResetFilters,
        filtersDisabled,
        scopesDisabled,
        filterScope,
        selectedObjectIds,
        resetFilters,
        updateScope
    } = useTileEditorObjectsFilterContext();

    const { data: scopes } = useTileEditorScopes();

    const { config } = useDatasetContext();
    const { variables = [] } = useDashboardContext();

    const variablesScopeIds = variables.map((v) => v.scopeId);

    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const [editingScope, setEditingScope] = useState();

    const filterListRef = useRef<HTMLDivElement>(null);

    const isSqUp = config?.dataStream?.pluginConfigId === SqUpPluginConfigId.value;

    const typesWithPreviousFilterTypes = useMemo(() => {
        const types = filterQueries.types.data;
        if (!types) {
            return [];
        }

        const previousTypes = orderBy(
            filters.types.filter((type) => !types.includes(type)),
            (type: string) => getNameForType(type, 'singular').toLowerCase()
        );

        const orderedTypes = orderBy([...previousTypes, ...types], (type: string) =>
            getNameForType(type, 'singular').toLowerCase()
        );

        return orderedTypes;
    }, [filters.types, filterQueries.types.data]);

    let currentScope;
    if (!(typeof config.scope === 'string' || Array.isArray(config.scope)) && config.scope) {
        if ('query' in config.scope && config.scope.query) {
            currentScope = config.scope as {
                query: string;
                queryDetail: any;
                bindings: any;
            };
        }
    }

    return (
        <aside className='flex flex-col space-y-4 text-sm border-r w-[20%] min-w-56 text-textSecondary border-dividerPrimary'>
            <Text.H4 className='flex items-center flex-shrink-0 w-full text-textPrimary'>
                <span className='mr-4'>Filter</span>

                <Button
                    disabled={!canResetFilters}
                    variant='tertiary'
                    className='ml-auto mr-4 text-sm font-normal'
                    onClick={() => {
                        resetFilters();
                        filterListRef.current?.scrollTo({ top: 0 });
                    }}
                >
                    Reset
                </Button>
            </Text.H4>
            {scopeModalOpen && (
                <CreateEditScope
                    scope={editingScope}
                    filters={
                        !editingScope
                            ? {
                                  selectedObjects: selectedObjectIds,
                                  scope: filterScope,
                                  sources:
                                      filters.plugins.length > 0 || isSqUp
                                          ? filters.plugins
                                          : (sources || []).map(({ id }) => id),
                                  types:
                                      filters.types.length > 0 || isSqUp ? filters.types : typesWithPreviousFilterTypes,
                                  query: filters.searchString,
                                  properties: filters.properties
                              }
                            : undefined
                    }
                    currentScope={currentScope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        setEditingScope(undefined);
                        if (modifiedScope) {
                            updateScope({
                                filters: { scopeId: modifiedScope.id, plugins: [], types: [] },
                                isDynamic: true
                            });
                        }
                    }}
                />
            )}
            <div
                ref={filterListRef}
                className='flex-1 min-h-0 pr-4 space-y-4 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'
            >
                {sources && sources.length > 0 && (
                    <Tooltip
                        title='Filtering by Data Source is not available when filtering by Collection with dynamic selection'
                        disabled={!filtersDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Data Source'
                            disabled={filtersDisabled}
                            {...(filters.plugins.length > 0 && {
                                onClear: () => updateScope({ filters: { plugins: [] } })
                            })}
                        >
                            {sources.map((source) => (
                                <FilterOption
                                    key={source.id}
                                    title={source.displayName!}
                                    icon={<PluginIcon pluginName={pluginLookup.get(source.id)?.pluginName} />}
                                    isSelected={
                                        filters.plugins.length > 0 ? filters.plugins.includes(source.id) : undefined
                                    }
                                    disabled={sources.length === 1}
                                    onClick={() => {
                                        trackEvent('Objects Data Source Filter Selected', { name: source.displayName });
                                        return updateScope({
                                            filters: { plugins: getNewFilterValue(filters.plugins, source.id) }
                                        });
                                    }}
                                />
                            ))}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                {typesWithPreviousFilterTypes && typesWithPreviousFilterTypes.length > 0 && (
                    <Tooltip
                        title='Filtering by Type is not available when filtering by Collection with dynamic selection'
                        disabled={!filtersDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Type'
                            disabled={filtersDisabled}
                            {...(filters.types.length > 0 && {
                                onClear: () => updateScope({ filters: { types: [] } })
                            })}
                        >
                            {typesWithPreviousFilterTypes.map((type) => {
                                const name = getNameForType(type, 'singular');
                                return (
                                    <FilterOption
                                        key={type}
                                        title={name}
                                        icon={<FontAwesomeIcon icon={getIconForType(type)} fixedWidth={true} />}
                                        isSelected={filters.types.length > 0 ? filters.types.includes(type) : undefined}
                                        disabled={typesWithPreviousFilterTypes.length === 1}
                                        onClick={() => {
                                            trackEvent('Objects Type Filter Selected', { name });
                                            return updateScope({
                                                filters: { types: getNewFilterValue(filters.types, type) }
                                            });
                                        }}
                                    />
                                );
                            })}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                {scopes && (
                    <Tooltip
                        title='Filtering by Collection is not available when using other filters with dynamic selection'
                        disabled={!scopesDisabled}
                    >
                        <CollapsibleFilterPanel
                            title='Collection'
                            disabled={scopesDisabled}
                            {...(allowCreateScope && {
                                onCreateTooltip: 'Add collection',
                                onCreate: () => setScopeModalOpen(true)
                            })}
                            {...(filterScope && {
                                onClear: () => updateScope({ filters: { scopeId: '' } })
                            })}
                        >
                            {scopes.length > 0 ? (
                                scopes.map((scope) => {
                                    const isSelected = filterScope === scope.id;

                                    return (
                                        <EditFilterOptionWrapper
                                            key={scope.id}
                                            tooltipTitle='Edit collection'
                                            disabled={!allowEditScope}
                                            onClick={() => {
                                                setEditingScope(scope);
                                                setScopeModalOpen(true);
                                            }}
                                        >
                                            <FilterOption
                                                title={scope.displayName!}
                                                isSelected={filterScope ? isSelected : undefined}
                                                onClick={(e) => {
                                                    e.currentTarget?.scrollIntoView();

                                                    updateScope({
                                                        filters: { scopeId: isSelected ? undefined : scope.id },
                                                        isDynamic:
                                                            filters.hasNonScopeFilter || filters.scopeId === scope.id
                                                                ? undefined
                                                                : true
                                                    });
                                                }}
                                            >
                                                {variablesScopeIds.includes(scope.id) && (
                                                    <Tooltip
                                                        title={'This collection is being used as a dashboard variable'}
                                                        placement='bottom-start'
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faBarsFilter}
                                                            fixedWidth={true}
                                                            className='w-3 h-3 ml-2 text-textPrimary'
                                                        />
                                                    </Tooltip>
                                                )}
                                            </FilterOption>
                                        </EditFilterOptionWrapper>
                                    );
                                })
                            ) : (
                                <Text.SmallBody className='text-textDisabled'>No collections available.</Text.SmallBody>
                            )}
                        </CollapsibleFilterPanel>
                    </Tooltip>
                )}

                <PropertyFilters
                    propertyFilters={filters.properties}
                    propertyValues={filterQueries.propertyValues.data}
                    setPropertyFilter={(property, value) => {
                        updateScope({
                            filters: {
                                properties: {
                                    ...filters.properties,
                                    [property]: getNewFilterValue(filters.properties[property], value)
                                }
                            }
                        });
                    }}
                    clearPropertyFilter={(property) => {
                        updateScope({ filters: { properties: { ...filters.properties, [property]: undefined } } });
                    }}
                    disabled={filtersDisabled}
                />
            </div>

            <div className='flex-shrink-0 pr-4'>
                <div className='pt-4 border-t border-dividerPrimary'>
                    <AddPropertyFilter />
                </div>
            </div>
        </aside>
    );
};
