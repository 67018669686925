import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import type { TileEditorDataStream } from '../TileEditorState';

export type DataStreamFilters = {
    dataSourceId?: string;
    objectType?: string;
    scopeId?: string;
    tags: string[];
    search?: string;
};

export type DataStreamState = {
    selectedDataStream?: TileEditorDataStream;
    filters: DataStreamFilters;
};

export const emptyDataStreamState: DataStreamState = {
    filters: { tags: [] }
};

export const createDataStreamState = ({
    dataStreams,
    dataStreamConfig,
    defaultFilters,
    previousState
}: {
    dataStreams: TileEditorDataStream[];
    dataStreamConfig: DataStreamBaseTileConfig['dataStream'];
    defaultFilters?: Partial<DataStreamState['filters']>;
    previousState?: DataStreamState;
}): DataStreamState => {
    const selectedDataStream = dataStreams.find((ds) => ds.id === dataStreamConfig?.id);

    return {
        selectedDataStream,
        filters: {
            ...emptyDataStreamState.filters,
            /**
             * Carry over the previous filters so they aren't lost
             * when recreating the state from tile config for a reset
             */
            ...previousState?.filters,
            ...defaultFilters
        }
    };
};
