import {
    ClientDataStreamRequest,
    StreamData,
    flattenColumnExpands,
    mergeStreamDataColumnDefinitions,
    reprocessStreamData
} from '@squaredup/data-streams';
import { Result, isDefined } from '@squaredup/utilities';
import { intersection, orderBy } from 'lodash';
import { FC, createContext, useContext, useRef } from 'react';
import { canRunClientSide } from './CanRunClientSide';

export interface ClientDataStreamsContextValue {
    isClientDataStreamsEnabled: boolean;
    /**
     * Set the stream data loaded from the backend on which
     * all the client requests using this context will be based.
     */
    setBase: (request: ClientDataStreamRequest, data?: StreamData) => void;
    runClientSide: (request: ClientDataStreamRequest) => Promise<Result<StreamData>>;
}

export interface BaseData {
    data: StreamData;
    request: ClientDataStreamRequest;
}

const disabledContextValue = {
    isClientDataStreamsEnabled: false,
    setBase: () => {
        throw new Error('Set a context value using ClientDataStreamsContextProvider');
    },
    runClientSide: () => {
        throw new Error('Set a context value using ClientDataStreamsContextProvider');
    }
};

const ClientDataStreamsContext = createContext<ClientDataStreamsContextValue>(disabledContextValue);

ClientDataStreamsContext.displayName = 'ClientDataStreamsContext';

export const useClientDataStreamsContext = () => useContext(ClientDataStreamsContext);

const getRequestColumnNames = (request: ClientDataStreamRequest) =>
    flattenColumnExpands(request.options.metadata)
        .map((c) => ('name' in c ? c.name : 'pattern' in c ? c.pattern : undefined))
        .filter(isDefined)
        .sort();

const countColumnsInCommon = (a: string[], b: string[]) => {
    return intersection(a, b).length;
};

export const findBaseData = (baseData: BaseData[], request: ClientDataStreamRequest): BaseData | undefined => {
    const values = baseData.filter((d) => canRunClientSide(d.request, request).succeeded);

    const requestColumnNames = getRequestColumnNames(request);

    return orderBy(
        values,
        [
            (d) => countColumnsInCommon(getRequestColumnNames(d.request), requestColumnNames),
            (d) =>
                countColumnsInCommon(
                    d.data.metadata.columns.map((c) => c.name),
                    requestColumnNames
                )
        ],
        ['desc', 'desc']
    )[0];
};

export const ClientDataStreamsContextProvider: FC<
    Pick<Partial<ClientDataStreamsContextValue>, 'isClientDataStreamsEnabled'> & {
        base?: BaseData;
    }
> = ({ children, isClientDataStreamsEnabled = false, base: initialBase }) => {
    /**
     * Store of the results of previous requests to the backend that we can use
     * as a base for running data streams requests that are modifications of those
     * requests client-side.
     * 
     * Use a ref as this doesn't affect how anything renders, and we don't want to
     * trigger a re-render when this changes as it causes a React warning.
     */
    const baseDataRef = useRef<BaseData[]>(initialBase == null ? [] : [initialBase]);

    if (!isClientDataStreamsEnabled) {
        return (
            <ClientDataStreamsContext.Provider value={disabledContextValue}>
                {children}
            </ClientDataStreamsContext.Provider>
        );
    }

    return (
        <ClientDataStreamsContext.Provider
            value={{
                isClientDataStreamsEnabled,
                setBase: (request: ClientDataStreamRequest, data?: StreamData) => {
                    if (data == null || data.metadata.source === 'client') {
                        return;
                    }

                    const base = findBaseData(baseDataRef.current, request);

                    if (
                        base == null &&
                        // Pass the same request twice so we only fail if this request would
                        // make bad base data - e.g. it's grouped
                        canRunClientSide(request, request).succeeded
                    ) {
                        baseDataRef.current = [...baseDataRef.current, { data, request }];
                    }
                },
                runClientSide: async (request: ClientDataStreamRequest) => {
                    const base = findBaseData(baseDataRef.current, request);

                    if (base == null) {
                        return Result.fail('No valid backend stream data to base a client data stream process on');
                    }

                    const reprocessedData = Result.success(
                        await reprocessStreamData(
                            base.data,
                            mergeStreamDataColumnDefinitions({
                                ...base.data.metadata.columnDefinitions,
                                request: request.options.metadata ?? []
                            }),
                            request.options
                        )
                    );

                    return reprocessedData;
                }
            }}
        >
            {children}
        </ClientDataStreamsContext.Provider>
    );
};
