import { Toggle } from '@/components/forms/Toggle';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/tooltip/Tooltip';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';

interface ObjectDynamicToggleProps {
    onClick?: (isDynamic: boolean) => void;
}

export const ObjectDynamicToggle: React.FC<ObjectDynamicToggleProps> = ({ onClick }) => {
    const { scope, isDynamic, updateScope } = useTileEditorObjectsFilterContext();

    const disabled =
        (!scope.isDynamic && scope.filters.hasScopeFilter && scope.filters.hasNonScopeFilter) || scope.isVariableScope;

    return (
        <Tooltip
            title='Dynamic selection is not available when a Collection is used with other filters'
            disabled={!disabled}
            className='flex items-center space-x-2'
        >
            <Toggle
                name='dynamic-selection-toggle'
                checked={isDynamic}
                disabled={disabled}
                className='self-center'
                data-testid='dynamicSelectionToggle'
                onCheckedChange={() => {
                    // When switching to/from dynamic we need to immediately update the scope
                    updateScope({ isDynamic: !isDynamic });
                    onClick?.(!isDynamic);
                }}
            />
            <label htmlFor='dynamic-selection-toggle' className='flex items-center space-x-2 select-none'>
                <span>Dynamic selection</span>
                <Tooltip title='Dynamically select all objects that match the filters, including those indexed later'>
                    <FontAwesomeIcon icon={faQuestionCircle} className='text-textSecondary opacity-70' />
                </Tooltip>
            </label>
        </Tooltip>
    );
};
