import { unmonitoredLabel } from '@squaredup/monitoring';
import { defaultOnCreate } from 'components/forms/autocomplete/Autocomplete';
import Field from 'components/forms/field/Field';
import type { ShapeConfigFieldsComponent } from './ShapeConfigFields';
import { useDOMElement } from 'components/hooks/useDOMElement';

export const StateShapeConfigFields: ShapeConfigFieldsComponent = ({ name }) => {
    const menuPortalTarget = useDOMElement('dataStreamEditorSteps', null);

    return (
        <Field.Label label='Map Values to States' labelClassName='text-base'>
            <Field.Input
                name={`${name}.map.success`}
                label='Success'
                type='autocomplete'
                selectOptionsAs='valueString'
                menuPortalTarget={menuPortalTarget}
                onCreate={defaultOnCreate}
            />
            <Field.Input
                name={`${name}.map.warning`}
                label='Warning'
                type='autocomplete'
                selectOptionsAs='valueString'
                menuPortalTarget={menuPortalTarget}
                onCreate={defaultOnCreate}
            />
            <Field.Input
                name={`${name}.map.error`}
                label='Error'
                type='autocomplete'
                selectOptionsAs='valueString'
                menuPortalTarget={menuPortalTarget}
                onCreate={defaultOnCreate}
            />
            <Field.Input
                name={`${name}.map.unknown`}
                label='Unknown'
                type='autocomplete'
                selectOptionsAs='valueString'
                menuPortalTarget={menuPortalTarget}
                onCreate={defaultOnCreate}
            />
            <Field.Input
                name={`${name}.map.unmonitored`}
                label={unmonitoredLabel}
                type='autocomplete'
                selectOptionsAs='valueString'
                menuPortalTarget={menuPortalTarget}
                onCreate={defaultOnCreate}
            />
        </Field.Label>
    );
};
