import { oneMinute } from 'queries/constants';
import { UseQueryOptions, useQuery } from 'react-query';
import { PreviewUpdateSubscription, SubscriptionUpdateRequest } from 'services/TenantService';

type QueryReturnType = Awaited<ReturnType<typeof PreviewUpdateSubscription>>;

export const useSubscriptionUpdatePreview = <T = QueryReturnType>(
    subscriptionId: string,
    request: SubscriptionUpdateRequest,
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>,
    suppressToast = false
) => {
    const queryKey = [subscriptionId, JSON.stringify(request)];
    return useQuery(queryKey, () => PreviewUpdateSubscription(request, suppressToast), {
        staleTime: oneMinute,
        cacheTime: oneMinute,
        ...options
    });
};
