import { TooltipButton } from '@/components/Button';
import Text from '@/components/Text';
import { faArrowDownLeftAndArrowUpRightToCenter, faArrowUpRightAndArrowDownLeftFromCenter, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';

interface StepTitleAndControlsProps {
    title: string;
    description?: ReactNode;
}

export const StepTitleAndControls: React.FC<StepTitleAndControlsProps> = ({ title, description }) => {
    const { 
        isDocked, 
        setIsDocked, 
        setCurrentEditorStep 
    } = useTileEditorStepsContext();

    return (
        <div className='flex items-start'>
            <div className='flex-1 space-y-1'>
                <Text.H3 className='text-textPrimary'>{title}</Text.H3>

                {description &&
                    <Text.Body className='text-textSecondary'>
                        {description}
                    </Text.Body>
                }
            </div>

            <div className='inline-flex pl-4 ml-auto space-x-3 shrink-0'>
                <TooltipButton 
                    title={isDocked ? 'Undock': 'Dock'}
                    variant='tertiary' 
                    onClick={() => setIsDocked?.(!isDocked)}
                    tabIndex={999}
                >
                    <FontAwesomeIcon icon={isDocked ? 
                        faArrowDownLeftAndArrowUpRightToCenter : 
                        faArrowUpRightAndArrowDownLeftFromCenter
                    }/>
                </TooltipButton>

                
                <TooltipButton 
                    title={isDocked ? 'Undock and close': 'Close'}
                    variant='tertiary' 
                    onClick={() => {
                        setCurrentEditorStep(undefined);
                        setIsDocked?.(false);
                    }}
                    tabIndex={999}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </TooltipButton>
            </div>
        </div>
    );
};
