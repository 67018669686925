import type { DataStreamBaseTileConfig, StreamData } from '@squaredup/data-streams';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { type FC } from 'react';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { EditorSteps } from '../constants';
import TileEditorStepsContext from '../contexts/TileEditorStepsContext';
import { useTileEditorSteps, type StepWithCondition } from '../hooks/useTileEditorSteps';
import { DataStreamMetadata } from '../steps/DataStreamMetadata';
import { DataStreamTileEditorCurrentStep } from '../steps/DataStreamTileEditorCurrentStep';
import { DataStreamTileEditorStepSelector } from '../steps/DataStreamTileEditorStepSelector';
import { countModifiedColumns } from '../steps/MetadataEditor/MetadataEditorTileState';
import { AnalyticsQueryEditor } from './AnalyticsQueryEditor';

const getSteps = (config: DataStreamBaseTileConfig, data: StreamData): StepWithCondition[] => {
    const numberOfModifiedColumns = countModifiedColumns(
        config.dataStream?.metadata,
        data.metadata.columns.map((c) => c.name)
    );

    const metadataEditorSublabel = numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';

    return [
        { name: EditorSteps.query, isEnabled: true, sublabel: 'Enter your query' },
        { name: EditorSteps.metadata, isEnabled: true, sublabel: metadataEditorSublabel }
    ];
};

const stepComponents = {
    [EditorSteps.query]: AnalyticsQueryEditor,
    [EditorSteps.metadata]: DataStreamMetadata
};

export const AnalyticsQueryEditorSteps: FC = () => {
    const { tileConfig, setTileConfig } = useTileEditorContext();
    const { data } = useDataStreamConfig(tileConfig);

    const steps = getSteps(tileConfig, data);

    const stepsValue = useTileEditorSteps(steps, EditorSteps.query);

    return (
        <TileEditorStepsContext.Provider value={stepsValue}>
            <div className='flex-1 w-full h-full min-h-0 bg-tileBackground'>
                <div className='flex h-full'>
                    <DataStreamTileEditorStepSelector />
                    <DataStreamTileEditorCurrentStep
                        stepComponents={stepComponents}
                        stepProps={{ config: tileConfig, setConfig: setTileConfig }}
                    />
                </div>
            </div>
        </TileEditorStepsContext.Provider>
    );
};
