import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { omit } from 'lodash';
import { dataStreamDefinitionQueryKeys } from 'queries/queryKeys/dataStreamDefinitionKeys';
import type { Dispatch, SetStateAction } from 'react';
import type { QueryClient } from 'react-query';
import type { DataStreamAction } from './DataStreamActions';
import { type DataStreamState } from './DataStreamState';

/**
 * Dependencies used to produce side-effects.
 */
export type DataStreamStateReducerExternals = {
    queryClient: QueryClient;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
};

export type DataStreamStateInternal = DataStreamState & {
    sideEffects?: (externals: DataStreamStateReducerExternals) => void;
};

export type DataStreamStateWithDispatch = DataStreamState & {
    dispatch: (action: DataStreamAction) => void;
};

export type DataStreamStateReducer = (
    state: DataStreamStateInternal,
    action: DataStreamAction
) => DataStreamStateInternal;

export const dataStreamStateReducer: DataStreamStateReducer = (state, action): DataStreamStateInternal => {
    switch (action.type) {
        case 'dataStream.selectDataStream': {
            const isSelectingDifferentDataStream = state.selectedDataStream?.id !== action.dataStream.id;

            if (!isSelectingDifferentDataStream) {
                return state;
            }

            return {
                ...state,
                selectedDataStream: action.dataStream,
                sideEffects: ({ queryClient, setConfig }) => {
                    // Cache any data stream related data to ensure the
                    // tile editor immediately reflects the current state
                    queryClient.setQueryData(
                        dataStreamDefinitionQueryKeys.byId(action.dataStream.id),
                        action.dataStream
                    );

                    setConfig((c) => ({
                        ...c,
                        dataStream: {
                            ...omit(c.dataStream, 'pluginConfigId', 'group', 'sort', 'filter'),
                            dataSourceConfig: action.dataStream.isPreset
                                ? action.dataStream.definition.dataSourceConfig
                                : undefined,
                            id: action.dataStream.id,
                            name: action.dataStream.definition?.name
                        }
                    }));
                }
            };
        }

        case 'dataStream.clearSelectedDataStream': {
            return {
                ...state,
                selectedDataStream: undefined,
                sideEffects: ({ setConfig }) => {
                    setConfig((c) => {
                        return { ...c, dataStream: undefined };
                    });
                }
            };
        }

        case 'dataStream.updateFilters': {
            return {
                ...state,
                filters: { ...state.filters, ...action.filters }
            };
        }

        default:
            return state;
    }
};
