import { isPresetOf } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import PluginIcon from 'pages/scope/PluginIcon';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useDataStreamDefinitionsForWorkspace } from 'queries/hooks/useDataStreamDefinitions';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useDataSources } from '../hooks/useDataSources';

export const DataStreamDisplayName: React.FC = () => {
    const { config } = useDatasetContext();

    const { data: dataStreams = [], isLoading: isLoadingDataStreamDefinitions } =
        useDataStreamDefinitionsForWorkspace();
    const { data: dataStream, isLoading: isLoadingDataStreamName } = useDataStreamDefinition(config.dataStream?.id);
    const { data: plugins, isLoading: isLoadingPlugins } = useDataSources();

    if (isLoadingDataStreamName || isLoadingPlugins || isLoadingDataStreamDefinitions) {
        return null;
    }

    const pluginName = plugins?.find(({ plugin }) => plugin?.pluginId === dataStream?.pluginId)?.plugin?.name;

    const presetParentDisplayName = dataStream?.definition.presetOf
        ? dataStreams.find((parentDs) => isPresetOf(parentDs, dataStream))?.displayName ?? dataStream?.displayName
        : dataStream?.displayName;

    if (config.dataStream?.id) {
        return (
            <div className='flex items-center w-full min-w-0 space-x-2'>
                <TruncatedText title={presetParentDisplayName!} className='flex-1 min-w-0' />
                {pluginName && (
                    <div className='w-6 h-6'>
                        <PluginIcon pluginName={pluginName} />
                    </div>
                )}
            </div>
        );
    } else {
        return <span>Select a data stream</span>;
    }
};
