import { AgentGroupId, Serialised } from '@squaredup/ids';
import { UIConfig } from '@squaredup/utilities';
import Button from 'components/button/Button';
import { getOptionData, getOptionValue } from 'components/forms/autocomplete/Autocomplete';
import DisplayJsonUi, { getAllFieldsByName } from 'components/forms/jsonForms/DisplayJsonUi';
import PluginContext from 'contexts/PluginContext';
import type { Config, Plugin, ProjectedDataStreamDefinitionEntity } from 'dynamo-wrapper';
import stringify from 'fast-json-stable-stringify';
import { cloneDeep, debounce } from 'lodash';
import { TestConfigAPI } from 'pages/settings/plugins/PluginTest';
import { PluginConfigFormData } from 'pages/settings/plugins/types';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTileEditorStepsContext } from '../../contexts/TileEditorStepsContext';
import { useDataStreamForm } from '../../hooks/useConfigurableDataStreamForm';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useDOMElement } from 'components/hooks/useDOMElement';

export interface DataStreamConfigureFormProps {
    pluginConfig: Serialised<Config<object>> | undefined;
    plugin: Serialised<Plugin> | undefined;
    formTemplate: Serialised<UIConfig[]> | undefined;
    formDefaultValues: Record<string, any> | undefined;
    streamDefinitions: Serialised<ProjectedDataStreamDefinitionEntity>[];
    manualConfigApply?: boolean;
}

export const DataStreamConfigureForm = ({
    formTemplate,
    formDefaultValues,
    streamDefinitions,
    plugin,
    pluginConfig,
    manualConfigApply
}: DataStreamConfigureFormProps) => {
    const { canMoveToNextStep, nextStep } = useTileEditorStepsContext();
    const { config, setConfig } = useDatasetContext();
    const { form, formFields, defaultVisibleValues } = useDataStreamForm({
        formTemplate,
        defaultValues: formDefaultValues,
        streamDefinitions,
        pluginId: plugin?.id,
        pluginConfigId: pluginConfig?.id
    });
    
    const menuPortalTarget = useDOMElement('dataStreamEditorSteps', null);
    

    // Avoid spamming requests when someone types in a text box or otherwise changes fields quickly,
    // this also delays updating the tile config, so we don't want to delay it too much
    const setConfigDebounced = useMemo(() => debounce(setConfig, 1000), [setConfig]);

    useEffect(() => {
        // Flush any pending updates when we unmount, or setConfig changes
        return () => {
            return setConfigDebounced.flush();
        };
    }, [setConfigDebounced]);

    const {
        formState: { isSubmitting, isValid },
        watch
    } = form;

    const resolveConfig = (dataSourceConfig: object) => {
        const formFieldsByName = getAllFieldsByName(formFields ?? []);

        return Object.entries(dataSourceConfig).reduce(
            (acc, [key, value]) => {
                acc[key] = getOptionValue(value);
                if (formFieldsByName.get(key)?.type === 'key-value') {
                    // Keep key-value objects verbatim
                    acc[key] = value;
                } else {
                    acc[key] = getOptionValue(value);
                }

                /**
                 * If the option has data, include it in the
                 * dataSourceConfig alongside the value.
                 */
                const data = getOptionData(value);
                if (data) {
                    acc[`${key}_data`] = data;
                }

                return acc;
            },
            {} as Record<string, unknown>
        );
    };

    const [previousFormState, setPreviousFormState] = useState(() =>
        stringify(resolveConfig(defaultVisibleValues ?? {}))
    );

    const setDataStreamConfig = (dataSourceConfig: object) => {
        const resolvedConfig = resolveConfig(dataSourceConfig);

        const configHasChanged = stringify(config.dataStream?.dataSourceConfig ?? {}) !== stringify(resolvedConfig);

        if (configHasChanged) {
            (manualConfigApply ? setConfig : setConfigDebounced)((c) => ({
                ...c,
                dataStream: {
                    ...c.dataStream,
                    dataSourceConfig: cloneDeep(resolvedConfig)
                }
            }));
        }
    };

    const formData = watch();

    const currentFormState = stringify(resolveConfig(formData));

    if (!manualConfigApply && isValid && formFields != null) {
        setDataStreamConfig(formData);
    }

    const handleInteractiveTest = async (testName: string, formDataToTest: Record<string, unknown>) => {
        const pluginConfigToUse = {
            ...pluginConfig!.config!,
            agentGroupId: pluginConfig?.agentGroupId
                ? new AgentGroupId(pluginConfig.agentGroupId.toString())
                : undefined,
            ___testName: testName, // May be better to add extra arg to the whole test config route?
            ___dataSourceConfig: formDataToTest
        } as any as PluginConfigFormData;
        const testAPI = TestConfigAPI(plugin, pluginConfig?.id);
        const result = await testAPI({ data: pluginConfigToUse });
        return result;
    };

    const isFirstFormFieldTypeText =
        (formFields != null && formFields[0].type === 'text') ||
        (formFields != null && formFields[0].type === 'textarea');

    return formFields ? (
        <PluginContext.Provider
            value={{
                plugin,
                config: pluginConfig! as Record<string, unknown>,
                testFunction: handleInteractiveTest
            }}
        >
            <FormProvider {...form}>
                <form className='flex flex-col flex-1 h-full min-w-0 min-h-0 pt-4 space-y-2'>
                    <div className='flex-1 w-full overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary'>
                        <DisplayJsonUi
                            includeDisplayNameField={false}
                            formFields={formFields}
                            autoFocus={isFirstFormFieldTypeText}
                            menuPortalTarget={menuPortalTarget}
                        />
                    </div>
                    <div className='flex justify-end flex-shrink-0 text-base'>
                        {manualConfigApply && (
                            <Button
                                type='button'
                                variant='secondary'
                                disabled={!isValid || previousFormState === currentFormState}
                                className='mr-2'
                                onClick={() => {
                                    setPreviousFormState(currentFormState);
                                    setDataStreamConfig(formData);
                                }}
                            >
                                Apply
                            </Button>
                        )}
                        <Button
                            type='button'
                            variant='primary'
                            disabled={
                                isSubmitting ||
                                !isValid ||
                                (manualConfigApply && previousFormState !== currentFormState)
                            }
                            onClick={() => nextStep()}
                        >
                            Next
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </PluginContext.Provider>
    ) : (
        <div className='flex items-end flex-1 mt-4 text-base md:justify-end'>
            <div>
                <Button variant='primary' disabled={!canMoveToNextStep} onClick={nextStep}>
                    Next
                </Button>
            </div>
        </div>
    );
};
