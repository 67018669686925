import { getScheme } from '@squaredup/tenants';

function setScheme(u: string): string {
    const scheme = getScheme(u);
    if (!scheme) {
        // Likely domain on its own, e.g. foo.com, which means implied
        // http(s) so add https:// for parse test.
        u = 'https://' + u;
    }
    return u;
}

/**
 * Tests a given URL against an origin to determine if it passes
 * Modified from https://github.com/google/csp-evaluator/blob/master/utils.ts
 * @param origin CSP header origin
 * @param input URL to test
 * @returns True if URL is allowed by the CSP header origin
 */
export const checkOriginMatches = (origin: string, input: string) => {
    // non-Chromium browsers don't support wildcards in domain names. We work
    // around this by replacing the wildcard with `wildcard_placeholder` before®
    // parsing the domain and using that as a magic string. This magic string is
    // encapsulated in this function such that callers of this function do not
    // have to worry about this detail.
    const originUrl = new URL(
        setScheme(
            origin
                .replace(':*', '') // Remove wildcard port
                .replace('*', 'wildcard_placeholder')
        )
    );
    const inputUrl = new URL(setScheme(input));

    // get schemes
    const originScheme = getScheme(origin);
    const inputScheme = getScheme(input);

    // get hosts
    const originHost = originUrl.hostname.toLowerCase();
    const originHostHasWildcard = originHost.startsWith('wildcard_placeholder.');
    const originWildcardFreeHost = originHost.replace(/^\wildcard_placeholder/iu, '');

    //get ports
    const originPort = originUrl.port;
    const inputPort = inputUrl.port;

    if (originPort && originPort !== inputPort) {
        // Port does not match
        return false;
    }

    // get paths
    const originPath = originUrl.pathname;
    const originHasPath = originPath !== '/';

    if (originScheme && originScheme !== inputScheme && !(originScheme === 'http' && inputScheme === 'https')) {
        // Scheme does not match, and automatic upgrade to https not occurring
        return false;
    }

    const domain = inputUrl.hostname;
    if (!domain.endsWith(originWildcardFreeHost)) {
        // Domains don't match.
        return false;
    }

    // If the host has no subdomain wildcard and doesn't match, continue.
    if (!originHostHasWildcard && originHost !== domain) {
        return false;
    }

    // If the allowlisted url has a path, check if one of the url paths
    // match.
    if (originHasPath) {
        // https://www.w3.org/TR/CSP2/#source-list-path-patching
        if (originPath.endsWith('/')) {
            if (!inputUrl.pathname.startsWith(originPath)) {
                return false;
            }
        } else {
            if (inputUrl.pathname !== originPath) {
                // Path doesn't match.
                return false;
            }
        }
    }

    // Everything looks good
    return true;
};

export const validateUrlAgainstOrigins = (input: string, origins: string[]) => {
    const allOrigins = [
        ...origins,
        '*.squaredup.com' // included in our CSP policy by default
    ];
    return allOrigins.some((o) => checkOriginMatches(o, input));
};
