import { Serialised } from '@squaredup/ids';
import { MatchCriteria } from '@squaredup/utilities';
import type { DataStreamDefinitionEntity, Plugin } from 'dynamo-wrapper';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useQuery } from 'react-query';
import { Get, PLUGIN_DETAIL } from 'services/PluginService';
import { useDataSources } from './useDataSources';

const dataStreamMatchCriteriaToGremlin = (matchSpec: Serialised<MatchCriteria>) => {
    return Object.entries(matchSpec).map(([prop, criteria]) => {
        // Some property names include the legacy `.0` suffix, we remove that with the following regex replace
        const propName = prop.replace(/.0$/u, '');

        // Having a a simple key for the prop is the same as an equals match so simply return that
        if (typeof criteria === 'string') {
            return `.has("${propName}", "${criteria}")`;
        }

        const matchType = criteria.type;

        switch (matchType) {
            case 'oneOf':
                return `.has("${propName}", within(${criteria.values.map((value: string) => `"${value}"`).join(',')}))`;
            case 'contains':
                return `.has("${propName}", containing("${criteria.value}"))`;
            case 'equals':
                return `.has("${propName}", "${criteria.value}")`;
            case 'any':
                return `.has("${propName}")`;
            case 'regex':
                return `.has("${propName}", regex("${criteria.pattern}"))`;
            default:
                return '';
        }
    });
};

/**
 * Construct a plugin match query
 * If the plugin doesn't match all nodes we restrict to only those nodes from config instances of the plugin
 * Unless the stream is scoped by data source
 * @param isPluginMatchesAll Does the plugin have a top-level matches: all declartion
 * @param pluginConfigIds Config IDs for the plugin in question
 * @returns Gremlin query
 */
const getPluginMatchQuery = (isPluginMatchesAll: boolean, pluginConfigIds: string[]) => {
    // If plugin has a top-level we don't filter objects by the plugin
    if (isPluginMatchesAll || pluginConfigIds.length === 0) {
        return '';
    }

    // Otherwise ensure we're restricting to all config IDs for the relevant plugin
    // Would be nice if could just say sourceName={pluginName} but it's not guaranteed to match
    return `.has("__configId", within(${pluginConfigIds.map((id) => `"${id}"`).join(',')}))`;
};

export const getGremlinQueryForDataStream = (
    plugin: Serialised<Plugin>,
    pluginConfigIds: string[],
    dataStream: Serialised<DataStreamDefinitionEntity> | undefined
) => {
    // Build a query to restrict objects based on the plugin's top-level match critieria
    const pluginMatchQuery = getPluginMatchQuery(plugin.matches === 'all', pluginConfigIds);

    const sourceMatchCriteria = dataStream?.definition?.matches;

    // If there's no source match criteria or set to 'all' / 'none', then just return the plugin criteria
    if (!sourceMatchCriteria || typeof sourceMatchCriteria === 'string') {
        return {
            isLoading: false,
            query: pluginMatchQuery
        };
    }

    // Otherwise combine everything
    const matchCriteria = Array.isArray(sourceMatchCriteria) ? sourceMatchCriteria : [sourceMatchCriteria];
    const gremlinMatchQueries = matchCriteria.map((criteria) => dataStreamMatchCriteriaToGremlin(criteria));
    const gremlinMatchQueriesWithOrPrefixStep = gremlinMatchQueries.map((gremlin) => `__${gremlin.join('')}`);

    return {
        isLoading: false,
        query: `${pluginMatchQuery}.or(${gremlinMatchQueriesWithOrPrefixStep.join(', ')})`
    };
};

/**
 * @param dataStreamId string
 * Uses the current data stream id to fetch the data stream and generate a gremlin query based on
 * the data stream's match criteria. e.g. matches: sourceType, oneOf, ["lambdaFunction", "dynamoDB"]
 * returns: .has("sourceType", within("lambdaFunction", "dynamoDB")
 * @returns Gremlin query string
 */
export const useDataStreamMatchesToGremlin = (dataStreamId?: string) => {
    const { data: dataStream, isLoading: isLoadingDataStream } = useDataStreamDefinition(dataStreamId);

    // Get the plugin
    const { data: plugin, isLoading: isLoadingPlugin } = useQuery(
        [PLUGIN_DETAIL, dataStream?.pluginId],
        async () => Get(dataStream?.pluginId!),
        {
            enabled: Boolean(dataStream?.pluginId)
        }
    );

    // Get configured instances of this plugin in the workspace
    const { data: pluginConfigIds, isLoading: isLoadingConfigs } = useDataSources({
        select: (configs) => configs.filter((c) => c.plugin?.pluginId === dataStream?.pluginId).map((c) => c.id)
    }, false);

    const isLoading = isLoadingDataStream || isLoadingPlugin || isLoadingConfigs;

    if (isLoading || !plugin || !pluginConfigIds) {
        return { isLoading, query: undefined };
    }

    return getGremlinQueryForDataStream(plugin, pluginConfigIds as unknown as string[], dataStream);
};
