import { StreamDataColumn, findColumns, number, preferred, required, state, string } from '@squaredup/data-streams';
import { Result } from '@squaredup/utilities';
import { ColumnDropdown } from '../sharedComponents/ColumnDropdown';
import { DataStreamBarChartConfig } from './Config';
import { getBarChartColumns } from './dataUtils';

export const BarDataMappingForm = ({
    columns,
    config,
    onChange
}: {
    columns: StreamDataColumn[];
    config: DataStreamBarChartConfig;
    onChange: (data: any) => void;
}) => {
    // Get all the columns using specs
    const labelColumns = findColumns(
        columns,
        required('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred('role', 'label'),
        required('visible', true)
    ).getValue([]);

    const valueColumns = findColumns(
        columns,
        required('valueShapeName', number.name),
        preferred('role', 'value')
    ).getValue([]);

    const defaultYAxis = valueColumns[0];
    const defaultXGroup = labelColumns.length > 1 ? labelColumns[1] : undefined;
    const defaultXAxis = labelColumns.length > 0 ? labelColumns[0] : undefined;

    const resolvedColumns = getBarChartColumns(columns, config);
    const {
        value: { labelColumn: currentXAxisDataCol }
    } = resolvedColumns;
    const groupColumns = labelColumns.map((c) => c.column).filter((c) => c.name !== currentXAxisDataCol?.column.name);

    return (
        <>
            <ColumnDropdown
                label='X-Axis'
                name='xAxisData'
                help='Select the column to use for the X-Axis of the chart'
                columns={labelColumns.map((c) => c.column)}
                defaultColumn={defaultXAxis ? Result.success(defaultXAxis) : undefined}
                config={config}
                onChange={onChange}
            />
            <ColumnDropdown
                label='Y-Axis'
                name='yAxisData'
                help='Select the column to use for the Y-Axis of the chart'
                columns={valueColumns.map((c) => c.column)}
                defaultColumn={defaultYAxis ? Result.success(defaultYAxis) : undefined}
                config={config}
                onChange={onChange}
            />
            <ColumnDropdown
                label='Series'
                name='xAxisGroup'
                help='Select the column used to split the X-Axis values into series'
                showNoneOption={true}
                columns={groupColumns}
                defaultColumn={defaultXGroup ? Result.success(defaultXGroup) : undefined}
                config={config}
                disabled={groupColumns.length === 0}
                onChange={onChange}
            />
        </>
    );
};
