import { cn } from '@/lib/cn';
import { scopeLimitMaximum, scopeLimitWarning } from '@squaredup/constants';
import { useTileEditorObjectsFilterContext } from 'ui/editor/dataStream/contexts/TileEditorObjectsFilterContext';

const LimitMessage = ({ message, className }: { message: string; className: string }) => (
    <div className={cn('pb-2', className)}>
        {message}{' '}
        <a className='text-textLink' href='https://squaredup.com/cloud/scope' target='_blank' rel='noreferrer noopener'>
            Learn more.
        </a>
    </div>
);

interface ScopeObjectCountLimitMessageProps {
    className?: string;
}

export const ScopeObjectCountLimitMessage: React.FC<ScopeObjectCountLimitMessageProps> = ({ className }) => {
    const { selectedObjectsCount, dynamicObjectsCount, objectLimit, isDynamic, filteredObjects } =
        useTileEditorObjectsFilterContext();

    if (objectLimit != null) {
        return null;
    }

    const scopeLimitWarningMessage = (
        <LimitMessage
            message='This collection is large and may impact dashboard performance.'
            className={cn('text-statusWarningPrimary', className)}
        />
    );

    const fixedScopeMaximumMessage = (
        <LimitMessage message='You have selected the maximum number of objects.' className={cn('text-statusErrorPrimary', className)} />
    );

    const scopeLimitMaximumMessage = (
        <LimitMessage
            message={`This collection is too large and will be limited to ${scopeLimitMaximum} objects.`}
            className={cn('text-statusErrorPrimary', className)}
        />
    );

    return (
        <>
            {!isDynamic &&
                selectedObjectsCount > scopeLimitWarning &&
                selectedObjectsCount < scopeLimitMaximum &&
                scopeLimitWarningMessage}
            {!isDynamic && selectedObjectsCount === scopeLimitMaximum && fixedScopeMaximumMessage}
            {!isDynamic && selectedObjectsCount > scopeLimitMaximum && scopeLimitMaximumMessage}
            {isDynamic &&
                !filteredObjects.isFetchingObjects &&
                dynamicObjectsCount > scopeLimitWarning &&
                dynamicObjectsCount < scopeLimitMaximum &&
                scopeLimitWarningMessage}
            {isDynamic &&
                !filteredObjects.isFetchingObjects &&
                dynamicObjectsCount >= scopeLimitMaximum &&
                scopeLimitMaximumMessage}
        </>
    );
};
