import { cn } from '@/lib/cn';
import LoadingSpinner from 'components/LoadingSpinner';
import { SearchIcon } from 'components/SearchIcon';
import Button from 'components/button/Button';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import Fuse from 'fuse.js';
import trackEvent, { trackEventDebounced } from 'lib/analytics';
import { useFlag } from 'lib/useFlag';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import { scopeQueryKeys } from 'queries/queryKeys/scopeKeys';
import { variableObjectQueryKeys } from 'queries/queryKeys/variableObjectKeys';
import { useMemo, useState, type FC } from 'react';
import { useQueryClient } from 'react-query';
import { SearchBar } from '../SearchBar';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataSourceFilterDropdown } from '../dataStream/DataSourceFilterDropdown';
import { DataStreamVirtualList } from '../dataStream/DataStreamVirtualList';
import { ScopeFilterDropdown } from '../dataStream/ScopeFilterDropdown';
import { TypeFilterDropdown } from '../dataStream/TypeFilterDropdown';
import { useDataStreamFilters, type DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { useTileEditorScopes } from '../hooks/useScopes';
import { StepTitleAndControls } from '../newLayout/StepTitleAndControls';
import { useIsNewEditorLayout } from '../newLayout/useIsNewEditorLayout';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { useTileEditorDataStreams } from '../state/useTileEditorDataStreams';
import { AddDataSourceModal } from './DataSourceModal/AddDataSourceModal';
import { DataStreamTagsFilter } from './DataStreamTagsFilter';

export const DataStreamStep: FC<{
    dataStreamFilters: ReturnType<typeof useDataStreamFilters>;
}> = ({ dataStreamFilters }) => {
    const [showAddDataSourceModal, setShowAddDataSourceModal] = useState(false);
    const [scopeModalOpen, setScopeModalOpen] = useState(false);
    const [editingScope, setEditingScope] = useState();

    const { canMoveToNextStep, nextStep } = useTileEditorStepsContext();
    const queryClient = useQueryClient();
    const { workspace, isGlobal } = useDataStreamWorkspaceContext();
    const dataStreamTagsFlagEnabled = useFlag('dataStreamTags');
    const newDataStreamEditor = useIsNewEditorLayout();

    const { data: scopes, isLoading: isLoadingScopes } = useTileEditorScopes();
    const {
        dataStreamFilterOptions,
        pluginFilterOptions,
        objectScopeFilterOptions,
        tagFilterOptions,
        isLoading,
        isLoadingScopeDataStreamMatches
    } = dataStreamFilters;

    const { selectedDataStreamId, filters, dispatch } = useTileEditorStore((s) => ({
        selectedDataStreamId: s.dataStream.selectedDataStream?.id,
        filters: s.dataStream.filters,
        dispatch: s.dispatch
    }));

    const { dataStreamsById } = useTileEditorDataStreams();

    const fuse = useMemo(() => {
        if (isLoading || isLoadingScopes) {
            return undefined;
        }

        return new Fuse(dataStreamFilterOptions!, {
            keys: [
                { name: 'displayNameFull', weight: 10 },
                { name: 'description', weight: 2 },
                { name: 'pluginConfigName', weight: 1 },
                { name: 'definition.matchesTypes', weight: 2 },
                { name: 'definition.tags', weight: 2 },
                { name: 'definition.matches.sourceType.value', weight: 2 }
            ],
            threshold: 0.4
        });
    }, [dataStreamFilterOptions, isLoading, isLoadingScopes]);

    const dataStreamsFilteredBySearch = filters.search
        ? fuse?.search(filters.search).map(({ item }) => item)
        : dataStreamFilterOptions;

    const onSearch = (query: string) => {
        trackEventDebounced('Data Stream Searched For', { query });
        dispatch({ type: 'dataStream.updateFilters', filters: { search: query } });
    };

    const toggleTag = (tag: string) => {
        let newTags = [...filters.tags];
        if (newTags.includes(tag)) {
            newTags = newTags.filter((t) => t !== tag);
        } else {
            newTags = [...newTags, tag];
        }
        dispatch({ type: 'dataStream.updateFilters', filters: { tags: newTags } });
    };

    if (isLoading || isLoadingScopes) {
        return (
            <div className='flex items-center justify-center flex-1 w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    const onSelectDataStream = (dataStream: DataStreamFilterOption) => {
        trackEvent('Data Stream Selected', {
            name: dataStream.displayName,
            plugin: dataStream.pluginName,
            configurable: Boolean(dataStream.template),
            configured: Boolean(dataStream.sourceTemplate)
        });

        dispatch({ type: 'dataStream.selectDataStream', dataStream: dataStreamsById.get(dataStream.id)! });
    };

    return (
        <div className={cn('flex flex-col flex-1 h-full min-w-0 min-h-0 py-7', newDataStreamEditor && 'py-4')}>
            {newDataStreamEditor && 
                <div className='pb-4 pl-6 pr-5 mb-4 border-b border-dividerTertiary'>
                    <StepTitleAndControls title='Select a data stream' />
                </div>
            }

            <div className='flex flex-col flex-1 min-w-0 min-h-0'>
                <div className='flex pl-6 pr-5 mb-3 gap-x-4'>
                    <SearchBar
                        placeholder='Search for data streams...'
                        initialValue={filters.search}
                        onChange={onSearch}
                    />

                    <DataSourceFilterDropdown
                        sources={pluginFilterOptions}
                        selectedDataSourceId={filters.dataSourceId}
                        setSelectedDataSourceId={(dataSourceId) =>
                            dispatch({ type: 'dataStream.updateFilters', filters: { dataSourceId } })
                        }
                        {...(!isGlobal && { onAddDataSource: () => setShowAddDataSourceModal(true) })}
                    />

                    <TypeFilterDropdown
                        types={objectScopeFilterOptions}
                        selectedType={filters.objectType}
                        setSelectedType={(objectType) =>
                            dispatch({ type: 'dataStream.updateFilters', filters: { objectType } })
                        }
                    />

                    {!isGlobal && (
                        <ScopeFilterDropdown
                            scopes={scopes}
                            selectedScopeId={filters.scopeId}
                            setSelectedScopeId={(scopeId) =>
                                dispatch({ type: 'dataStream.updateFilters', filters: { scopeId } })
                            }
                            onAddScope={() => setScopeModalOpen(true)}
                            onEditScope={(scope) => {
                                setEditingScope(scope);
                                setScopeModalOpen(true);
                            }}
                        />
                    )}
                </div>

                {dataStreamTagsFlagEnabled && tagFilterOptions.length > 0 && (
                    <DataStreamTagsFilter tags={tagFilterOptions} selectedTags={filters.tags} toggleTag={toggleTag} />
                )}

                {isLoadingScopeDataStreamMatches ? (
                    <div className='w-full h-full pt-2 pl-6 pr-5'>
                        <LoadingSpinner />
                    </div>
                ) : dataStreamsFilteredBySearch?.length === 0 ? (
                    <div className='flex h-full place-content-center text-textPrimary'>
                        <div className='m-auto text-center h-fit w-fit'>
                            <SearchIcon className='m-auto w-fit h-fit' />
                            <div className='-mt-8'>
                                <p className='mb-2'>Couldn't find what you are looking for?</p>
                                <Button variant={'link'} onClick={() => setShowAddDataSourceModal(true)}>
                                    Add a data source
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    // Disable the sort to avoid losing the search order if a search term is present
                    <DataStreamVirtualList
                        dataStreams={dataStreamsFilteredBySearch ?? []}
                        selectedDataStreamId={selectedDataStreamId}
                        allowSort={!filters.search}
                        selectedTags={filters.tags}
                        toggleTag={toggleTag}
                        onItemClick={onSelectDataStream}
                    />
                )}

                <div className='flex self-end mt-5 mr-5 space-x-4'>
                    <Button
                        disabled={!selectedDataStreamId || !canMoveToNextStep}
                        onClick={nextStep}
                    >
                        {!canMoveToNextStep && selectedDataStreamId ? <LoadingSpinner size={18} /> : 'Next'}
                    </Button>
                </div>
            </div>

            {scopeModalOpen && (
                <CreateEditScope
                    scope={editingScope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        setEditingScope(undefined);
                        if (modifiedScope && workspace) {
                            queryClient.invalidateQueries(scopeQueryKeys.workspace(workspace));
                            queryClient.removeQueries(variableObjectQueryKeys.all);
                            dispatch({ type: 'dataStream.updateFilters', filters: { scopeId: modifiedScope.id } });
                        }
                    }}
                />
            )}

            {showAddDataSourceModal && (
                <AddDataSourceModal
                    workspaceId={workspace}
                    onSelectedDataSource={(dataSourceId?: string) => {
                        setShowAddDataSourceModal(false);
                        // Remove the search to avoid getting stuck on the empty page
                        dispatch({
                            type: 'dataStream.updateFilters',
                            filters: { search: '', ...(dataSourceId ? { dataSourceId } : {}) }
                        });
                    }}
                />
            )}
        </div>
    );
};
