import { DataStreamScopeSchema, resolveDataStreamScopeConfig } from '@squaredup/data-streams';
import { useMemo } from 'react';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';

/**
 * @returns Resolves scope criteria if it passes the data stream scope schema
 */
export const useResolvedScope = () => {
    const { config } = useDatasetContext();

    const scopeConfig = resolveDataStreamScopeConfig(config?.scope);

    const scope = useMemo(() => {
        const scopeParse = DataStreamScopeSchema.safeParse(scopeConfig);

        return scopeParse.success ? scopeParse.data : undefined;
    }, [scopeConfig]);

    return scope;
};
