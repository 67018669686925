import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { isPresetOf } from '@squaredup/data-streams';
import LoadingSpinner from 'components/LoadingSpinner';
import { ResizablePanelResizeHandle } from 'components/ResizablePanelResizeHandle';
import { ScrollArea } from 'components/scrollArea';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useMemo, useRef, type ComponentRef, type FC } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useDataStreamTemplate } from '../../hooks/useDataStreamTemplate';
import { StepTitleAndControls } from '../../newLayout/StepTitleAndControls';
import { useIsNewEditorLayout } from '../../newLayout/useIsNewEditorLayout';
import { useTileEditorStore } from '../../state/TileEditorStoreProvider';
import { useTileEditorDataStreams } from '../../state/useTileEditorDataStreams';
import { DataStreamUserConfiguredForm } from '../DataStreamUserConfiguredForm';
import { ConfigurationPresetList } from './ConfigurationPresetList';
import { DataStreamConfigureForm } from './DataStreamConfigureForm';

export const DataStreamConfigurationStep: FC = () => {
    const { dataStreams } = useTileEditorDataStreams();
    const {
        dataStream,
        parentDataStream,
        pluginConfig,
        plugin,
        formTemplate,
        formDefaultValues,
        streamDefinitions,
        isLoading: isLoadingDataStream
    } = useDataStreamTemplate();
    const { dispatch, selectedDataStream } = useTileEditorStore((s) => ({
        selectedDataStream: s.dataStream.selectedDataStream,
        dispatch: s.dispatch
    }));
    const newDataStreamEditor = useIsNewEditorLayout();

    // We want to show the read-only form data if the dataStream is a legacy configured stream
    const { data: sourceDataStream, isLoading: isLoadingSourceDataStream } = useDataStreamDefinition(
        dataStream?.sourceTemplate
    );

    const panelGroupRef = useRef<ComponentRef<typeof PanelGroup>>(null);

    const resetPanelLayout = () => {
        if (!panelGroupRef.current) {
            return;
        }
        panelGroupRef.current.setLayout([{ sizePercentage: 33 }, { sizePercentage: 67 }]);
    };

    const hasOrIsPreset = useMemo(
        () =>
            dataStream != null &&
            (dataStream.definition.presetOf != null ||
                streamDefinitions.some((preset) => isPresetOf(dataStream, preset))),
        [dataStream, streamDefinitions]
    );

    const isLoading = isLoadingDataStream || isLoadingSourceDataStream;

    if (isLoading || dataStream == null) {
        return (
            <div className='flex items-center justify-center flex-1 w-full h-full'>
                <LoadingSpinner className='m-auto' />
            </div>
        );
    }

    const manualConfigApply = dataStream.definition.manualConfigApply || parentDataStream?.definition.manualConfigApply;

    return (
        <div className={cn('flex flex-col h-full min-h-0 pl-6 pr-5 py-7', newDataStreamEditor && 'py-4 px-0 flex-1')}>
            {newDataStreamEditor ? (
                <div className='pb-4 pl-6 pr-5 space-y-2 border-b border-dividerTertiary'>
                    <StepTitleAndControls title='Configure parameters' />
                </div>
            ) : (
                <Text.H3 className='text-textPrimary'>Parameters</Text.H3>
            )}

            <div className={cn('flex flex-col flex-1 h-full min-h-0 text-sm', newDataStreamEditor && 'pl-6 pr-5')}>
                {sourceDataStream ? (
                    <DataStreamUserConfiguredForm
                        dataStream={dataStream!}
                        sourceDataStream={sourceDataStream}
                        formTemplate={sourceDataStream.template}
                        defaultValues={dataStream?.definition.dataSourceConfig as Record<string, any>}
                        streamDefinitions={streamDefinitions}
                        plugin={plugin}
                        pluginConfig={pluginConfig}
                    />
                ) : hasOrIsPreset ? (
                    <PanelGroup ref={panelGroupRef} direction='horizontal' className='grid h-full gap-2'>
                        <Panel collapsible={true} minSizePixels={200} defaultSizePercentage={33}>
                            <ScrollArea className='h-full min-h-0'>
                                <div className='flex flex-col gap-4 pt-4'>
                                    <span className='text-textSecondary'>
                                        Select from a preset below, or configure manually.
                                    </span>
                                    <ConfigurationPresetList
                                        dataStreams={dataStreams}
                                        selectedDataStream={selectedDataStream}
                                        onChange={(ds) => {
                                            dispatch({ type: 'tileEditor.selectPreset', dataStream: ds });
                                        }}
                                    />
                                </div>
                            </ScrollArea>
                        </Panel>
                        <ResizablePanelResizeHandle direction='horizontal' onResetLayout={resetPanelLayout} />
                        <Panel collapsible={true} minSizePixels={200}>
                            <div className='h-full pl-8 border-l-2 border-dividerTertiary'>
                                <DataStreamConfigureForm
                                    key={dataStream.id} // Reset the form state when the selected data stream changes
                                    formTemplate={formTemplate}
                                    formDefaultValues={formDefaultValues}
                                    streamDefinitions={streamDefinitions}
                                    plugin={plugin}
                                    pluginConfig={pluginConfig}
                                    manualConfigApply={manualConfigApply}
                                />
                            </div>
                        </Panel>
                    </PanelGroup>
                ) : (
                    <DataStreamConfigureForm
                        key={dataStream.id} // Reset the form state when the selected data stream changes
                        formTemplate={formTemplate}
                        formDefaultValues={formDefaultValues}
                        streamDefinitions={streamDefinitions}
                        plugin={plugin}
                        pluginConfig={pluginConfig}
                        manualConfigApply={manualConfigApply}
                    />
                )}
            </div>
        </div>
    );
};
