import { useEffect, useState } from 'react';
/**
 * Hook that returns the DOM element with the given ID. We ensure the caller is mounted
 * by using a useEffect as it may be a React component we are targeting. We default to using
 * the body if the element is not available and the user doesn't allow null.
 */
export const useDOMElement = (id?: string, defaultValue: HTMLElement | null = document.body) => {
    const [DOMElement, setDOMElement] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setDOMElement(id ? document.getElementById(id) ?? defaultValue : defaultValue);

        return () => setDOMElement(null);
    }, [id, defaultValue]);

    return DOMElement;
};
