import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { migrateDynamicScopeToBooleanQuery } from '@squaredup/graph';
import { baseDataStreamConfig } from 'dashboard-engine/constants';
import { invalidateScopeCache } from 'pages/dashboard/components/utils/variableLocalStorageUtils';
import { useMemo, useState } from 'react';
import { ObjectFilters } from 'ui/editor/dataStream/TileEditor/state/scope/ObjectFilterState';
import { createScopeState } from 'ui/editor/dataStream/TileEditor/state/scope/ScopeState';
import { createScopeStore } from 'ui/editor/dataStream/TileEditor/state/scope/ScopeStore';
import { ObjectsFilterContextWrapper } from 'ui/editor/dataStream/TileEditor/steps/ObjectsFilterContextWrapper';
import { CreateEditScopeModal } from './CreateEditScopeModal';
import type { Scope } from './ScopesPage';
import { migrateTagsToPropertyFilters } from './migrateTagsToPropertyFilters';
import DatasetContext, { datasetContextDefaultValue } from 'ui/editor/dataStream/contexts/DatasetContext';

interface CreateEditScopeProps {
    scope?: Scope;
    currentScope?: {
        query: string;
        queryDetail: any;
        bindings: any;
    };
    filters?: ObjectFilters;
    defaultName?: string;
    onClose: (modifiedScope?: { id: string }) => void;
}

/**
 * Provides the needed contexts for CreateEditScopeModal to be used on the Scopes page
 */
export const CreateEditScope: React.FC<CreateEditScopeProps> = ({
    scope,
    currentScope,
    filters,
    defaultName,
    onClose
}) => {
    const existingScope = scope
        ? {
              id: scope.id,
              version: scope.data.version,
              workspace: scope.workspaceId,
              scope: scope.data.scope,
              displayName: scope.displayName,
              isDependency: scope.data.isDependency,
              query: scope.data.query,
              queryDetail: JSON.parse(scope?.data.queryDetail || '{}'),
              bindings: JSON.parse(scope?.data.bindings || '{}')
          }
        : undefined;

    migrateDynamicScopeToBooleanQuery(existingScope?.queryDetail);
    migrateTagsToPropertyFilters(existingScope, existingScope?.queryDetail?.tags);

    const [config, setConfig] = useState<DataStreamBaseTileConfig>({
        ...baseDataStreamConfig,
        scope: existingScope ?? currentScope
    });

    const store = useMemo(
        () =>
            createScopeStore({
                externals: { setConfig },
                initialState: createScopeState({
                    scopeConfig: config.scope,
                    defaultFilters: filters,
                    currentWorkspaceId: '',
                    variables: [],
                    configVariables: []
                })
            }),
        // The store gets created once and never changes,
        // we ignore any changes to the config or filters props
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const { dispatch, ...state } = store();

    return (
        <DatasetContext.Provider
            value={{
                ...datasetContextDefaultValue,
                config,
                setConfig
            }}
        >
            <ObjectsFilterContextWrapper
                state={state}
                dispatch={dispatch}
                selectedDataStream={undefined}
                loadWithoutDataStream={true}
            >
                <CreateEditScopeModal
                    existingScope={existingScope}
                    defaultName={defaultName}
                    onClose={(editedScope) => {
                        invalidateScopeCache(editedScope?.id);
                        onClose(editedScope);
                    }}
                />
            </ObjectsFilterContextWrapper>
        </DatasetContext.Provider>
    );
};
