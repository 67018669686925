import { cn } from '@/lib/cn';
import { TruncatedText } from 'components/TruncatedText';
import { Tabs } from 'ui/Tabs';
import { DataStreamTileEditorVisualizationPanel } from './DataStreamTileEditorVisualizationPanel';
import KPI from './kpis/DataStreamTileEditorKPIs';
import { DataStreamTileEditorMonitoring } from './monitoring/DataStreamTileEditorMonitoring';
import { useIsNewEditorLayout } from './newLayout/useIsNewEditorLayout';

const tabs = [
    {
        label: <TruncatedText title='Visualization'>Visualization</TruncatedText>,
        className: 'w-2/5 px-2',
        component: <DataStreamTileEditorVisualizationPanel />,
        key: 'visualization'
    },
    {
        label: <TruncatedText title='Monitoring'>Monitoring</TruncatedText>,
        className: 'w-2/5 px-2',
        component: <DataStreamTileEditorMonitoring />,
        key: 'monitoring'
    },
    {
        label: <TruncatedText title='KPI'>KPI</TruncatedText>,
        className: 'w-1/5 px-2',
        component: <KPI />,
        key: 'kpi'
    }
];

export const DataStreamTileEditorVisualizationPanelWrapper: React.FC = () => {
    const newDataStreamEditor = useIsNewEditorLayout();

    return (
        <div className={cn('flex flex-col h-full min-h-0 bg-tileBackground', newDataStreamEditor && 'border border-dividerPrimary')}>
            <Tabs 
                tabs={tabs} 
                tabsID='visualizationTabs' 
                className='w-full min-h-0' 
            />
        </div>
    );
};
