import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { hasProperty } from '@squaredup/utilities';
import { debounce } from 'lodash';
import { clarity } from 'react-microsoft-clarity';
import Auth from 'services/Auth';
import { isProd, isTestEnvironment } from './environment';

// Simple utility function to ensure that any issues with sending those events doesn't take down our code!
export default function trackEvent(eventName: string, payload: any) {
    try {
        const context: any = Auth?.user?.tenant ? { groupId: Auth.user.tenant } : {};
        if (hasProperty(window, 'analytics') && typeof window.analytics === 'object' && window.analytics != null) {
            window.analytics.track(eventName, payload, context); //eslint-disable-line no-undef
        }
    } catch (e) {
        // Never expect to hit this, it would indicate we're mis configured, or someone has messed
        // with index.html, around how we init segment
        console.error('Analytics error', e); //eslint-disable-line no-console
    }
}

export const trackEventDebounced = debounce((eventName, payload) => {
    trackEvent(eventName, payload);
}, 1000);

export const identifyUser = (tenantName: string, tenantStage: string) => {
    if (hasProperty(window, 'analytics') && typeof window.analytics === 'object' && window.analytics != null) {
        window.analytics.identify(Auth.user?.id ?? '<no user id>', {
            // Include as placeholders for future richer profile capture
            email: Auth.user?.name,
            name: Auth.user?.name,
            username: Auth.user?.name
        });

        window.analytics.group(Auth.user?.tenant ?? '<no tenant>', {
            name: tenantName,
            activeStage: tenantStage
        });
    }
};

const urlParams = new URLSearchParams(window.location.search);

export const initClarity = (tenantId: string, tenantName: string, tenantStage: string, tierTemplate: string) => {
    if ((isProd && !isTestEnvironment && tierTemplate === 'free') || urlParams.get('analytics') === 'enabled') {
        if (!clarity.hasStarted()) {
            clarity.init('nfzf2bkoi4');
            const email = Auth.user?.name ?? '<no user name>';
            clarity.identify(email, {});
            clarity.setTag('userId', Auth.user?.id ?? '<no user id>');
            clarity.setTag('userEmail', email);
            clarity.setTag('tenantName', tenantName);
            clarity.setTag('tenant', tenantId);
            clarity.setTag('stage', tenantStage);
        }
    }
};

export const initAmplitude = (tierTemplate: string) => {
    const enableClient = (isProd && !isTestEnvironment) || urlParams.get('analytics') === 'enabled';
    const enableReplay = tierTemplate === 'free' || urlParams.get('analytics') === 'enabled';
    const apiKey = isProd ? '352b152dee41e0c885c8ed5d6bf18f56' : 'd7744e19363da5203cc22e4c8bc7211c';

    if (enableClient) {
        if (enableReplay) {
            const sessionReplayTracking = sessionReplayPlugin();
            amplitude.add(sessionReplayTracking);
        }
        amplitude.init(apiKey, Auth.user?.id, {
            serverZone: 'EU',
            autocapture: true
        });
    }
};
