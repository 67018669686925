import { cn } from '@/lib/cn';
import { Datum } from '@nivo/line';
import useSize from '@react-hook/size';
import { RefObject, useRef } from 'react';
import { Legend, LegendPosition, Legends } from './Legends';

interface LegendWrapperProps {
    legend: Legend[];
    vizContainerClassname?: string;
    legendContainerClassname?: string;
    containerSizePercentage: number;
    currentlyHoveredId?: number;
    showTooltip?: boolean;
    showValuesAsPercentage?: boolean;
    vizRef: RefObject<HTMLElement>;
    legendContainerRef?: RefObject<HTMLDivElement>;
    position: LegendPosition;
    showAllItems?: boolean;
    onHover: (attributes: Datum) => void;
    onLeave: (attributes: Datum) => void;
    formatValue?: (v: unknown) => string;
    formatPercentage?: (v: unknown) => string;
}

export const LegendWrapper: React.FC<LegendWrapperProps> = ({
    legend,
    position,
    vizContainerClassname,
    legendContainerClassname,
    containerSizePercentage,
    currentlyHoveredId,
    vizRef,
    legendContainerRef,
    showTooltip,
    showValuesAsPercentage,
    showAllItems,
    children,
    onHover,
    onLeave,
    formatValue,
    formatPercentage
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, containerHeight] = useSize(containerRef);
    const isVertical = position === 'top' || position === 'bottom';

    return (
        <div ref={containerRef} className='w-full h-full'>
            <div
                className={cn('w-full h-full flex justify-center items-center', {
                    'flex-row': !isVertical && position === 'right',
                    'flex-row-reverse': !isVertical && position === 'left',
                    'flex-col': isVertical,
                    'flex-col-reverse': isVertical && position === 'top'
                })}
            >
                <div className={cn('relative overflow-hidden w-full h-full', vizContainerClassname)}>{children}</div>
                <div ref={legendContainerRef} className={cn('flex justify-center', legendContainerClassname)}>
                    <Legends
                        legend={legend}
                        vizRef={vizRef}
                        containerSize={{
                            width: isVertical ? containerWidth : containerWidth * containerSizePercentage,
                            height: !isVertical ? containerHeight : containerHeight * containerSizePercentage
                        }}
                        isVertical={isVertical}
                        currentlyHoveredId={currentlyHoveredId}
                        showTooltip={showTooltip}
                        showValuesAsPercentage={showValuesAsPercentage}
                        showAllItems={showAllItems}
                        onHover={onHover}
                        onLeave={onLeave}
                        formatValue={formatValue}
                        formatPercentage={formatPercentage}
                    />
                </div>
            </div>
        </div>
    );
};
