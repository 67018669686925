import { cn } from '@/lib/cn';
import { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import DataStreamBaseTile from 'dashboard-engine/basetiles/DataStreamBaseTile';
import { useTileEditorContext } from '../../contexts/TileEditorContext';

interface UnshapedDataTableProps {
    config?: DataStreamBaseTileConfig;
    rawHeaderLabels?: boolean;
    className?: string;
    notConfiguredError?: JSX.Element;
}

export const UnshapedDataTable: React.FC<UnshapedDataTableProps> = ({ 
    config, 
    className, 
    rawHeaderLabels, 
    notConfiguredError 
}) => {
    const { tileConfig } = useTileEditorContext();
    const tableConfig = config ?? tileConfig;

    return (
        <div className={cn('h-full', className)}>
            <DataStreamBaseTile
                config={{
                    ...tableConfig,
                    ...(tableConfig?.dataStream
                        ? {
                              dataStream: {
                                  ...tableConfig.dataStream,
                                  filter: undefined,
                                  group: undefined,
                                  sort: undefined
                              }
                          }
                        : {}),
                    visualisation: {
                        type: 'data-stream-table',
                        config: {
                            'data-stream-table': {
                                showShapeInTooltip: true,
                                useAccessorHeaderLabels: rawHeaderLabels
                            }
                        }
                    }
                }}
                notConfiguredError={notConfiguredError}
            />
        </div>
    );
};
