import { fiveMinutes } from 'queries/constants';
import { UseQueryOptions, useQuery } from 'react-query';
import { GetSubscriptionPrices } from 'services/TenantService';

type QueryReturnType = Awaited<ReturnType<typeof GetSubscriptionPrices>>;

export const useSubscriptionPrices = <T = QueryReturnType>(
    products: string[],
    options?: UseQueryOptions<QueryReturnType, unknown, T, string[]>
) => {
    const querykey = ['prices', products.sort().join()];

    return useQuery(querykey, () => GetSubscriptionPrices(products), {
        staleTime: fiveMinutes,
        cacheTime: fiveMinutes,
        ...options
    });
};