import { cn } from '@/lib/cn';
import { FormattedStreamValue, StreamData } from '@squaredup/data-streams';
import {
    ColumnDef,
    ColumnSizingInfoState,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    Row,
    SortingState,
    Updater,
    useReactTable
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import { useVisualisationContext } from 'contexts/VisualisationContext';
import { VisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import { getLinkInfo } from 'lib/getLinkInfo';
import pluralize from 'pluralize';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDrilldownContext } from 'ui/editor/dataStream/contexts/DrilldownContext';
import { Table } from 'ui/table/components/Table';
import { TableBody } from 'ui/table/components/TableBody';
import { TableCell } from 'ui/table/components/TableCell';
import { TableHead } from 'ui/table/components/TableHead';
import { TableHeader } from 'ui/table/components/TableHeader';
import { TableRow } from 'ui/table/components/TableRow';
import { DataStreamTableGlobalFilter } from './DataStreamTableGlobalFilter';
import { findTableRowLinkColumn, getTableRowLink } from './dataUtils';
import { styles as allStyles, DataStreamTableStyleModes } from './styles';
import { DataStreamTableConfig } from './types';

type DivRef = HTMLDivElement | null;

const DEFAULT_ROW_HEIGHT = 32.5;
const DEFAULT_COLUMN_WIDTH = 270;
const VIRTUALIZATION_THRESHOLD = 100;

const globalFilterFn: FilterFn<any> = (row: Row<FormattedStreamValue[]>, columnId, value) => {
    const lowerCaseFilterQuery = value.toLowerCase();
    const lowerCaseColumnValue = (row.getValue(columnId) as FormattedStreamValue).formatted.toLowerCase();

    return lowerCaseColumnValue.includes(lowerCaseFilterQuery);
};

const defaultColumn: Partial<ColumnDef<FormattedStreamValue[]>> = {
    minSize: 70,
    enableSorting: true,
    enableGlobalFilter: true
};

interface DataStreamTableContentProps {
    config: DataStreamTableConfig;
    streamData: StreamData;
    columns: ColumnDef<FormattedStreamValue[], any>[];
    columnVisibility?: Record<string, boolean>;
    columnOrder?: string[];
    columnSizing?: Record<string, number>;
    hideCount?: boolean;
    initialState?: VisualizationState;
    onStateChange?: (state: VisualizationState) => void;
}

export const DataStreamTableContent = forwardRef<HTMLDivElement, DataStreamTableContentProps>(
    ({ config, streamData, columns, columnVisibility, columnOrder, hideCount, initialState, onStateChange }, ref) => {
        const [sorting, setSorting] = useState<SortingState>(initialState?.sorting ?? []);
        const [globalFilter, setGlobalFilter] = useState(initialState?.globalFilter ?? '');
        const [columnSizingInfo, setColumnSizingInfo] = useState({} as ColumnSizingInfoState);
        const { isDrilldownEnabled } = useDrilldownContext();

        const tableRef = useRef<HTMLDivElement>(null);
        useImperativeHandle<DivRef, DivRef>(ref, () => tableRef.current);

        const navigate = useNavigate();
        const { editing } = useDashboardContext();
        const { preview } = useTileContext();
        const { updateVisualisationConfig } = useVisualisationContext();

        const { rowLinkColumnName } = config;

        const rowLinkColumn = findTableRowLinkColumn(streamData.metadata.columns, rowLinkColumnName?.value);

        const onSortingChange = (newSorting: Updater<SortingState>) => {
            const sortingInfoState = typeof newSorting === 'function' ? newSorting(sorting) : newSorting;

            setSorting(sortingInfoState);

            // save table sorting so it can be preserved when opened in fullscreen.
            onStateChange?.({ sorting: sortingInfoState });
        };

        const onGlobalFilterChange = (newFilter: string) => {
            setGlobalFilter(newFilter);

            // save global filter so it can be preserved when opened in fullscreen.
            onStateChange?.({ globalFilter: newFilter });
        };

        const onColumnSizingInfoChange = (newState: Updater<ColumnSizingInfoState>) => {
            const columnResizeInfoState = typeof newState === 'function' ? newState(columnSizingInfo) : newState;

            setColumnSizingInfo(columnResizeInfoState);

            if (editing && !columnResizeInfoState.isResizingColumn) {
                updateVisualisationConfig?.({
                    ...config,
                    resizedColumns: {
                        columnWidths: {
                            ...config.resizedColumns?.columnWidths,
                            ...getState().columnSizing
                        }
                    }
                });
            }
        };

        const { getCenterTotalSize, getHeaderGroups, getRowModel, getState } = useReactTable({
            data: streamData.rows,
            columns,
            defaultColumn,
            globalFilterFn,
            columnResizeMode: 'onChange',
            enableSorting: !config.transpose,
            enableColumnResizing: !config.transpose,
            state: {
                columnVisibility,
                columnOrder,
                columnSizingInfo,
                globalFilter,
                sorting
            },
            onSortingChange,
            onColumnSizingInfoChange,
            getColumnCanGlobalFilter: () => true,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel()
        });

        const styles = config.transpose
            ? allStyles[DataStreamTableStyleModes.transpose]
            : allStyles[DataStreamTableStyleModes.default];

        const rows = getRowModel().rows;
        const virtualizationEnabled = rows.length > VIRTUALIZATION_THRESHOLD;

        const rowVirtualizer = useVirtualizer({
            count: rows.length,
            overscan: 8,
            horizontal: Boolean(config.transpose),
            enabled: virtualizationEnabled,
            getScrollElement: () => tableRef.current!,
            estimateSize: () => (config.transpose ? DEFAULT_COLUMN_WIDTH : DEFAULT_ROW_HEIGHT)
        });

        const tableRows = virtualizationEnabled ? rowVirtualizer.getVirtualItems() : rows;

        return (
            <>
                {!preview && (
                    <DataStreamTableGlobalFilter globalFilter={globalFilter} setGlobalFilter={onGlobalFilterChange} />
                )}

                <div className='flex flex-col w-full h-full space-y-3 overflow-hidden text-sm'>
                    <div
                        className='min-h-0 h-full overflow-auto scrollbar-thin relative scrollbar-track-transparent scrollbar-thumb-statusUnknownPrimary [scrollbar-gutter:stable]'
                        ref={tableRef}
                        data-target='tableWrapper'
                    >
                        <Table
                            className={styles.table}
                            style={{
                                width: `${getCenterTotalSize()}px`,
                                ...(config.transpose && {
                                    width: virtualizationEnabled ? `${rowVirtualizer.getTotalSize()}px` : 'auto'
                                })
                            }}
                        >
                            <TableHeader className={styles.thead}>
                                {getHeaderGroups().map((headerGroup) => (
                                    <TableRow
                                        key={headerGroup.id}
                                        className={clsx(styles.theadtr, !config.transpose && 'h-[34px]')}
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <TableHead
                                                key={header.id}
                                                style={{ width: config.transpose ? 'auto' : header.getSize() }}
                                                className={styles.th}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext())}

                                                {!config.transpose && (
                                                    <div
                                                        {...{
                                                            onMouseDown: header.getResizeHandler(),
                                                            onTouchStart: header.getResizeHandler(),
                                                            className: clsx(
                                                                'absolute w-1 opacity-0 h-full bg-dividerSecondary top-0 right-0 select-none touch-none cursor-ew-resize group-hover:opacity-100',
                                                                header.column.getIsResizing() &&
                                                                    '!opacity-100 !bg-textPrimary'
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHeader>

                            <TableBody
                                className={styles.tbody}
                                {...(!config.transpose && {
                                    style: {
                                        height: virtualizationEnabled
                                            ? `${Math.max(rowVirtualizer.getTotalSize(), DEFAULT_ROW_HEIGHT)}px`
                                            : 'auto'
                                    }
                                })}
                            >
                                {rows.length > 0 ? (
                                    tableRows.map((rawRow, index) => {
                                        const row = virtualizationEnabled
                                            ? rows[rawRow.index]
                                            : (rawRow as Row<FormattedStreamValue[]>);

                                        const even = (virtualizationEnabled ? rawRow.index : index) % 2 === 0;
                                        const rowData = streamData.metadata?.rowData?.[rawRow.index];
                                        const rowLinkHref = rowLinkColumn
                                            ? getTableRowLink(rowLinkColumn, row, rowData)
                                            : null;

                                        return (
                                            <TableRow
                                                key={row.id}
                                                data-index={virtualizationEnabled ? rawRow.index : index}
                                                className={cn(
                                                    styles.tr,
                                                    !even && 'bg-tableEvenRow',
                                                    rowLinkHref &&
                                                        'hover:bg-secondaryButtonBackgroundHover cursor-pointer'
                                                )}
                                                {...(virtualizationEnabled && { ref: rowVirtualizer.measureElement })}
                                                {...(rowLinkHref && {
                                                    onClick: () => {
                                                        const linkInfo = getLinkInfo(rowLinkHref);
                                                        const isTextSelected = window?.getSelection()?.toString();

                                                        if (isTextSelected) {
                                                            return;
                                                        }

                                                        if (
                                                            linkInfo.isExternal ||
                                                            linkInfo.isOpenAccessUrl ||
                                                            !isDrilldownEnabled
                                                        ) {
                                                            window.open(rowLinkHref, '_blank');
                                                        } else {
                                                            navigate(linkInfo.relativeURL);
                                                        }
                                                    },
                                                    role: 'link',
                                                    'data-href': rowLinkHref
                                                })}
                                                {...('start' in rawRow && {
                                                    style: {
                                                        position: 'absolute',
                                                        transform: config.transpose
                                                            ? `translateX(${rawRow.start}px)`
                                                            : `translateY(${rawRow.start}px)`
                                                    }
                                                })}
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        style={{
                                                            width: config.transpose ? 'auto' : cell.column.getSize()
                                                        }}
                                                        className={styles.td}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow
                                        className={cn(
                                            '!w-full border-dividerPrimary border-x border-b',
                                            config.transpose && 'border-y border-l-0 min-w-48'
                                        )}
                                    >
                                        <TableCell
                                            colSpan={getHeaderGroups()[0]?.headers.length}
                                            className='w-full my-auto'
                                        >
                                            No data
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>

                    {Boolean(rows.length && !hideCount) && (
                        <div className='text-sm text-right text-textSecondary'>
                            {rows.length} {pluralize('result', rows.length)}
                        </div>
                    )}
                </div>
            </>
        );
    }
);
