import type { DataStream } from 'services/DataStreamDefinitionService';
import TileEditorObjectsFilterContext from '../../contexts/TileEditorObjectsFilterContext';
import { useDataStreamObjectFilters } from '../hooks/useDataStreamObjectFilters';
import type { ScopeState } from '../state/scope/ScopeState';
import type { ScopeStateWithDispatch } from '../state/scope/ScopeStore';

interface ObjectsFilterContextWrapperProps {
    state: ScopeState;
    dispatch: ScopeStateWithDispatch['dispatch'];
    selectedDataStream: DataStream | undefined;
    /**
     * If true, objects will be loaded even when no data stream is selected.
     */
    loadWithoutDataStream?: boolean;
}

/**
 * Wraps children in ObjectsFilterContext, providing functions to filter and scope objects
 */
export const ObjectsFilterContextWrapper: React.FC<ObjectsFilterContextWrapperProps> = ({
    state,
    dispatch,
    selectedDataStream,
    loadWithoutDataStream,
    children
}) => {
    const objectFilterState = useDataStreamObjectFilters(
        {
            ...state,
            dispatch
        },
        selectedDataStream,
        { loadWithoutDataStream }
    );

    return (
        <TileEditorObjectsFilterContext.Provider value={objectFilterState}>
            {children}
        </TileEditorObjectsFilterContext.Provider>
    );
};
