import { DataStreamBaseTileConfig } from '@squaredup/data-streams';

export const getInitialObjectsSelection = (scopeConfig: DataStreamBaseTileConfig['scope']): string[] | undefined => {
    // For tiles with no scope we want to initialise the editor in fixed scope mode. We therefore
    // return an empty array (`isDynamic` checks the return of this function is undefined)
    if (!scopeConfig) {
        return [];
    }

    if (
        !Array.isArray(scopeConfig) &&
        'queryDetail' in scopeConfig &&
        ('ids' in scopeConfig.queryDetail || 'list' in scopeConfig.queryDetail)
    ) {
        return scopeConfig.queryDetail.ids || scopeConfig.queryDetail.list || [];
    }
};
