import Text from '@/components/Text';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Node } from '@squaredup/graph';
import { hasStringProperty } from '@squaredup/utilities';
import clsx from 'clsx';
import LoadingSpinner from 'components/LoadingSpinner';
import Form from 'components/forms/form/Form';
import Input from 'components/forms/input/Input';
import { useClickOutside } from 'lib/useClickOutside';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SearchIcon } from './SearchIcon';
import { SearchItem } from './SearchItem';

export type SearchSource = { sourceConfigName: string; sourceInstanceName: string };

export type SearchResult = {
    node: Node;
    title: string;
    sources?: SearchSource[];
    link?: string;
    folderPath?: string[];
};

export type SearchMenuComponent<T extends SearchResult> = React.FunctionComponent<{
    activeOption?: T;
    callback?: () => void;
    visible?: boolean;
}>;

type OptionGroups<T extends SearchResult> = Record<string, T[]>;

interface SearchProps<T extends SearchResult> {
    defaultOptions: OptionGroups<T>;
    MenuComponent: SearchMenuComponent<T>;
    maxHeight: number;
    placeholder: string;
    focused: boolean;
    onFocus: React.FocusEventHandler;
    onBlur: React.FocusEventHandler;
    onSearch: (query: string) => Promise<OptionGroups<T>>;
    onSelect: (option: T) => void;
    onClose?: () => void;
}

interface SearchOptionsProps<T extends SearchResult> {
    options: OptionGroups<T>;
    activeId?: string;
    onOptionHover: (option: T) => void;
    onOptionClick: (option: T) => void;
    onClose?: () => void;
}

interface SearchGroupProps {
    title: string;
    children: React.ReactNode;
}

const keys = {
    ENTER: 13,
    ESC: 27,
    UP: 38,
    DOWN: 40
};

function Search<T extends SearchResult = SearchResult>({
    defaultOptions = {},
    MenuComponent,
    maxHeight,
    placeholder,
    focused,
    onFocus,
    onBlur,
    onSearch,
    onSelect,
    onClose
}: Partial<SearchProps<T>>) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(defaultOptions);
    const [activeOption, setActiveOption] = useState<T>();
    const [query, setQuery] = useState<string>('');
    const hasSearchOptions = Object.keys(options).length > 0;
    const wrapperRef = useRef<HTMLInputElement>(null);

    // Handle clicks outside of panel
    useClickOutside([wrapperRef], onClose);

    const findResultIndex = (id: string) => flatOptions.findIndex((option) => option.node.id === id);
    const flatOptions = flattenOptions(options);

    const debouncedSearch = useMemo(
        () =>
            debounce((searchQuery) => {
                setQuery(searchQuery);
                if (searchQuery.length > 0) {
                    const fetchResults = async () => {
                        setIsLoading(true);

                        const searchResults = onSearch ? await onSearch(searchQuery) : {};
                        setOptions(searchResults);

                        const searchResultsFlat = flattenOptions(searchResults);

                        const previouslySelected = searchResultsFlat.find((r) => r.node.id === activeOption?.node.id);
                        setActiveOption(previouslySelected ?? searchResultsFlat[0]);

                        setIsLoading(false);
                    };
                    fetchResults();
                }
            }, 500),
        [onSearch, activeOption]
    );

    const handleOnHover = (result: T) => {
        setActiveOption(result);
    };

    const handleClose = () => {
        setOptions({});
        onClose?.();
    };

    const targetResultAndScroll = (targetIndex: number) => {
        setActiveOption(flatOptions[targetIndex]);
        document.getElementById(flatOptions[targetIndex].node.id)?.scrollIntoView({ block: 'center' });
        document.getElementById('Search')?.scrollIntoView(false);
    };

    const handleKeyUp = ({ keyCode: key }: React.KeyboardEvent) => {
        if (activeOption) {
            const resultIndex = findResultIndex(activeOption.node.id);
            if (key === keys.UP && resultIndex !== 0) {
                const targetIndex = resultIndex - 1;
                targetResultAndScroll(targetIndex);
                return;
            }

            if (key === keys.DOWN && resultIndex !== flatOptions.length - 1) {
                const targetIndex = resultIndex + 1;
                targetResultAndScroll(targetIndex);
            }

            if (key === keys.ENTER) {
                if (hasStringProperty(activeOption, 'link')) {
                    navigate(activeOption.link);
                    onClose && onClose();
                } else {
                    onSelect?.(activeOption);
                }
            }
        }

        if (key === keys.ESC && onClose) {
            handleClose();
        }
    };

    return (
        <div className='relative z-10 flex flex-col w-full pointer-events-none' ref={wrapperRef}>
            <div
                className={clsx(
                    'ring-1 ring-outlinePrimary focus-within:ring-outlineSecondary flex mb-1 items-center flex-grow pr-5 text-lg text-textPrimary bg-componentBackgroundPrimary overflow-hidden transition-all duration-200 rounded-input pointer-events-auto'
                )}
            >
                <Form>
                    <Input
                        className='outline-none h-14 ring-0'
                        name='Search'
                        placeholder={placeholder || 'Search...'}
                        prepend={<FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth className='w-6 shrink-0' />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => debouncedSearch(e.target.value)}
                        onKeyUp={handleKeyUp}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        background='bg-transparent'
                        data-testid='searchInput'
                        autoComplete='off'
                        autoFocus={true}
                    />
                </Form>
                {isLoading && <LoadingSpinner size={18} />}
            </div>

            {focused && (
                <>
                    {MenuComponent && (
                        <MenuComponent activeOption={activeOption} callback={onClose}>
                            {!hasSearchOptions && query.length > 0 && !isLoading && (
                                <div className='flex flex-wrap content-center justify-center h-full'>
                                    <div className='w-1/2 m-auto pb-[10%] h-1/2'>
                                        <div className='relative w-full h-full'>
                                            <SearchIcon className='w-full h-full' />
                                            <div className='absolute left-0 right-0 overflow-visible top-3/4'>
                                                <Text.H4 className='text-center'>
                                                    There are no results matching your search.
                                                </Text.H4>
                                                <Text.Body className='text-center text-textSecondary'>
                                                    Try a different search term.
                                                </Text.Body>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {query.length === 0 && (
                                <Text.Body className='flex flex-wrap content-center justify-center h-full text-textSecondary'>
                                    Search for dashboards, tiles, objects or anything.
                                </Text.Body>
                            )}
                            {query.length > 0 && (
                                <SearchOptions
                                    options={options}
                                    onClose={onClose}
                                    activeId={activeOption?.node.id}
                                    onOptionHover={handleOnHover}
                                    onOptionClick={(option) => onSelect && onSelect(option)}
                                />
                            )}
                        </MenuComponent>
                    )}
                    {!MenuComponent && (
                        <>
                            <div
                                className='tile-scroll-overflow bg-componentBackgroundPrimary'
                                style={{ maxHeight: maxHeight }}
                            >
                                <SearchOptions
                                    options={options}
                                    onClose={onClose}
                                    activeId={activeOption?.node.id}
                                    onOptionHover={handleOnHover}
                                    onOptionClick={(option) => onSelect && onSelect(option)}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function SearchOptions<T extends SearchResult>({
    options,
    activeId,
    onClose,
    onOptionHover,
    onOptionClick
}: SearchOptionsProps<T>) {
    return (
        <div className='w-full border-dividerPrimary'>
            {Object.keys(options).map((group) => (
                <SearchGroup key={group} title={group}>
                    {options[group].map((result) => (
                        <div key={result.node.id} id={result.node.id}>
                            {hasStringProperty(result, 'link') && (
                                <NavLink to={result.link} onClick={onClose}>
                                    <SearchItem
                                        title={result.title}
                                        sources={result.sources}
                                        onMouseEnter={() => onOptionHover(result)}
                                        active={activeId === result.node.id}
                                        resultId={result.node.id}
                                        node={result.node}
                                        folderPath={result.folderPath}
                                    />
                                </NavLink>
                            )}
                            {!hasStringProperty(result, 'link') && (
                                <SearchItem
                                    title={result.title}
                                    sources={result.sources}
                                    onMouseEnter={() => onOptionHover(result)}
                                    onClick={() => onOptionClick && onOptionClick(result)}
                                    active={activeId === result.node.id}
                                    resultId={result.node.id}
                                />
                            )}
                        </div>
                    ))}
                </SearchGroup>
            ))}
        </div>
    );
}

function SearchGroup({ title, children }: SearchGroupProps) {
    return (
        <div data-testid='searchResultsGroup'>
            <Text.H4 className='pt-3 pb-1 mx-4 mb-1 truncate border-b px-[8px] text-textSearchSecondary border-dividerPrimary'>
                {title}
            </Text.H4>
            {children}
        </div>
    );
}

function flattenOptions<T extends SearchResult>(arr: OptionGroups<T>): T[] {
    const flat: T[] = [];

    Object.keys(arr).forEach((key) => {
        arr[key].forEach((result) => {
            flat.push(result);
        });
    });

    return flat;
}

Search.Group = SearchGroup;
Search.Item = SearchItem;
export default Search;
