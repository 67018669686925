import { Skeleton } from '@/components/Skeleton';
import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureKey, getFeatureDisplay, getFirstTierWithFeature, getTierTemplate } from '@squaredup/tenants';
import Button from 'components/button/Button';
import { Pill } from 'components/pill/Pill';
import { fiveMinutes, oneMinute } from 'queries/constants';
import { ComponentPropsWithoutRef } from 'react';
import { useQuery } from 'react-query';
import { PaddleSubscriptionInfo, ProjectedTenantResponse } from 'services/TenantService';
import { useContactSalesModal } from '../ContactSalesModal';
import { CurrencyCode, getPaddlePricePreviewQueryKey, PricePreview, PricePreviewOptions } from '../Paddle';
import { ENTERPRISE_PADDLE_ID, formatFeatureForPlanDisplay, plans } from '../Plans';
import { BillingCycle, CurrencySwitch, ModalPanel, useBillingCycleSwitch } from './common';

type PlanActionButtonProps = {
    canChangePlan: boolean;
    canOnlyAddUsers: boolean;
    disabled: boolean;
    openContactSalesModal: () => void;
    openCheckout: () => void;
    isUpgradeInProgress?: boolean;
};

const PlanActionButton = ({
    canChangePlan,
    disabled,
    openContactSalesModal,
    openCheckout,
    isUpgradeInProgress,
    canOnlyAddUsers
}: PlanActionButtonProps) => {
    const buttonText = canChangePlan ? (canOnlyAddUsers ? 'Add more users' : 'Upgrade') : 'Contact sales';
    return (
        <Button
            type='button'
            variant={'upgrade'}
            disabled={disabled || isUpgradeInProgress}
            className={'justify-center w-full mt-auto'}
            data-testid={canChangePlan ? 'upgrade-modal-purchase' : 'upgrade-modal-contact'}
            onClick={canChangePlan ? openCheckout : openContactSalesModal}
        >
            {isUpgradeInProgress ? 'Upgrading...' : buttonText}
        </Button>
    );
};

type PlanFeaturesProps = {
    includesPlan: string;
    features: { displayName: string; feature?: FeatureKey }[];
    tierTemplate: ReturnType<typeof getTierTemplate>;
    featureKey?: FeatureKey;
    currentUsage?: number;
};

const PlanFeatures = ({
    features,
    includesPlan,
    featureKey,
    tierTemplate,
    currentUsage,
    ...props
}: ComponentPropsWithoutRef<'div'> & PlanFeaturesProps) => {
    // Never highlight users, as users purchase them for every plan
    const featureDisplay = featureKey && featureKey !== 'users' ? getFeatureDisplay(featureKey) : undefined;
    const limit = tierTemplate?.features.find((f) => f.key === featureKey);
    const isFirstTierWithFeature =
        featureDisplay &&
        featureKey &&
        limit &&
        getFirstTierWithFeature(featureKey, currentUsage)?.displayName === tierTemplate?.displayName;

    // Inject feature into list if not normally specified
    // monitor freq needs to be under desired limit, not over
    const shownFeatures =
        isFirstTierWithFeature && !features.some((f) => f.feature === featureKey)
            ? features.concat({
                  displayName: formatFeatureForPlanDisplay(featureDisplay, limit),
                  feature: featureKey
              })
            : features;

    return (
        <div className='text-textTable leading-[16px]' {...props}>
            <Text.Body>Everything from {includesPlan} plus:</Text.Body>
            <ul className='mt-3'>
                {shownFeatures.map((feature) => (
                    <li
                        key={feature.displayName}
                        className={cn(
                            'p-2',
                            isFirstTierWithFeature &&
                                feature.feature === featureKey &&
                                'border border-upgrade bg-upgradeFaded rounded-md'
                        )}
                    >
                        <Text.Body
                            className={cn(
                                isFirstTierWithFeature &&
                                    feature.feature === featureKey &&
                                    'text-textPrimary font-semibold'
                            )}
                        >
                            <FontAwesomeIcon className='mr-3' icon={faCheck} />
                            <span>{feature.displayName}</span>
                        </Text.Body>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const PlanPrice = ({ price, billingCycle, minUsers, ...props }: ComponentPropsWithoutRef<'p'> & PlanPriceProps) => {
    const normalizeMonthly = price?.billingCycle === 'year' && ['USD', 'EUR', 'GBP'].includes(price.currencyCode);
    const cost = normalizeMonthly ? formatCurrency(price.currencyCode, price.priceRaw / 1200) : price?.price;
    const intervalUnit = normalizeMonthly ? 'month' : price?.billingCycle;

    return (
        <p className='flex items-baseline gap-2' aria-label='Plan Price' {...props}>
            {cost ? (
                <>
                    <span className='font-semibold text-[22px]'>{cost}</span>
                    <span className='text-[14px]'>per user / {intervalUnit}</span>
                    {minUsers > 1 && (
                        <span className='text-textSecondary text-[14px] ml-auto'>(min. {minUsers} users)</span>
                    )}
                </>
            ) : (
                <span className='text-[14px] leading-[33px]'>Not available with {billingCycle} billing</span>
            )}
        </p>
    );
};

type PlanPriceProps = {
    billingCycle: BillingCycle;
    minUsers: number;
    price?: PricePreview;
};

const formatCurrency = (currency: string, value: number) =>
    Intl.NumberFormat(undefined, { currency: currency, style: 'currency', currencyDisplay: 'narrowSymbol' }).format(
        value
    );

const PlanContainer = ({
    title,
    isCurrentPlan,
    children,
    ...props
}: ComponentPropsWithoutRef<'div'> & { title: string; isCurrentPlan: boolean }) => (
    <div
        className='flex flex-col flex-1 border rounded-md border-outlinePrimary bg-tileBackground'
        aria-label={`${title} Plan`}
        {...props}
    >
        <PlanTitle title={title} isCurrentPlan={isCurrentPlan} />
        <div className='flex flex-col flex-1 gap-5 px-4 py-5'>{children}</div>
    </div>
);

const PlanTitle = ({
    title,
    isCurrentPlan,
    ...props
}: ComponentPropsWithoutRef<'div'> & { title: string; isCurrentPlan: boolean }) => (
    <div className='flex items-center gap-3 px-4 py-3 border-b border-outlinePrimary' aria-label='Plan Name' {...props}>
        <Text.H2>{title}</Text.H2>
        {isCurrentPlan && <Pill className='bg-outlinePrimary text-textSecondary'>Current plan</Pill>}
    </div>
);

type ComparePlanPanelProps = {
    onClose: () => void;
    onSubmit: (plan: string, users: number) => void;
    featureKey?: FeatureKey;
    currentUsage?: number;
    organisation?: string;
    tenant: ProjectedTenantResponse;
    currencyCode: CurrencyCode;
    setCurrencyCode: (code: CurrencyCode) => void;
    subscription?: PaddleSubscriptionInfo;
    getPrices: (options: PricePreviewOptions) => Promise<Record<string, PricePreview> | undefined>;
};

export const ComparePlanPanel = ({
    onSubmit: submit,
    featureKey,
    currentUsage,
    organisation,
    tenant,
    currencyCode,
    setCurrencyCode,
    getPrices,
    subscription
}: ComparePlanPanelProps) => {
    const tier = tenant.tier;
    const feature = featureKey ? getFeatureDisplay(featureKey) : undefined;
    const defaultBilling =
        subscription && subscription.items.some((i) => i.price.billingCycle?.interval === 'month')
            ? 'monthly'
            : 'annual';
    const { billingCycle, BillingCycleSwitch } = useBillingCycleSwitch({ defaultValue: defaultBilling });
    const { contactSalesModal, openContactSalesModal } = useContactSalesModal(
        feature?.displayName ?? 'Plan and Usage',
        tenant.id,
        organisation ?? ''
    );

    const customerDetails = {
        currency: currencyCode,
        customerDetails: {
            addressId: subscription?.addressId,
            customerId: subscription?.customerId
        }
    };

    const { data: prices } = useQuery(
        getPaddlePricePreviewQueryKey(customerDetails),
        () => getPrices(customerDetails),
        {
            // Don't ask for a price preview if we know we are still waiting on customer details
            enabled: tenant.licenceData?.paddleSubscriptionId ? Boolean(subscription) : true,
            staleTime: oneMinute,
            cacheTime: fiveMinutes
        }
    );

    const plansWithPrices = plans.map(({ annualPriceId, monthlyPriceId, ...rest }) => {
        const priceId = billingCycle === 'monthly' ? monthlyPriceId : annualPriceId;
        const price = priceId ? prices?.[priceId] : undefined;

        return {
            ...rest,
            minUsers: price?.minUsers ?? 1,
            cost: price
        };
    });

    const isOnExistingPlan = tier?.expiry !== undefined;
    const canEditSubscription =
        tenant.licenceData?.paddleSubscriptionId !== undefined && tier?.tierTemplate !== undefined;
    // Need to switch this over to use the tierTemplate from the paid subscription, by looking up plansWithPrices
    const currentTierRank =
        (tier?.tierTemplate ? getTierTemplate(tier.tierTemplate)?.sortRank : undefined) ?? Number.POSITIVE_INFINITY;
    const pricesLoading = prices === undefined;

    return (
        <ModalPanel>
            <div className='flex justify-center gap-4'>
                {tenant.licenceData?.paddleSubscriptionId && !subscription ? (
                    <Skeleton className='h-[40px] w-80' />
                ) : (
                    <BillingCycleSwitch />
                )}
                <CurrencySwitch
                    isVisible={!tenant.licenceData?.paddleSubscriptionId}
                    setCurrency={setCurrencyCode}
                    value={currencyCode}
                />
            </div>
            <div className='flex flex-grow h-full gap-5'>
                {plansWithPrices.map((plan) =>
                    pricesLoading ? (
                        <Skeleton key={plan.tierTemplate?.id} className='flex-1 h-96' />
                    ) : (
                        <PlanContainer
                            key={plan.tierTemplate.id}
                            title={plan.tierTemplate.displayName}
                            isCurrentPlan={plan.tierTemplate.id === tier?.tierTemplate}
                        >
                            <PlanPrice billingCycle={billingCycle} price={plan.cost} minUsers={plan.minUsers} />
                            <hr className='border-outlinePrimary' />
                            <PlanFeatures
                                features={plan.items}
                                includesPlan={plan.includes}
                                featureKey={featureKey}
                                currentUsage={currentUsage}
                                tierTemplate={plan.tierTemplate}
                            />
                            <PlanActionButton
                                isUpgradeInProgress={tenant.licenceData?.upgradePending}
                                canChangePlan={
                                    plan.productId !== ENTERPRISE_PADDLE_ID &&
                                    (!isOnExistingPlan ||
                                        (canEditSubscription && plan.tierTemplate.sortRank >= currentTierRank))
                                }
                                canOnlyAddUsers={plan.tierTemplate.sortRank === currentTierRank}
                                disabled={!plan.cost}
                                openContactSalesModal={openContactSalesModal}
                                openCheckout={() => {
                                    if (plan.cost) {
                                        submit(plan.cost.id, plan.minUsers);
                                    }
                                }}
                            />
                        </PlanContainer>
                    )
                )}
            </div>
            <Text.Body className='my-2'>
                Check our{' '}
                <Button
                    type='button'
                    variant='link'
                    href='https://www.squaredup.com/pricing/'
                    data-testid='upgrade-pricing-link'
                >
                    website
                </Button>{' '}
                for full details about our plans or{' '}
                <Button
                    type='button'
                    variant='link'
                    onClick={openContactSalesModal}
                    data-testid='upgrade-modal-contact-footer'
                >
                    contact sales.
                </Button>
            </Text.Body>
            {contactSalesModal}
        </ModalPanel>
    );
};
