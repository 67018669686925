import { Skeleton } from '@/components/Skeleton';
import type { VisualisationConfig } from '@squaredup/data-streams';
import BarChartSkeleton from 'dashboard-engine/visualisations/DataStreamBarChart/BarChartSkeleton';
import BlocksSkeleton from 'dashboard-engine/visualisations/DataStreamBlocks/BlocksSkeleton';
import DonutSkeleton from 'dashboard-engine/visualisations/DataStreamDonut/DonutSkeleton';
import GaugeSkeleton from 'dashboard-engine/visualisations/DataStreamGaugeChart/GaugeSkeleton';
import LineGraphSkeleton from 'dashboard-engine/visualisations/DataStreamLineGraph/LineGraphSkeleton';
import ScalarSkeleton from 'dashboard-engine/visualisations/DataStreamScalar/ScalarSkeleton';
import TableSkeleton from 'dashboard-engine/visualisations/DataStreamTable/TableSkeleton';

const VisualisationSkeleton = ({
    type,
    className,
    config,
    shimmer
}: {
    type?: VisualisationConfig['type'];
    className?: string;
    config?: Record<string, unknown>;
    shimmer?: boolean;
}) => {
    switch (type) {
        case 'data-stream-line-graph':
            return <LineGraphSkeleton {...config} shimmer={shimmer} className={className} />;
        case 'data-stream-table':
            return <TableSkeleton {...config} className={className} />;
        case 'data-stream-donut-chart':
            return <DonutSkeleton {...config} className={className} />;
        case 'data-stream-gauge':
            return <GaugeSkeleton {...config} className={className} />;
        case 'data-stream-bar-chart':
            return <BarChartSkeleton {...config} className={className} />;
        case 'data-stream-scalar':
            return <ScalarSkeleton {...config} className={className} />;
        case 'data-stream-blocks':
            return <BlocksSkeleton {...config} className={className} />;
        default:
            return <Skeleton {...config} className={className} />;
    }
};

export default VisualisationSkeleton;
