import { AutocompleteOption } from 'components/forms/jsonForms/autocompleteOptions';
import Tooltip from 'components/tooltip/Tooltip';
import stringify from 'fast-json-stable-stringify';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { AggregationFormData, aggregatorOptions } from 'ui/editor/dataStream/utilities/grouping';
import { DeleteRowButton } from './DeleteRowButton';
import { useDOMElement } from 'components/hooks/useDOMElement';

export interface AggregationFormProps {
    aggregations: AggregationFormData[];
    requiredColumns: AutocompleteOption[][];
    deleteAggregation: () => void;
    fieldIndex: number;
}

/**
 * A form for configuring a single aggregation, including aggregation type and any required columns
 */
export const AggregationRow: React.FC<AggregationFormProps> = ({
    aggregations,
    requiredColumns,
    deleteAggregation,
    fieldIndex
}) => {
    const menuPortalTarget = useDOMElement('dataStreamEditorSteps', null);

    /* The grid styles only work for groupers that need 0 or 1 columns,
       which is currently all of them but could change in future */
    return (
        <>
            <div className='col-start-1'>
                <EditorSelect
                    name={`aggregations[${fieldIndex}].aggregationType`}
                    placeholder='Count'
                    options={aggregatorOptions}
                    isClearable={false}
                    menuPortalTarget={menuPortalTarget}
                />
            </div>

            {requiredColumns.map((columns, i) => {
                return (
                    <div key={`${fieldIndex}_${i}_${stringify(columns)}_${stringify(aggregations[i])}`}>
                        <EditorSelect
                            name={`aggregations[${fieldIndex}].aggregationColumns[${i}]`}
                            placeholder='Select column'
                            options={columns}
                            isClearable={false}
                            menuPortalTarget={menuPortalTarget}
                        />
                    </div>
                );
            })}

            {requiredColumns.length === 0 && (
                <div>
                    <EditorSelect 
                        name='disabled' 
                        placeholder='-' 
                        isDisabled={true} 
                        isClearable={false} 
                        options={[]} 
                        menuPortalTarget={menuPortalTarget}
                    />
                </div>
            )}

            <Tooltip title='Remove this aggregation' className='flex col-start-3 place-items-center'>
                <DeleteRowButton
                    aria-label='Remove this aggregation'
                    className='block w-full h-full'
                    onClick={deleteAggregation}
                />
            </Tooltip>
        </>
    );
};
