import LoadingSpinner from 'components/LoadingSpinner';
import { upperFirst } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import { CollapsibleFilterPanel } from 'ui/editor/components/CollapsibleFilterPanel/CollapsibleFilterPanel';
import { FilterOption } from 'ui/editor/components/CollapsibleFilterPanel/FilterOption';

interface FilterPropertyProps {
    property: string;
    selectedValues: string[];
    isLoadingValues: boolean;
    values: string[];
    disabled?: boolean;
    setPropertyFilter: (property: string, value: string) => void;
    clearPropertyFilter: (property: string) => void;
}

export const PropertyFilter: React.FC<FilterPropertyProps> = ({
    property,
    selectedValues,
    isLoadingValues,
    values,
    disabled,
    setPropertyFilter,
    clearPropertyFilter
}) => {
    const filterRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (filterRef?.current) {
            filterRef.current.scrollIntoView();
        }
    }, []);

    return (
        <CollapsibleFilterPanel
            title={upperFirst(property)}
            onClear={() => {
                clearPropertyFilter(property);
            }}
            disabled={disabled}
            ref={filterRef}
        >
            {isLoadingValues && values.length === 0 ? (
                <LoadingSpinner className='m-auto' />
            ) : (
                <>
                    {values.length > 0 ? (
                        values.map((option) => {
                            const optionLabel = String(option);

                            return (
                                <FilterOption
                                    key={optionLabel}
                                    title={optionLabel}
                                    isSelected={selectedValues ? selectedValues.includes(option) : undefined}
                                    onClick={() => {
                                        setPropertyFilter(property, option);
                                    }}
                                />
                            );
                        })
                    ) : (
                        <p className='opacity-50 pointer-events-none select-none'>No options available</p>
                    )}
                </>
            )}
        </CollapsibleFilterPanel>
    );
};
