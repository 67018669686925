
import { cn } from '@/lib/cn';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { VisualizationPreview } from '../preview/VisualizationPreview';

export const DataStreamEditorPrimaryViewingPanel = () => {
    const { editorSteps, currentEditorStep, isDocked } = useTileEditorStepsContext();
    const editorStep = isDocked ? editorSteps.find(({ name }) => name === currentEditorStep)?.component : undefined;

    return (
        <div className={cn('h-full min-h-0', editorStep && 'border bg-tileBackground border-dividerTertiary')}>
            {editorStep ?? <VisualizationPreview />}
        </div>
    );
};