import { FormattedStreamValue, state, StreamDataColumn } from '@squaredup/data-streams';
import { ColumnDef } from '@tanstack/react-table';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { memoize, orderBy, sortBy } from 'lodash';
import { memo, useMemo, useRef } from 'react';
import { useSize } from 'ui/hooks/useSize';
import { getColumnSizes } from './ColumnSize';
import { DataTableColumnHeader } from './DataStreamColumnHeader';
import { DataStreamTableContent } from './DataStreamTableContent';
import { renderCell } from './RenderCell';
import { sortDataStreamValue } from './sortDataStreamValue';
import { DataStreamTableConfig } from './types';

const getColumnDisplayName = (
    columnDisplayNames: DataStreamTableConfig['columnDisplayNames'],
    column: StreamDataColumn
) => columnDisplayNames?.[column.name] ?? column.displayName;
const accessorFn = (i: number) => (row: any) => row[i] as FormattedStreamValue;

const DataStreamTable: DataStreamVisualisation<DataStreamTableConfig> = memo(
    ({ data: streamData, config, initialState, onStateChange }) => {
        const tableWrapperRef = useRef<HTMLDivElement>(null);

        const {
            rows,
            metadata: { columns }
        } = streamData;

        const defaultColumnOrder = useMemo(() => sortBy(columns, 'displayIndex').map(({ name }) => name), [columns]);

        const {
            columnOrder = defaultColumnOrder,
            columnDisplayNames,
            hiddenColumns,
            resizedColumns,
            useAccessorHeaderLabels,
            showShapeInTooltip,
            hideCount,
            transpose
        } = config;

        const hideRowCount = hideCount || (transpose && rows.length === 1);

        // The config uses a string array to represent hidden columns but react-table uses an object for hiding/showing.
        const columnVisibility = useMemo(
            () =>
                columns.reduce(
                    (visibleColumns, column) => {
                        visibleColumns[column.name] = hiddenColumns
                            ? !hiddenColumns.includes(column.name)
                            : column.visible;
                        return visibleColumns;
                    },
                    {} as Record<string, boolean>
                ),
            [hiddenColumns, columns]
        );

        const [tableWidth] = useSize(tableWrapperRef, true);

        const columnSizes = useMemo(() => {
            const calculatedColumnSizes = getColumnSizes(columns, rows, resizedColumns);

            // If there is a target width, make the last visible column span the remaining space (if not already filled)
            if (tableWidth) {
                const visibleColumns = orderBy(
                    Object.entries(columnVisibility)
                        .filter(([, isVisible]) => isVisible)
                        .map(([columnName]) => columnName),
                    (column) => columnOrder?.indexOf(column)
                );

                // If the last visible column has been resized just return the column sizes as we dont' want
                // to override the set column width
                const lastVisibleColumn = visibleColumns[visibleColumns.length - 1];
                if (Object.keys(resizedColumns?.columnWidths || {}).includes(lastVisibleColumn)) {
                    return calculatedColumnSizes;
                }

                const visibleColumnsTotalWidth = Object.entries(calculatedColumnSizes).reduce(
                    (totalWidth, [columnName, columnSize]) => {
                        if (columnVisibility[columnName]) {
                            return totalWidth + columnSize;
                        }

                        return totalWidth;
                    },
                    0
                );

                if (visibleColumnsTotalWidth < tableWidth) {
                    return {
                        ...calculatedColumnSizes,
                        [lastVisibleColumn]:
                            calculatedColumnSizes[lastVisibleColumn] + (tableWidth - visibleColumnsTotalWidth - 1)
                    };
                }
            }

            return calculatedColumnSizes;
        }, [columns, rows, resizedColumns, tableWidth, columnVisibility, columnOrder]);

        const columnsConfig: ColumnDef<FormattedStreamValue[], any>[] = useMemo(
            () =>
                columns?.map((c, i) => ({
                    id: c.name || String(i),
                    sortingFn: memoize(
                        sortDataStreamValue(columns),
                        (rowA, rowB, columnId) =>
                            `${(rowA.getValue(columnId) as FormattedStreamValue).value}-${
                                (rowB.getValue(columnId) as FormattedStreamValue).value
                            }-${c.shapeName}`
                    ),
                    size: columnSizes[c.name],
                    cell: renderCell(
                        c,
                        transpose ? { align: 'left' as const } : undefined,
                        streamData.metadata?.rowData
                    ),
                    accessorFn: accessorFn(i),
                    sortDescFirst: c.shapeName === state.name, // TODO: Should other columns, e.g. numbers be sorted desc first?
                    header: ({ column }) => (
                        <DataTableColumnHeader
                            key={c.name}
                            column={column}
                            title={useAccessorHeaderLabels ? c.name : getColumnDisplayName(columnDisplayNames, c)}
                            showShapeInTooltip={showShapeInTooltip}
                            tooltipText={useAccessorHeaderLabels ? getColumnDisplayName(columnDisplayNames, c) : c.name}
                            columnShapeName={c.shapeName}
                            columnRole={c.role}
                        />
                    )
                })),
            [
                columns,
                columnDisplayNames,
                columnSizes,
                showShapeInTooltip,
                useAccessorHeaderLabels,
                transpose,
                streamData.metadata?.rowData
            ]
        );

        if (!rows || rows?.length === 0) {
            return <NoDataPlaceholder />;
        }

        return (
            <div className='w-full h-full max-h-full pb-2' data-visualization='data-stream-table'>
                <DataStreamTableContent
                    ref={tableWrapperRef}
                    config={config}
                    columns={columnsConfig}
                    streamData={streamData}
                    columnVisibility={columnVisibility}
                    columnOrder={columnOrder}
                    hideCount={hideRowCount}
                    initialState={initialState}
                    onStateChange={onStateChange}
                />
            </div>
        );
    }
);

export default DataStreamTable;
