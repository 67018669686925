import Text from '@/components/Text';
import { cn } from '@/lib/cn';
import { TruncatedText } from 'components/TruncatedText';
import { FC, ReactNode, type MouseEvent } from 'react';

interface FilterOptionProps {
    title: string;
    icon?: ReactNode;
    children?: ReactNode;
    className?: string;
    isSelected?: boolean;
    disabled?: boolean;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const FilterOption: FC<FilterOptionProps> = ({
    title,
    icon,
    className,
    isSelected,
    disabled,
    children,
    onClick
}) => {
    return (
        <div className='flex overflow-hidden'>
            <button
                className={cn(
                    (isSelected || disabled) && 'text-textPrimary font-bold',
                    (!isSelected || disabled) && 'text-textSecondary hover:text-textPrimary',
                    disabled && 'hover:text-textSecondary',
                    'flex flex-1 w-full overflow-hidden items-center gap-x-2',
                    className
                )}
                onClick={(e) => onClick(e)}
                disabled={disabled}
            >
                {icon && <span className='flex-shrink-0 w-5 h-5'>{icon}</span>}
                <Text.SmallBody className='flex-1 overflow-hidden text-left'>
                    <TruncatedText title={title} className='min-w-0 truncate' />
                </Text.SmallBody>
            </button>
            {children}
        </div>
    );
};
