import { useEffect, useRef } from 'react';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { EditorSteps } from '../constants';
import { useTileEditorStepsContext } from '../contexts/TileEditorStepsContext';
import { DataStreamTileEditorStepSelector } from './DataStreamTileEditorStepSelector';

export const DataStreamTileEditorSteps: React.FC = () => {
    const { config } = useDatasetContext();
    const automaticallyNavigated = useRef(false);

    const { isLoading: isLoadingLimit, limit } = useCheckScopeIsOobAndHasLimit(config);
    const { setCurrentEditorStep } = useTileEditorStepsContext();

    useEffect(() => {
        if (!isLoadingLimit && limit !== false && !automaticallyNavigated.current) {
            setCurrentEditorStep(EditorSteps.objects);
            automaticallyNavigated.current = true;
        }
    }, [isLoadingLimit, limit, setCurrentEditorStep]);

    return (
        <div className='h-full min-h-0 shrink-0 bg-tileBackground'>
            <div className='flex h-full'>
                <DataStreamTileEditorStepSelector />
            </div>
        </div>
    );
};
