import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scopeLimitMaximum } from '@squaredup/constants';
import type { DataStreamGremlinScope, DataStreamScope } from '@squaredup/data-streams';
import { TruncatedText } from 'components/TruncatedText';
import { getNameForType } from 'lib/types';
import { groupBy } from 'lodash';
import { useScope } from 'queries/hooks/useScope';
import { useScopeContentToObjects } from 'queries/hooks/useScopeContentToObjects';
import { type FC } from 'react';
import type { PartialDeep } from 'type-fest';
import { useDataStreamTemplate } from '../hooks/useDataStreamTemplate';
import type { ScopeState } from '../state/scope/ScopeState';

type DataStreamObjectsSublabelProps = {
    scopeState: ScopeState;
    scopeConfig: PartialDeep<DataStreamScope>;
    workspaceId: string;
};

export const DataStreamObjectsSublabel: FC<DataStreamObjectsSublabelProps> = ({
    scopeState,
    scopeConfig,
    workspaceId
}) => {
    const { dataStream, pluginDataSource } = useDataStreamTemplate();

    const objectLimit = dataStream?.definition?.objectLimit ?? pluginDataSource?.objectLimit;

    const { data: selectedScope } = useScope(
        workspaceId,
        scopeState.type === 'predefinedScope' ? scopeState.scopeId : undefined
    );

    const { data: scopeObjectsByType } = useScopeContentToObjects(
        scopeConfig && 'query' in scopeConfig
            ? ({
                  ...scopeConfig,
                  excludeCanonicalLinkedNodes: true // Always filter out canonical as the tile editor only allows you to select source nodes
              } as DataStreamGremlinScope)
            : undefined,
        workspaceId,
        {
            select: (data) =>
                groupBy(data, (object) => {
                    const objectType = object.type?.[0] ?? object.sourceType?.[0];
                    return objectType ? getNameForType(objectType, 'singular', 'object') : 'object';
                })
        }
    );

    if (scopeState.type === 'none') {
        return <span>Select objects</span>;
    }

    if (scopeState.isVariableScope) {
        return (
            <div className='flex items-center w-full min-w-0 space-x-2'>
                <TruncatedText title='Variable' className='flex-1 min-w-0' />
                <div className='inline-flex items-center justify-center w-6 h-6'>
                    <FontAwesomeIcon icon={faBarsFilter} fixedWidth={true} />
                </div>
            </div>
        );
    }

    if (scopeState.type === 'predefinedScope' && selectedScope != null) {
        return <TruncatedText title={selectedScope.displayName}>{selectedScope.displayName}</TruncatedText>;
    }

    if (scopeObjectsByType) {
        const allObjects = Object.values(scopeObjectsByType).flat();
        const objectTypes = Object.keys(scopeObjectsByType);

        if (allObjects.length === 1) {
            const objectName = allObjects[0]?.name[0];
            return <TruncatedText title={objectName}>{objectName}</TruncatedText>;
        }

        const hasMultipleTypes = objectTypes.length > 1;
        const hasMultipleObjects = allObjects.length > 1;
        const fallback = hasMultipleObjects ? 'objects' : 'object';

        // Get the friendly, pluralised type name
        // If there are multiple types we just show object/objects
        const typeName = hasMultipleTypes
            ? fallback
            : getNameForType(objectTypes[0], hasMultipleObjects ? 'plural' : 'singular', fallback);

        const objectsSummary = `${Math.min(allObjects.length, objectLimit ?? scopeLimitMaximum)} ${typeName} selected`;

        return <TruncatedText title={objectsSummary}>{objectsSummary}</TruncatedText>;
    }

    return <span>-</span>;
};
