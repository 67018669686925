import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getTimeframeLabel, type TimeframeEnumValue } from '@squaredup/timeframes';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { getDataStreamType, type DataStreamType } from 'dashboard-engine/util/getDataStreamType';
import { useDataStreamDefinition } from 'queries/hooks/useDataStreamDefinition';
import { useContext, useEffect, useRef } from 'react';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useTileEditorContext } from '../../contexts/TileEditorContext';
import { AnalyticsQueryEditor } from '../analytics/AnalyticsQueryEditor';
import { EditorSteps } from '../constants';
import StepsContext from '../contexts/TileEditorStepsContext';
import { useObjectFilterObjects } from '../hooks/objectFilters/useObjectFilterObjects';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useScopeBaseQuery } from '../hooks/useDataStreamObjectFilters';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { useDataStreamType } from '../hooks/useDataStreamType';
import { useTileEditorSteps, type StepWithCondition } from '../hooks/useTileEditorSteps';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { DataStreamConfigurationStep } from './DataStreamConfiguration/DataStreamConfigurationStep';
import { DataStreamConfigurationStepSubLabel } from './DataStreamConfiguration/DataStreamConfigurationStepSubLabel';
import { DataStreamDisplayName } from './DataStreamDisplayName';
import { DataStreamMetadata } from './DataStreamMetadata';
import { DataStreamObjectsSublabel } from './DataStreamObjectsSublabel';
import { DataStreamShapingLabels } from './DataStreamShapingLabels';
import { DataStreamStep } from './DataStreamStep';
import { DataStreamTileEditorCurrentStep } from './DataStreamTileEditorCurrentStep';
import { DataStreamTileEditorStepSelector } from './DataStreamTileEditorStepSelector';
import { countModifiedColumns } from './MetadataEditor/MetadataEditorTileState';
import { ObjectsStep } from './ObjectsStep';
import { ShapingStep } from './ShapingStep';
import { TimeframeStep } from './TimeframeStep';

const stepComponents = {
    [EditorSteps.dataStream]: DataStreamStep,
    [EditorSteps.objects]: ObjectsStep,
    [EditorSteps.parameters]: DataStreamConfigurationStep,
    [EditorSteps.timeframe]: TimeframeStep,
    [EditorSteps.shaping]: ShapingStep,
    [EditorSteps.metadata]: DataStreamMetadata,
    [EditorSteps.query]: AnalyticsQueryEditor
} satisfies Record<EditorSteps, unknown>;

const getInitialEditorStep = (config: DataStreamBaseTileConfig, dataStreamType: DataStreamType) => {
    // If there's no datastream selected, show the datastream step
    if (!config.dataStream?.id) {
        return EditorSteps.dataStream;
    }

    // We expect a scope and there isn't one yet, show the scope step
    if (!config.scope) {
        return EditorSteps.objects;
    }
    // If we expect config and there isn't one yet, show the query step
    if (dataStreamType.isConfigurable && !config.dataStream?.dataSourceConfig) {
        return EditorSteps.parameters;
    }
    // Otherwise, the tile is configured, show data stream
    // Note: we don't show timeframe as it's there's always a default
    return EditorSteps.dataStream;
};

const getTimeframeSteplabel = (
    supportedTimeframes: boolean | TimeframeEnumValue[],
    inheritedTimeframe: TimeframeEnumValue,
    configTimeframe?: TimeframeEnumValue
) => {
    if (supportedTimeframes === false) {
        return 'Not supported';
    }

    if (configTimeframe) {
        if (configTimeframe === 'none') {
            return 'None';
        }
        return `${getTimeframeLabel(configTimeframe, 'nonrelative')} (Fixed)`;
    }

    return `${getTimeframeLabel(inheritedTimeframe, 'nonrelative')} (Dashboard)`;
};

export const DataStreamTileEditorSteps: React.FC = () => {
    const { config, setConfig } = useDatasetContext();
    const { savedTileDataStreamType } = useTileEditorContext();
    const { timeframe: inheritedTimeframe } = useContext(DashboardContext);
    const automaticallyNavigated = useRef(false);

    const { dataStreamType, isLoading } = useDataStreamType(config);

    const supportedTimeframes = useDataStreamSupportedTimeframes(config.dataStream?.id);
    const { data: dataStream } = useDataStreamDefinition(config.dataStream?.id);
    const { data, isConfigured } = useDataStreamConfig(config);
    const { dataStreamState, scope, storeCurrentStep, workspaceId, dispatch } = useTileEditorStore((s) => ({
        storeCurrentStep: s.currentStep,
        dataStreamState: s.dataStream,
        scope: s.scope,
        workspaceId: s.dashboard.workspaceId,
        dispatch: s.dispatch
    }));
    const dataStreamFilters = useDataStreamFilters({ dispatch, ...dataStreamState });

    const { scopeBaseQuery, isLoading: isLoadingScopeBaseQuery } = useScopeBaseQuery(
        dataStreamState.selectedDataStream,
        undefined
    );
    const {
        isFetchingObjects,
        isPreviousData,
        count: objectCount
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: {},
        isFilterQueryReady: !isLoadingScopeBaseQuery && dataStreamState.selectedDataStream != null && !scope.isDynamic,
        options: { keepPreviousData: false }
    });

    const hasDataStream = Boolean(config.dataStream);
    const isConfigurableDataStream = dataStreamType && dataStreamType.isConfigurable;

    const hasScope = Object.keys(config.scope || {}).length > 0;

    const editorSteps: StepWithCondition[] = [
        {
            name: EditorSteps.dataStream,
            isEnabled: !isLoading,
            sublabel: <DataStreamDisplayName />
        },
        {
            name: EditorSteps.objects,
            isEnabled:
                dataStreamType.supportsScope &&
                (scope.isDynamic ||
                    (!isLoading &&
                        !isPreviousData &&
                        hasDataStream &&
                        objectCount != null &&
                        // We might be about to auto-select if we're still loading objects
                        !(isFetchingObjects && scope.type === 'none') &&
                        !(
                            !isFetchingObjects &&
                            scope.type === 'objectList' &&
                            objectCount === 1 &&
                            scope.selectedNodeIds.length === 1
                        ))),
            sublabel: !hasDataStream ? (
                '-'
            ) : isFetchingObjects ? (
                <LoadingSpinner size={12} />
            ) : (
                <DataStreamObjectsSublabel
                    scopeState={scope}
                    scopeConfig={config.scope ?? []}
                    workspaceId={workspaceId}
                />
            )
        },
        {
            name: EditorSteps.parameters,
            isEnabled:
                !isLoading && hasDataStream && isConfigurableDataStream && (hasScope || !dataStreamType.supportsScope),
            sublabel: isConfigurableDataStream ? <DataStreamConfigurationStepSubLabel /> : '-'
        },
        {
            name: EditorSteps.timeframe,
            isEnabled: !isLoading && hasDataStream && supportedTimeframes !== false && isConfigured,
            sublabel: getTimeframeSteplabel(supportedTimeframes, inheritedTimeframe, config.timeframe)
        },
        {
            name: EditorSteps.shaping,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: <DataStreamShapingLabels />
        },
        {
            name: EditorSteps.metadata,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: (() => {
                const numberOfModifiedColumns = countModifiedColumns(
                    config.dataStream?.metadata,
                    data.metadata.columns.map((c) => c.name)
                );

                return numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';
            })()
        }
    ];

    const stepsValue = useTileEditorSteps(
        editorSteps,
        savedTileDataStreamType ? getInitialEditorStep(config, getDataStreamType(dataStream)) : EditorSteps.dataStream,
        undefined,
        undefined,
        (newStep: EditorSteps | undefined) => {
            if (newStep !== storeCurrentStep) {
                dispatch({ type: 'setActiveStep', step: newStep as EditorSteps });
            }
        }
    );

    const { isLoading: isLoadingLimit, limit } = useCheckScopeIsOobAndHasLimit(config);

    useEffect(() => {
        if (!isLoadingLimit && limit !== false && !automaticallyNavigated.current) {
            stepsValue.setCurrentEditorStep(EditorSteps.objects);
            automaticallyNavigated.current = true;
        }
    }, [isLoadingLimit, limit, stepsValue.setCurrentEditorStep]);

    return (
        <StepsContext.Provider value={stepsValue}>
            <div className='flex-1 w-full h-full min-h-0 bg-tileBackground'>
                <div className='flex h-full'>
                    <DataStreamTileEditorStepSelector />
                    <DataStreamTileEditorCurrentStep
                        stepComponents={stepComponents}
                        stepProps={{ config, setConfig, dataStreamFilters }}
                    />
                </div>
            </div>
        </StepsContext.Provider>
    );
};
