import { getTimeframeLabel, type TimeframeEnumValue } from '@squaredup/timeframes';
import LoadingSpinner from 'components/LoadingSpinner';
import DashboardContext from 'contexts/DashboardContext';
import { useDataStreamConfig } from 'dashboard-engine/hooks/useDataStreamConfig';
import { useContext } from 'react';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { EditorSteps } from '../constants';
import TileEditorStepsContext from '../contexts/TileEditorStepsContext';
import { useObjectFilterObjects } from '../hooks/objectFilters/useObjectFilterObjects';
import { useDataStreamFilters } from '../hooks/useDataStreamFilters';
import { useScopeBaseQuery } from '../hooks/useDataStreamObjectFilters';
import { useDataStreamSupportedTimeframes } from '../hooks/useDataStreamSupportedTimeframes';
import { useDataStreamType } from '../hooks/useDataStreamType';
import { StepWithCondition, useTileEditorSteps } from '../hooks/useTileEditorSteps';
import { useTileEditorStore } from '../state/TileEditorStoreProvider';
import { DataStreamConfigurationStep } from '../steps/DataStreamConfiguration/DataStreamConfigurationStep';
import { DataStreamConfigurationStepSubLabel } from '../steps/DataStreamConfiguration/DataStreamConfigurationStepSubLabel';
import { DataStreamDisplayName } from '../steps/DataStreamDisplayName';
import { DataStreamMetadata } from '../steps/DataStreamMetadata';
import { DataStreamObjectsSublabel } from '../steps/DataStreamObjectsSublabel';
import { DataStreamShapingLabels } from '../steps/DataStreamShapingLabels';
import { DataStreamStep } from '../steps/DataStreamStep';
import { countModifiedColumns } from '../steps/MetadataEditor/MetadataEditorTileState';
import { ObjectsStep } from '../steps/ObjectsStep';
import { ShapingStep } from '../steps/ShapingStep';
import { TimeframeStep } from '../steps/TimeframeStep';

const getTimeframeSteplabel = (
    supportedTimeframes: boolean | TimeframeEnumValue[],
    inheritedTimeframe: TimeframeEnumValue,
    configTimeframe?: TimeframeEnumValue
) => {
    if (supportedTimeframes === false) {
        return 'Not supported';
    }

    if (configTimeframe) {
        if (configTimeframe === 'none') {
            return 'None';
        }
        return `${getTimeframeLabel(configTimeframe, 'nonrelative')} (Fixed)`;
    }

    return `${getTimeframeLabel(inheritedTimeframe, 'nonrelative')} (Dashboard)`;
};

interface TileEditorStepsContextWrapperProps {
    onDockEditorStep?: () => void;
    onReleaseEditorStep?: () => void;
}
export const TileEditorStepsContextWrapper: React.FC<TileEditorStepsContextWrapperProps> = ({ 
    onDockEditorStep,
    onReleaseEditorStep, 
    children 
}) => {
    const { config, setConfig } = useDatasetContext();
    const { timeframe: inheritedTimeframe } = useContext(DashboardContext);

    const { dataStreamType, isLoading } = useDataStreamType(config);

    const supportedTimeframes = useDataStreamSupportedTimeframes(config.dataStream?.id);
    const { data, isConfigured } = useDataStreamConfig(config);
    const { dataStreamState, scope, storeCurrentStep, workspaceId, dispatch } = useTileEditorStore((s) => ({
        storeCurrentStep: s.currentStep,
        dataStreamState: s.dataStream,
        scope: s.scope,
        workspaceId: s.dashboard.workspaceId,
        dispatch: s.dispatch
    }));
    const dataStreamFilters = useDataStreamFilters({ dispatch, ...dataStreamState });

    const { scopeBaseQuery, isLoading: isLoadingScopeBaseQuery } = useScopeBaseQuery(
        dataStreamState.selectedDataStream,
        undefined
    );
    const {
        isFetchingObjects,
        isPreviousData,
        count: objectCount
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: {},
        isFilterQueryReady: !isLoadingScopeBaseQuery && dataStreamState.selectedDataStream != null && !scope.isDynamic,
        options: { keepPreviousData: false }
    });

    const hasDataStream = Boolean(config.dataStream);
    const isConfigurableDataStream = dataStreamType && dataStreamType.isConfigurable;

    const hasScope = Object.keys(config.scope || {}).length > 0;

    const editorSteps: StepWithCondition[] = [
        {
            name: EditorSteps.dataStream,
            isEnabled: !isLoading,
            sublabel: <DataStreamDisplayName />,
            component: <DataStreamStep dataStreamFilters={dataStreamFilters} />
        },
        {
            name: EditorSteps.objects,
            isEnabled:
                dataStreamType.supportsScope &&
                (scope.isDynamic || storeCurrentStep === EditorSteps.objects ||
                    (!isLoading &&
                        !isPreviousData &&
                        hasDataStream &&
                        objectCount != null &&
                        // We might be about to auto-select if we're still loading objects
                        !(isFetchingObjects && scope.type === 'none') &&
                        !(
                            !isFetchingObjects &&
                            scope.type === 'objectList' &&
                            objectCount === 1 &&
                            scope.selectedNodeIds.length === 1
                        ))),
            sublabel: !hasDataStream ? (
                '-'
            ) : isFetchingObjects && storeCurrentStep !== EditorSteps.objects ? (
                <LoadingSpinner size={12} />
            ) : (
                <DataStreamObjectsSublabel
                    scopeState={scope}
                    scopeConfig={config.scope ?? []}
                    workspaceId={workspaceId}
                />
            ),
            component: <ObjectsStep />
        },
        {
            name: EditorSteps.parameters,
            isEnabled: 
                !isLoading && hasDataStream && isConfigurableDataStream && (hasScope || !dataStreamType.supportsScope),
            sublabel: isConfigurableDataStream ? <DataStreamConfigurationStepSubLabel /> : '-',
            component: <DataStreamConfigurationStep />
        },
        {
            name: EditorSteps.timeframe,
            isEnabled: !isLoading && hasDataStream && supportedTimeframes !== false && isConfigured,
            sublabel: getTimeframeSteplabel(supportedTimeframes, inheritedTimeframe, config.timeframe),
            component: <TimeframeStep />
        },
        {
            name: EditorSteps.shaping,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: <DataStreamShapingLabels />,
            component: <ShapingStep />
        },
        {
            name: EditorSteps.metadata,
            isEnabled: !isLoading && hasDataStream && isConfigured,
            sublabel: (() => {
                const numberOfModifiedColumns = countModifiedColumns(
                    config.dataStream?.metadata,
                    data.metadata.columns.map((c) => c.name)
                );

                return numberOfModifiedColumns > 0 ? `${numberOfModifiedColumns} configured` : '';
            })(),
            component: <DataStreamMetadata 
                config={config} 
                setConfig={setConfig} 
            />
        }
    ];

    const stepsValue = useTileEditorSteps(
        editorSteps,
        undefined,
        onDockEditorStep,
        onReleaseEditorStep,
        (newStep) => {
            if (newStep !== storeCurrentStep) {
                dispatch({ type: 'setActiveStep', step: newStep as EditorSteps });
            }
        }
    );

    return (
        <TileEditorStepsContext.Provider value={stepsValue}>
            {children}
        </TileEditorStepsContext.Provider>
    );
};
