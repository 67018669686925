import {
    boolean,
    date,
    FormattedStreamValue,
    guid,
    isNone,
    json,
    noneString,
    RowData,
    ShapeName,
    state,
    StreamDataColumn,
    url
} from '@squaredup/data-streams';
import { CellContext } from '@tanstack/react-table';
import Tooltip from 'components/tooltip/Tooltip';
import { getDrilldownUrlFor, getValueIfAllSame } from 'dashboard-engine/util/drilldown';
import { FC } from 'react';
import { BooleanCell } from './cells/BooleanCell';
import { CellProps, CellStyle } from './cells/CellProps';
import { DateCell } from './cells/DateCell';
import { DrilldownCell } from './cells/DrilldownCell';
import { GUIDCell } from './cells/GUIDCell';
import { JSONCell } from './cells/JSONCell';
import { RawCell } from './cells/RawCell';
import { StateCell } from './cells/StateCell';
import { URLCell } from './cells/URLCell';
import { ValueCell } from './cells/ValueCell';

const CELL_RENDERERS: Record<ShapeName, FC<CellProps>> = {
    [url.name]: URLCell,
    [date.name]: DateCell,
    [guid.name]: GUIDCell,
    [json.name]: JSONCell,
    [state.name]: StateCell,
    [boolean.name]: BooleanCell,
    // eslint-disable-next-line camelcase
    shape_raw: RawCell
};

const defaultCellStyle: CellStyle = { align: 'center' };

/**
 * Render a stream data value as a particular shape as if it was appearing in
 * the table visualisation
 */
export const renderCellValue = (columnShape: ShapeName, value: FormattedStreamValue, style: CellStyle) => {
    if (isNone(value.value) || value?.value === '') {
        return (
            <Tooltip title='No value'>
                <span className='opacity-30'>{noneString}</span>
            </Tooltip>
        );
    }

    const Component = CELL_RENDERERS[columnShape] ?? ValueCell;

    return <Component {...value} style={style} />;
};

export const renderCell =
    (column: StreamDataColumn, style?: CellStyle, rowData?: RowData[]) =>
    ({ cell }: CellContext<FormattedStreamValue[], any>) => {
        const value = cell.getValue();

        if (column.sourceIdColumn && rowData?.[cell.row.index].pluginConfigIds) {
            const pluginConfigId = getValueIfAllSame(rowData[cell.row.index].pluginConfigIds);
            const sourceId = (cell.row.getValue(column.sourceIdColumn) as FormattedStreamValue)?.raw as string;

            if (pluginConfigId && sourceId) {
                return (
                    <DrilldownCell
                        {...value}
                        style={style ?? defaultCellStyle}
                        href={getDrilldownUrlFor(sourceId, pluginConfigId)}
                    />
                );
            }
        }

        return renderCellValue(column.shapeName, value, style ?? defaultCellStyle);
    };
