import type { DashboardVariable } from '@squaredup/dashboards';
import type { DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
    type Dispatch,
    type FC,
    type SetStateAction
} from 'react';
import { useQueryClient } from 'react-query';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useDatasetContext } from '../../contexts/DatasetContext';
import { EditorSteps } from '../constants';
import { useObjectFilterObjects } from '../hooks/objectFilters/useObjectFilterObjects';
import { useScopeBaseQuery } from '../hooks/useDataStreamObjectFilters';
import {
    createTileEditorState,
    type TileEditorDataStream,
    type TileEditorState,
    type TileEditorStateReducerExternals
} from './TileEditorState';
import { tileEditorStateReducer, type TileEditorStateWithDispatch } from './TileEditorStateReducer';
import { useTileEditorDataStreams } from './useTileEditorDataStreams';

type TileEditorStore = UseBoundStore<StoreApi<TileEditorStateWithDispatch>>;

type TileEditorStoreContextValue = {
    useStore: TileEditorStore;
};

const TileEditorStoreContext = createContext<TileEditorStoreContextValue>({
    get useStore(): TileEditorStore {
        throw new Error('useStore must be used inside a TileEditorStoreProvider');
    }
});

export const createTileEditorStore = ({
    initialState,
    ...externals
}: Omit<TileEditorStateReducerExternals, 'setConfig'> & { initialState: TileEditorState }) =>
    create<TileEditorStateWithDispatch>()(
        devtools(
            (set) => {
                return {
                    ...initialState,
                    dispatch: (action) => {
                        set(
                            (state) => {
                                const newState = tileEditorStateReducer(state, action);

                                /**
                                 * Capture any setConfig calls and apply them to the tile config
                                 * in the store without actually calling setConfig.
                                 *
                                 * This is necessary because we can't call setConfig directly in the reducer,
                                 * instead it gets handled in a useEffect in the provider.
                                 *
                                 * By pre-calculating the new tile config here, we keep the side-effects
                                 * internal to the store, and avoid multiple setConfig calls.
                                 */
                                let newTileConfig =
                                    action.type === 'resetTileEditorState'
                                        ? action.newState.asTileConfig
                                        : state.asTileConfig;

                                newState.sideEffects?.({
                                    ...externals,
                                    setConfig: (c) => {
                                        if (typeof c === 'function') {
                                            newTileConfig = c(newTileConfig);
                                        } else {
                                            newTileConfig = c;
                                        }
                                    }
                                });

                                return {
                                    ...newState,
                                    asTileConfig: newTileConfig,
                                    hasPendingConfigUpdate:
                                        action.type !== 'resetTileEditorState' && newTileConfig !== state.asTileConfig,
                                    sideEffects: undefined
                                };
                            },
                            undefined,
                            action
                        );
                    }
                };
            },
            {
                name: 'Tile Editor Store',
                serialize: true
            }
        )
    );

/**
 * Provide tile editor store to all children.
 */
export const TileEditorStoreProvider: FC<{
    config: DataStreamBaseTileConfig;
    setConfig: Dispatch<SetStateAction<DataStreamBaseTileConfig>>;
    dataStreams: TileEditorDataStream[];
    workspaceId: string;
    variables: DashboardVariable[];
    datasetId: string;
    isEnabled: boolean;
}> = ({ children, config, setConfig, dataStreams, workspaceId, variables, datasetId, isEnabled }) => {
    const queryClient = useQueryClient();

    // Capture the initial dependencies
    const [initialDependencies, setInitialDependencies] = useState({
        dataStreams,
        workspaceId,
        variables
    });

    const useStore: TileEditorStore = useMemo(
        () =>
            createTileEditorStore({
                queryClient,
                initialState: createTileEditorState({
                    dataStreams,
                    config,
                    workspaceId,
                    variables
                })
            }),
        /**
         * We don't recreate the store when dataStreams, config, workspaceId, or variables
         * change because we reset the state of the store below instead. This helps maintain
         * a single instance of the store with one history of actions to aid debugging.
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [datasetId]
    );

    const { asTileConfig, hasPendingConfigUpdate, state, selectedDataStream, dispatch } = useStore((s) => ({
        state: s,
        selectedDataStream: s.dataStream.selectedDataStream,
        asTileConfig: s.asTileConfig,
        hasPendingConfigUpdate: s.hasPendingConfigUpdate ?? false,
        dispatch: s.dispatch
    }));

    const asTileConfigString = stringify(asTileConfig);
    const configString = stringify(config);

    /**
     * Reset the store when the config changes, unless there's a pending config update,
     * in which case the store version of the tile config is applied via setConfig.
     */
    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        const configHasChanged = asTileConfigString !== configString;
        const updateTileConfigFromStore = configHasChanged && hasPendingConfigUpdate;

        if (updateTileConfigFromStore) {
            setConfig(asTileConfig);
            dispatch({ type: 'applyConfigUpdate' });
        }

        /**
         * We have to compare the dependencies ourselves, otherwise
         * we wouldn't be able to tell if we're in the useEffect because the dependencies
         * changed or not.
         */
        const dependenciesHaveChanged =
            initialDependencies.dataStreams !== dataStreams ||
            initialDependencies.workspaceId !== workspaceId ||
            (initialDependencies.variables !== variables &&
                !(initialDependencies.variables.length === 0 && variables.length === 0));

        if (dependenciesHaveChanged || (configHasChanged && !updateTileConfigFromStore)) {
            dispatch({
                type: 'resetTileEditorState',
                newState: createTileEditorState({ dataStreams, config, workspaceId, variables, previousState: state })
            });
        }

        if (dependenciesHaveChanged) {
            setInitialDependencies({ dataStreams, workspaceId, variables });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configString, asTileConfigString, hasPendingConfigUpdate, dataStreams, workspaceId, variables]);

    /**
     * Auto-select the only object if there is only one object matching the selected data stream.
     */
    const canAutoSelectObject =
        isEnabled &&
        state.currentStep === EditorSteps.dataStream &&
        /**
         * Don't auto-select if the user has already visited the objects step,
         * we want the auto-select to happen only after they select a data stream,
         * which resets the visited steps.
         */
        !state.visitedSteps.includes(EditorSteps.objects) &&
        /**
         * Never change an existing scope.
         */
        state.scope.type === 'none';

    /**
     * We want to know if there is exactly 1 object matching the selected data stream,
     * so we need to load the objects without any filters applied.
     * Some filters are carried over to the Objects step from the data stream step,
     * so it's possible for some to be set without the user having visited the Objects step.
     */
    const { scopeBaseQuery, isLoading: isLoadingScopeBaseQuery } = useScopeBaseQuery(selectedDataStream, undefined);
    const {
        isFetchingObjects,
        isPreviousData,
        count: objectCount,
        objects
    } = useObjectFilterObjects({
        scopeBaseQuery,
        queryParams: {},
        isFilterQueryReady: !isLoadingScopeBaseQuery && selectedDataStream != null && canAutoSelectObject,
        options: { keepPreviousData: false }
    });

    const shouldAutoSelectObject =
        canAutoSelectObject && !isFetchingObjects && objects != null && !isPreviousData && objectCount === 1;

    useEffect(() => {
        if (shouldAutoSelectObject) {
            dispatch({ type: 'setObjectListScope', selectedNodeIds: [objects[0].id] });
        }
    }, [dispatch, shouldAutoSelectObject, objects]);

    return (
        <TileEditorStoreContext.Provider
            value={{
                useStore: isEnabled
                    ? useStore
                    : ((() => {
                          throw new Error('TileEditorStore is disabled');
                      }) as unknown as TileEditorStore)
            }}
        >
            {children}
        </TileEditorStoreContext.Provider>
    );
};

/**
 * Provide tile editor store to all children using config from `DatasetContext`.
 */
export const TileEditorStoreProviderFromDataSetContext: FC = ({ children }) => {
    const { config, setConfig, activeDataset, datasets } = useDatasetContext();
    const { variables = [] } = useDashboardContext();

    const { workspace } = useDataStreamWorkspaceContext();
    const { dataStreams } = useTileEditorDataStreams();

    const isDatasetTab = activeDataset < datasets.length || datasets.length === 0;

    return (
        <TileEditorStoreProvider
            config={config}
            setConfig={setConfig}
            dataStreams={dataStreams}
            workspaceId={workspace}
            variables={variables}
            datasetId={!isDatasetTab ? 'dataset-nodatasets' : `dataset-${activeDataset}`}
            isEnabled={isDatasetTab}
        >
            {children}
        </TileEditorStoreProvider>
    );
};

export const useTileEditorStore = <T,>(selector: (state: TileEditorStateWithDispatch) => T): T => {
    return useContext(TileEditorStoreContext).useStore(selector);
};
