import { Button } from '@/components/Button';
import { InfoTip } from '@/components/InfoTip';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { CreateEditScope } from 'pages/scope/CreateEditScope';
import pluralize from 'pluralize';
import { useState } from 'react';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';
import { useCheckScopeIsOobAndHasLimit } from 'ui/tile/hooks/useCheckScopeIsOobAndHasLimit';
import { useTileEditorObjectsFilterContext } from '../../contexts/TileEditorObjectsFilterContext';

export const OobScopeLimitMessage = () => {
    const { config } = useDatasetContext();
    const [scopeModalOpen, setScopeModalOpen] = useState(false);

    const { updateScope } = useTileEditorObjectsFilterContext();

    const {
        isLoading: isLoadingLimit,
        limit,
        isSavedScope,
        scopeDisplayName,
        scope,
        objectType
    } = useCheckScopeIsOobAndHasLimit(config);

    return (
        <>
            {!isLoadingLimit && limit != null && limit !== false && isSavedScope && (
                <InfoTip icon={faInfoCircle} iconClassName='text-textLink'>
                    For demo purposes, {limit > 1 ? 'up to ' : ''}
                    {pluralize(objectType ?? 'object', limit, true)} {limit === 1 ? 'has' : 'have'} been automatically
                    selected.{' '}
                    <Button variant='link' onClick={() => setScopeModalOpen(true)}>
                        Edit the '{scopeDisplayName}' scope
                    </Button>{' '}
                    to select your own.
                </InfoTip>
            )}

            {scopeModalOpen && (
                <CreateEditScope
                    scope={scope}
                    onClose={(modifiedScope) => {
                        setScopeModalOpen(false);
                        if (modifiedScope) {
                            updateScope({
                                filters: { scopeId: modifiedScope.id, types: [], plugins: [] },
                                isDynamic: true
                            });
                        }
                    }}
                />
            )}
        </>
    );
};
