import type { DashboardVariable } from '@squaredup/dashboards';
import { type DataStreamBaseTileConfig } from '@squaredup/data-streams';
import { getInitialObjectsSelection } from '../../utilities/getInitialObjectsSelection';
import {
    createObjectFilterState,
    emptyFilters,
    type FallbackDefaults,
    type ObjectFilters,
    type ObjectFilterState
} from './ObjectFilterState';

/**
 * No scope, but we may have some filters saved.
 * Either no objects were returned, or the user hasn't selected any yet.
 */
export type NoneScopeState = {
    type: 'none';
    isDynamic: false;
    canFilter: true;
    isVariableScope: false;
    filters: ObjectFilterState;
    interactedNodeIds: string[];
};

/**
 * We're just using a pre-defined scope / object collection.
 * We're always dynamic, and the user can't apply any filters.
 */
export type PredefinedScopeState = {
    type: 'predefinedScope';
    isDynamic: true;
    canFilter: false;
    scopeId: string;
    workspaceId: string;
    /**
     * Search strings for predefined scopes don't affect the scope itself, but they do affect the UI
     */
    searchString: string;
    interactedNodeIds: string[];
} & (
    | {
          /**
           * The pre-defined scope we're using is a scope from a dashboard variable.
           */
          isVariableScope: true;
          variableSelectedNodeIds: string[];
          variableIsSelectedAll: boolean;
      }
    | {
          isVariableScope: false;
      }
);

/**
 * A fixed list of node Ids the user has selected from a list
 * that they may have filtered.
 */
export type ObjectListScopeState = {
    type: 'objectList';
    isDynamic: false;
    canFilter: true;
    isVariableScope: false;
    filters: ObjectFilterState;
    selectedNodeIds: string[];
    interactedNodeIds: string[];
};

/**
 * As `objectList`, but dynamic, so there's no list of node Ids.
 */
export type DynamicScopeState = {
    type: 'dynamic';
    isDynamic: true;
    canFilter: true;
    isVariableScope: false;
    filters: ObjectFilterState;
    interactedNodeIds: string[];
};

export type ScopeState = NoneScopeState | PredefinedScopeState | ObjectListScopeState | DynamicScopeState;

export const emptyScopeState: ScopeState & { type: 'none' } = {
    type: 'none',
    isDynamic: false,
    canFilter: true,
    isVariableScope: false,
    filters: emptyFilters,
    interactedNodeIds: []
};

export const createScopeState = ({
    scopeConfig,
    currentWorkspaceId,
    variables,
    configVariables,
    defaultFilters,
    fallbackDefaults,
    previousState
}: {
    scopeConfig: DataStreamBaseTileConfig['scope'];
    variables: DashboardVariable[];
    configVariables: string[];
    currentWorkspaceId: string;
    defaultFilters?: ObjectFilters;
    fallbackDefaults?: FallbackDefaults;
    previousState?: ScopeState;
}): ScopeState => {
    const filterState =
        previousState == null || !previousState.canFilter
            ? createObjectFilterState({
                  scopeConfig,
                  workspaceId: currentWorkspaceId,
                  defaults: defaultFilters,
                  fallbackDefaults
              })
            : previousState.filters;

    const selectedNodeIds =
        scopeConfig == null ? defaultFilters?.selectedObjects ?? [] : getInitialObjectsSelection(scopeConfig);
    const isDynamic = scopeConfig != null && (selectedNodeIds == null || selectedNodeIds.length === 0);

    if (isDynamic && (filterState.hasNonScopeFilter || !filterState.hasScopeFilter)) {
        return {
            type: 'dynamic',
            isDynamic: true,
            canFilter: true,
            isVariableScope: false,
            filters: filterState,
            interactedNodeIds: previousState?.interactedNodeIds ?? []
        };
    }

    const workspaceId = Array.isArray(scopeConfig) ? undefined : scopeConfig?.workspace ?? currentWorkspaceId;

    if (filterState.hasScopeFilter && !filterState.hasNonScopeFilter && workspaceId) {
        const isVariableScope = variables.some(
            (v) => v.scopeId === filterState.scopeId && configVariables.includes(v.id)
        );

        const variableProperties = isVariableScope
            ? {
                  isVariableScope,
                  variableSelectedNodeIds: variables.flatMap((v) => v.selectedObjects).map((v) => v.id),
                  variableIsSelectedAll: variables.every((v) => v.selectedAll)
              }
            : { isVariableScope };

        return {
            type: 'predefinedScope',
            isDynamic: true,
            canFilter: false,
            scopeId: filterState.scopeId,
            workspaceId,
            interactedNodeIds: previousState?.interactedNodeIds ?? [],
            searchString: filterState.searchString,
            ...variableProperties
        };
    }

    if (selectedNodeIds == null || selectedNodeIds.length === 0) {
        return {
            ...emptyScopeState,
            filters: filterState,
            interactedNodeIds: previousState?.interactedNodeIds ?? []
        };
    }

    return {
        type: 'objectList',
        isDynamic: false,
        canFilter: true,
        isVariableScope: false,
        filters: filterState,
        selectedNodeIds,
        interactedNodeIds: previousState?.interactedNodeIds ?? selectedNodeIds
    };
};
