import { cn } from '@/lib/cn';
import { faFireFlameCurved, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncatedText } from 'components/TruncatedText';
import Tooltip from 'components/tooltip/Tooltip';
import { useFlag } from 'lib/useFlag';
import PluginIcon from 'pages/scope/PluginIcon';
import { DataStreamListItem } from '../components/DataStreamListItem';
import { DataStreamFilterOption } from '../hooks/useDataStreamFilters';
import { DataStreamOptionTags } from './DataStreamOptionTags';
import { DataStreamMatchTypeDisplay } from './DataStreamTypeDisplay';

interface DataStreamOptionProps {
    dataStream: DataStreamFilterOption;
    recent?: boolean;
    isSelected?: boolean;
    onClick?: () => void;
    selectedTags: string[];
    toggleTag: (tag: string) => void;
}

const recentFeaturedIcon = (recent: boolean, featured: boolean) => {
    if (recent) {
        return () => (
            <Tooltip title='Recently used'>
                <FontAwesomeIcon icon={faHistory} aria-label='Recent icon' />
            </Tooltip>
        );
    }

    if (featured) {
        return () => (
            <Tooltip title='Featured'>
                <FontAwesomeIcon icon={faFireFlameCurved} aria-label='Featured icon' />
            </Tooltip>
        );
    }

    return () => <></>;
};

export const DataStreamOption: React.FC<DataStreamOptionProps> = ({
    dataStream,
    recent = false,
    isSelected = false,
    onClick,
    selectedTags,
    toggleTag
}) => {
    const featured = dataStream.definition.featured ?? false;
    const dataStreamTagsFlagEnabled = useFlag('dataStreamTags');

    const Icon = recentFeaturedIcon(recent, featured);
    const displayName =
        dataStream.presetParentDataStream == null ? (
            dataStream.displayName
        ) : (
            <>
                <span
                    className={cn('text-tileEditorPresetCategoryText', {
                        'text-tileEditorPresetCategoryTextActive': isSelected
                    })}
                >
                    {dataStream.presetParentDataStream.displayName} /{' '}
                </span>
                {dataStream.displayName}
            </>
        );

    return (
        <DataStreamListItem
            key={dataStream.id}
            className={cn(
                'grid grid-cols-[1rem,minmax(7rem,_1fr),minmax(4rem,_1fr),minmax(8rem,_2fr),1rem] min-w-fit w-full gap-6',
                {
                    'grid-cols-[1rem,minmax(7rem,_1fr),minmax(4rem,_1fr),minmax(8rem,_2fr),1rem]':
                        dataStreamTagsFlagEnabled,
                    'grid-cols-[1rem,minmax(7rem,_1fr),minmax(7rem,_2fr),minmax(5rem,_1.2fr),1rem]':
                        !dataStreamTagsFlagEnabled
                }
            )}
            isSelected={isSelected}
            onClick={onClick}
            data-testid='dataStreamOption'
            aria-selected={isSelected}
        >
            <span className='flex items-center space-x-3'>
                <span className='h-[15px] w-[15px] flex-shrink-0'>
                    <Tooltip title={dataStream.pluginName}>
                        <PluginIcon pluginName={dataStream.pluginName} />
                    </Tooltip>
                </span>
            </span>

            <div className='flex items-center'>
                <TruncatedText
                    title={dataStream.displayName}
                    className={cn('flex-1 min-w-0 text-textSecondary font-semibold', {
                        'text-textPrimary': isSelected
                    })}
                >
                    {displayName}
                </TruncatedText>
            </div>

            <div className='flex items-center'>
                <TruncatedText title={dataStream.description ?? ''} className='flex-1 min-w-0'>
                    {dataStream.description}
                </TruncatedText>
            </div>

            <div className='flex items-center'>
                {dataStreamTagsFlagEnabled ? (
                    <DataStreamOptionTags
                        tags={dataStream.definition.tags ?? []}
                        isDataStreamSelected={isSelected}
                        selectedTags={selectedTags}
                        toggleTag={toggleTag}
                    />
                ) : (
                    <DataStreamMatchTypeDisplay matchTypes={dataStream.definition.matchesTypes} />
                )}
            </div>

            <span className='flex items-center justify-center'>
                <Icon />
            </span>
        </DataStreamListItem>
    );
};
