import { create } from 'zustand';
import type { ScopeAction } from './ScopeActions';
import type { ScopeState } from './ScopeState';
import { scopeStateReducer, type ScopeStateReducerExternals } from './ScopeStateReducer';

export type ScopeStateWithDispatch = ScopeState & {
    dispatch: (action: ScopeAction) => void;
};

export const createScopeStore = ({
    initialState,
    externals
}: {
    initialState: ScopeState;
    externals: ScopeStateReducerExternals;
}) =>
    create<ScopeStateWithDispatch>()((set) => ({
        ...initialState,
        dispatch: (action) => {
            set((state) => {
                const newState = scopeStateReducer(state, action);

                /**
                 * Execute side-effects as part of the dispatch. This means that the side-effects
                 * happen before components are re-rendered with the new state, which may not be
                 * ideal, but it avoids the need for a separate useEffect to handle side-effects.
                 *
                 * Having a separate useEffect means the components are rendered with the new state
                 * before the side-effects have been applied, and are then immediately re-rendered
                 * with the side-effects applied. It also means we don't have to dispatch an action
                 * to mark the side-effects as executed which is another render saved, and means we
                 * don't have to expose that action to the outside world.
                 */
                if (newState.sideEffects != null) {
                    newState.sideEffects(externals);
                }

                return { ...newState, sideEffects: undefined };
            });
        }
    }));
