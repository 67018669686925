import { PropertyFilter } from './PropertyFilter';

interface PropertyFiltersProps {
    propertyFilters: Record<string, string[]>;
    propertyValues: Map<string, string[]> | undefined;
    disabled?: boolean;
    setPropertyFilter: (property: string, value: string) => void;
    clearPropertyFilter: (property: string) => void;
}

export const PropertyFilters: React.FC<PropertyFiltersProps> = ({
    propertyFilters,
    propertyValues,
    disabled,
    setPropertyFilter,
    clearPropertyFilter
}) => {
    return (
        <>
            {Object.entries(propertyFilters).map(([property, selectedValues]) => (
                <PropertyFilter
                    key={property}
                    property={property}
                    selectedValues={selectedValues}
                    isLoadingValues={propertyValues == null || !propertyValues.has(property)}
                    values={propertyValues?.get(property) || []}
                    setPropertyFilter={setPropertyFilter}
                    clearPropertyFilter={clearPropertyFilter}
                    disabled={disabled}
                />
            ))}
        </>
    );
};
