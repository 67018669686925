import { StreamDataColumn } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import stringify from 'fast-json-stable-stringify';
import { useFormContext } from 'react-hook-form';
import EditorSelect from 'ui/editor/components/EditorSelect';
import { ColumnOption } from 'ui/editor/dataStream/utilities/filters';
import { directionOptions } from 'ui/editor/dataStream/utilities/sorting';
import { DeleteRowButton } from './DeleteRowButton';
import { useDOMElement } from 'components/hooks/useDOMElement';

interface SortRowProps {
    columns: StreamDataColumn[];
    columnOptions: ColumnOption[];
    fieldIndex: number;
    deleteSort: () => void;
}

export const SortRow: React.FC<SortRowProps> = ({ columnOptions, fieldIndex, deleteSort }) => {
    const { watch } = useFormContext();
    const menuPortalTarget = useDOMElement('dataStreamEditorSteps', null);

    const by = watch(`by.${fieldIndex}`);

    const { column } = by;

    const selectedColumnIsValid = columnOptions.some((o) => o.value === column);

    return (
        <>
            <div className='col-start-1'>
                <EditorSelect
                    key={stringify(columnOptions)}
                    name={`by[${fieldIndex}].column`}
                    placeholder='Select column'
                    options={columnOptions}
                    menuPortalTarget={menuPortalTarget}
                />
            </div>
            <div>
                <EditorSelect
                    key={stringify(directionOptions)}
                    name={`by[${fieldIndex}].direction`}
                    placeholder='Ascending'
                    options={directionOptions}
                    isClearable={false}
                    isDisabled={!selectedColumnIsValid}
                    menuPortalTarget={menuPortalTarget}
                />
            </div>

            <Tooltip title='Remove this sorting' className='flex col-start-3 place-items-center'>
                <DeleteRowButton
                    aria-label='Remove this sorting'
                    className='block w-full h-full'
                    onClick={deleteSort}
                />
            </Tooltip>
        </>
    );
};
