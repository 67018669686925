import { buildQuery } from '@squaredup/graph';
import { useDataStreamWorkspaceContext } from 'contexts/DataStreamWorkspaceContext';
import stringify from 'fast-json-stable-stringify';
import { groupBy, orderBy, uniq } from 'lodash';
import { useQuery } from 'react-query';
import { Query } from 'services/GraphService';
import { FilterQueryParameters } from '../../utilities/getObjectFilters';

/**
 * Gets all values for a given list of properties based on filtered sources, types, and properties
 */
export const useObjectFilterPropertyValues = ({
    scopeBaseQuery,
    queryParams,
    filterProperties
}: {
    scopeBaseQuery: string;
    queryParams: FilterQueryParameters;
    filterProperties: Record<string, any[]>;
}) => {
    const { isGlobal } = useDataStreamWorkspaceContext();
    const filterPropertiesList = Object.keys(filterProperties);

    return useQuery(
        ['properties', scopeBaseQuery, stringify(queryParams), stringify(filterPropertiesList), stringify(isGlobal)],
        async () => {
            const propertiesQuery =
                scopeBaseQuery +
                `.properties()
                .where(has('id', within(${filterPropertiesList.map((t) => `'${t}'`).join(',')})))`;

            const { gremlinQueryResults } = await Query(
                buildQuery(queryParams, propertiesQuery),
                isGlobal ? 'directOrAnyWorkspaceLinks' : undefined
            );

            const groupedProperties = groupBy(gremlinQueryResults, 'label');

            return new Map([
                ...filterPropertiesList.map((p) => [p, []] as [string, string[]]),
                ...Object.entries(groupedProperties)
                    .sort(([firstKey], [secondKey]) => (firstKey.toLowerCase() < secondKey.toLowerCase() ? -1 : 1))
                    .map(
                        ([property, values]) =>
                            [property, orderBy(uniq(values.map(({ value }) => value)))] as [string, string[]]
                    )
            ]);
        },
        {
            enabled: filterPropertiesList.length > 0,
            keepPreviousData: true
        }
    );
};
