import { hasStringProperty } from '@squaredup/utilities';
import React, { ReactNode } from 'react';
import { Controller, ControllerRenderProps, RegisterOptions, UseControllerReturn } from 'react-hook-form';
import Select, { components, MenuPosition, OptionProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { match, not, when } from 'ts-pattern';
import { MarkdownBase } from '../../markdown/MarkdownBase';
import {
    AutocompleteOption,
    AutocompleteOptions,
    ensureOptions,
    FixedOptions,
    isOptionsLoader as isOptionsLoaderCondition,
    OptionsLoader
} from '../jsonForms/autocompleteOptions';
import RemoteCreatableSelect from '../template-select/RemoteCreatableSelect';
import RemoteSelect from '../template-select/RemoteSelect';
import './Autocomplete.css';

export interface AutoCompleteProps
    extends Omit<
        React.ComponentProps<typeof RemoteCreatableSelect>,
        'options' | 'loadOptions' | 'formatCreateLabel' | 'getNewOptionData'
    > {
    name: string;
    validation?: RegisterOptions;
    defaultValue?: AutocompleteOption | AutocompleteOption[];
    options?: AutocompleteOptions;
    optionsKey?: string;
    isMulti?: boolean;
    disabled?: boolean;
    menuPortalTarget?: HTMLElement | null;
    onCreate?: (value: string, optionLabel?: ReactNode) => AutocompleteOption;
    onSelect?: (value: AutocompleteOption | AutocompleteOption[]) => void;
    isValidNewOption?: (value: string) => boolean;
    getDefaultValue?: () => AutocompleteOption[];
    formatCreateLabel?: (value: string) => string;
    /**
     * Defaults to 'optionObject'.
     *
     * If set to 'valueString', the form will receive the value property of
     * the selected option rather than the whole option object.
     *
     * Can only be 'valueString' when options are fixed (not loaded dynamically)
     * and option creation is disabled (onCreate is not set).
     */
    selectOptionsAs?: 'valueString' | 'optionObject';
}

const CustomDataSourceOption = (props: OptionProps<unknown>) => {
    const options = props.data as AutocompleteOption;
    return (
        <components.Option {...props}>
            <div className='text-sm'>
                <div className='autocomplete__label'>{options.label}</div>
                {options.description && options.description?.length !== 0 && (
                    <span className='text-textSecondary'>
                        <MarkdownBase content={options.description} />
                    </span>
                )}
            </div>
        </components.Option>
    );
};

const isOptionsLoader = when(isOptionsLoaderCondition);
/**
 * Determine if a value is non-empty, i.e. the autocomplete with
 * this value has at least one selected option
 */
const isNonEmptyValue = (v: unknown) => v != null && v !== '' && (!Array.isArray(v) || v.length > 0);

export const getOptionValue = (
    optionOrValue: AutocompleteOption | AutocompleteOption[] | unknown
): string | string[] | unknown => {
    if (Array.isArray(optionOrValue)) {
        return optionOrValue.map((v) => getOptionValue(v) as string);
    }

    return hasStringProperty(optionOrValue, 'value') ? optionOrValue.value : optionOrValue;
};

/**
 * Get the parsed data property of an option, if it exists.
 */
export const getOptionData = (
    optionOrValue: AutocompleteOption | AutocompleteOption[] | unknown
): Record<string, unknown> | undefined | (Record<string, unknown> | undefined)[] => {
    if (Array.isArray(optionOrValue)) {
        if (optionOrValue.length === 0) {
            return undefined;
        }

        const dataArray = optionOrValue.map((v) => getOptionData(v) as Record<string, unknown>);

        if (dataArray.every((d) => d == null)) {
            return undefined;
        }

        return dataArray;
    }

    if (hasStringProperty(optionOrValue, 'data')) {
        try {
            return JSON.parse(optionOrValue.data) as Record<string, unknown>;
        } catch {
            return undefined;
        }
    }

    return undefined;
};

export const autocompleteDropdownList = '.autocomplete__menu-list';

/**
 * Default onCreate handler that creates an option with the
 * value that the user entered
 */
export const defaultOnCreate = (value: string, optionLabel: ReactNode): AutocompleteOption => ({
    value,
    label: optionLabel?.toString() || value
});

/**
 * Autocomplete
 * Renders an autocomplete input with the ability to create
 * options on the fly - fully integrated with our validation framework.
 *
 * Wrapper around React-Select and all supplied properties
 * are passed to CreatableSelect.
 *
 * @example
 * `<Autocomplete name='tags' onCreate={asyncCreateTag} />`
 */
function Autocomplete({
    name,
    validation,
    options,
    isMulti = true,
    selectOptionsAs = 'optionObject',
    optionsKey,
    disabled,
    menuPosition,
    menuPortalTarget,
    onCreate,
    isValidNewOption,
    onSelect,
    getDefaultValue,
    formatCreateLabel = (value: string) => `Add "${value}"`,
    ...props
}: AutoCompleteProps) {
    if (typeof options === 'function' && selectOptionsAs === 'valueString') {
        throw new Error("selectOptionsAs can only be 'valueString' when options are fixed (not loaded dynamically)");
    }

    const commonProps = {
        classNamePrefix: 'autocomplete',
        name,
        isClearable: true,
        isMulti,
        backspaceRemovesValue: true,
        allowCreateWhileLoading: false,
        key: optionsKey,
        menuPosition: 'fixed' as MenuPosition,
        menuShouldBlockScroll: false,
        menuShouldScrollIntoView: false,
        classNames: { menuPortal: () => '!z-50' },
        ...(menuPosition && { menuPosition }),
        ...(menuPortalTarget && { menuPortalTarget }),
        getNewOptionData: onCreate,
        isValidNewOption,
        formatCreateLabel
    };

    const DynamicOptionsWithCreate =
        (optionsLoader: OptionsLoader) =>
        ({ field: { name: fieldName, value, onChange, ref }, formState }: UseControllerReturn) => {
            /*
             * Clear the field if we have a value but can't load options - this means that
             * we previously were able to load options (to select a value), but now can't,
             * probably because field references are no longer valid. E.g. the user has
             * cleared a required referenced field.
             */
            if (value === '' || (!optionsLoader.canLoad && isNonEmptyValue(value))) {
                onChange(null);
            }

            optionsLoader.onLoad(() => {
                if (isNonEmptyValue(value)) {
                    onChange(optionsLoader.getOption(value));
                    return;
                }

                /**
                 * The options finished loading and we have no value set,
                 * so we set the default value, if it exists.
                 */
                const defaultValue = formState.defaultValues?.[fieldName];

                if (defaultValue != null) {
                    onChange(optionsLoader.getOption(defaultValue, { excludeMissingOptions: true }));
                }
            });

            return (
                <RemoteCreatableSelect
                    ref={ref}
                    value={optionsLoader.getOption(value)}
                    loadOptions={optionsLoader.loadOptions}
                    blurInputOnSelect={true}
                    onChange={(v) => {
                        onChange(v);
                        onSelect?.(v as AutocompleteOption | AutocompleteOption[]);
                    }}
                    components={{
                        Option: CustomDataSourceOption,
                        SingleValue: components.SingleValue
                    }}
                    {...commonProps}
                    {...props}
                    isDisabled={(props.isDisabled ?? false) || !optionsLoader.canLoad}
                />
            );
        };

    const DynamicOptionsNoCreate =
        (optionsLoader: OptionsLoader) =>
        ({ field: { name: fieldName, value, onChange, ref }, formState }: UseControllerReturn) => {
            if (value === '' || (!optionsLoader.canLoad && isNonEmptyValue(value))) {
                onChange(null);
            }

            optionsLoader.onLoad(() => {
                if (isNonEmptyValue(value)) {
                    onChange(optionsLoader.getOption(value));
                    return;
                }

                const defaultValue = formState.defaultValues?.[fieldName];

                if (defaultValue != null) {
                    onChange(optionsLoader.getOption(defaultValue, { excludeMissingOptions: true }));
                }
            });

            return (
                <RemoteSelect
                    ref={ref}
                    value={optionsLoader.getOption(value)}
                    loadOptions={optionsLoader.loadOptions}
                    blurInputOnSelect={true}
                    onChange={(v) => {
                        onChange(v);
                        onSelect?.(v as AutocompleteOption | AutocompleteOption[]);
                    }}
                    components={{
                        Option: CustomDataSourceOption,
                        SingleValue: components.SingleValue
                    }}
                    {...commonProps}
                    {...props}
                    isDisabled={(props.isDisabled ?? false) || !optionsLoader.canLoad}
                />
            );
        };

    const FixedOptionsWithCreate =
        (fixedOptions: FixedOptions) =>
        ({ field: { value, onChange, ref } }: { field: ControllerRenderProps }) => {
            if (value === '') {
                onChange(undefined);
            }
            return (
                <CreatableSelect
                    ref={ref}
                    blurInputOnSelect={true}
                    value={ensureOptions(fixedOptions, value)}
                    options={fixedOptions}
                    onChange={(v) => {
                        if (selectOptionsAs === 'valueString') {
                            onChange(getOptionValue(v));
                        } else {
                            onChange(v);
                        }
                        onSelect?.(v as AutocompleteOption | AutocompleteOption[]);
                    }}
                    components={{
                        Option: CustomDataSourceOption,
                        SingleValue: components.SingleValue
                    }}
                    {...commonProps}
                    {...props}
                />
            );
        };

    const FixedOptionsNoCreate =
        (fixedOptions: FixedOptions) =>
        ({ field: { value, onChange, ref } }: { field: ControllerRenderProps }) => {
            /**
             * value seems to sometimes gets set to empty string when the autocomplete isn't part
             * of a form and we're using onSelect etc. to react to changes. This doesn't break
             * anything but it does cause the placeholder to not appear, which looks a bit odd as
             * the value can end up set to empty string without the user interacting with the field.
             *
             * We do the same for each of the autocomplete types so they're consistent at least.
             */
            if (value === '') {
                onChange(undefined);
            }
            return (
                <Select
                    ref={ref}
                    value={ensureOptions(fixedOptions, value)}
                    options={fixedOptions}
                    blurInputOnSelect={true}
                    onChange={(v) => {
                        if (selectOptionsAs === 'valueString') {
                            onChange(getOptionValue(v));
                        } else {
                            onChange(v);
                        }
                        onSelect?.(v as AutocompleteOption | AutocompleteOption[]);
                    }}
                    components={{
                        Option: CustomDataSourceOption,
                        SingleValue: components.SingleValue
                    }}
                    {...commonProps}
                    {...props}
                />
            );
        };

    const SelectComponentRenderer = match({ options, allowCreate: onCreate != null })
        .with({ options: isOptionsLoader, allowCreate: true }, ({ options: o }) => DynamicOptionsWithCreate(o))
        .with({ options: isOptionsLoader, allowCreate: false }, ({ options: o }) => DynamicOptionsNoCreate(o))
        .with({ options: not(isOptionsLoader), allowCreate: true }, ({ options: o }) => FixedOptionsWithCreate(o ?? []))
        .with({ options: not(isOptionsLoader), allowCreate: false }, ({ options: o }) => FixedOptionsNoCreate(o ?? []))
        .exhaustive();

    return (
        <div className='w-full min-w-0' data-testid='autocomplete' aria-label={name}>
            <Controller
                name={name}
                rules={
                    validation?.required === true
                        ? {
                              ...validation,
                              validate: {
                                  // Treat empty arrays as 'not set' for validation purposes
                                  required: isNonEmptyValue
                              }
                          }
                        : validation
                }
                defaultValue={props.defaultValue}
                render={SelectComponentRenderer}
            />
        </div>
    );
}

export default Autocomplete;
