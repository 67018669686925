/* eslint-disable no-console */
import {
    ClientDataStreamRequest,
    DataStreamOptions,
    DataStreamWarning,
    defaultFormatSpec,
    emptyGroupingSpec,
    emptySortSpec,
    FormattedStreamData,
    noData,
    StreamData
} from '@squaredup/data-streams';
import { getTimeframe } from '@squaredup/timeframes';
import { DashboardContextValue } from 'contexts/DashboardContext';
import {
    ClientDataStreamsContextValue,
    useClientDataStreamsContext
} from 'dashboard-engine/dataStreams/clientDataStreams/ClientDataStreamsContext';
import { streamDataKeys } from 'queries/queryKeys/streamDataKeys';
import { useQuery, UseQueryOptions, UseQueryResult, type QueryKey } from 'react-query';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';
import { requestData } from '../../services/DataStreamService';

/**
 * Default refetch interval to use if the server doesn't tell us what the interval should be via validFor
 * in the data stream response, or there is an error fetching the data stream.
 */
const DEFAULT_REFETCH_INTERVAL_MS = 5 * 60 * 1000; // 5 mins

export const emptyDataStreamOptions: DataStreamOptions = {
    group: emptyGroupingSpec,
    sort: emptySortSpec
};

export type Context = Pick<DashboardContextValue, 'timeframe'>;

export type UseDataStreamResult = Omit<UseQueryResult<Readonly<StreamData>, unknown>, 'data'> & {
    data: Readonly<StreamData>; // avoid data being optional
    /**
     * True if the data has been loaded, false if data is noData because we're waiting on a response
     */
    isLoaded: boolean;
    warnings?: (string | DataStreamWarning)[];
    errors?: unknown;
    isLoadingOrPreviousData: boolean;
};

const enableFormattingIfMetadataConfigured = (options: DataStreamOptions): DataStreamOptions => {
    /**
     * SQL analytics sets noNumberFormatting to true by default, so if we set metadata
     * we need to explicitly disable it for the metadata formatting to have an effect on numbers.
     */
    if (options.metadata != null && options.metadata.length > 0) {
        return { ...options, format: { ...options.format, noNumberFormatting: false } };
    }

    return options;
};

export const parseUseDataStreamArgs = (
    request: ClientDataStreamRequest | undefined,
    queryOptions?: UseDataStreamQueryOptions
): { queryKey: QueryKey; resolvedOptions: UseDataStreamQueryOptions } & (
    | { isDataStreamSelected: false }
    | {
          isDataStreamSelected: true;
          dataStreamRequest: ClientDataStreamRequest;
      }
) => {
    if (!request) {
        return {} as ReturnType<typeof parseUseDataStreamArgs>;
    }

    const {
        dataStreamId,
        dataStreamName,
        options: dataStreamOptions,
        timeframe,
        scope,
        pluginConfigId,
        dataSourceConfig,
        context
    } = request ?? {};

    dataStreamOptions.format = {
        ...defaultFormatSpec,
        ...dataStreamOptions.format
    };

    const options = enableFormattingIfMetadataConfigured(dataStreamOptions) || emptyDataStreamOptions;
    const { extraKeys, ...resolvedOptions } = queryOptions ?? {};

    const queryKey = streamDataKeys.forRequest(request, extraKeys);

    // Resolve time frame based on enum
    // TODO: once time frames are made more flexible - amend how the time frame is resolved
    const resolvedTimeframe = getTimeframe(timeframe);

    if (!dataStreamId) {
        return { queryKey, resolvedOptions, isDataStreamSelected: false };
    }

    const dataStreamRequest: ClientDataStreamRequest = {
        dataStreamId,
        dataStreamName,
        options,
        timeframe: resolvedTimeframe,
        pluginConfigId,
        scope,
        dataSourceConfig,
        context
    };

    return {
        queryKey,
        resolvedOptions: {
            ...resolvedOptions,
            ...queryOptions?.dataStreamOptions
        },
        isDataStreamSelected: true,
        dataStreamRequest
    };
};

export const getDataStreamDataFn = (
    args:
        | { isDataStreamSelected: false }
        | {
              isDataStreamSelected: true;
              dataStreamRequest: ClientDataStreamRequest;
          },
    workspaceId: string | null | undefined,
    clientDataStreams?: ClientDataStreamsContextValue,
    suppressToast?: boolean
) => {
    return async () => {
        try {
            if (!args.isDataStreamSelected) {
                throw new Error('No data stream selected');
            }

            // Bypass client datastream (for now) if there's any variables
            if (
                clientDataStreams?.isClientDataStreamsEnabled &&
                args.dataStreamRequest.options.noCacheRead !== true &&
                !args.dataStreamRequest.context
            ) {
                const clientResult = await clientDataStreams.runClientSide(args.dataStreamRequest);

                if (clientResult.succeeded) {
                    return clientResult.value;
                }
            }

            return await requestData(args.dataStreamRequest, suppressToast, workspaceId);
        } catch (e) {
            console.error('Data stream failed:', e);
            throw e;
        } finally {
            console.groupEnd();
        }
    };
};

const refetchInterval = (data: FormattedStreamData | undefined): number | false => {
    if (data?.metadata?.validForSeconds) {
        // Server has given us a hint for how long to wait until we refetch the data.
        return data.metadata.validForSeconds * 1000;
    }
    // Don't have a validFor from the server, perhaps because there was an error. So just use our default.
    return DEFAULT_REFETCH_INTERVAL_MS;
};

export type UseDataStreamQueryOptions = Omit<
    UseQueryOptions<FormattedStreamData, unknown, FormattedStreamData, QueryKey>,
    'queryKey' | 'queryFn'
> & {
    extraKeys?: QueryKey;
    dataStreamOptions?: Omit<DataStreamOptions, 'sort' | 'filter' | 'group' | 'format' | 'metadata' | 'purpose'>;
};

export function useDataStream(
    request: ClientDataStreamRequest | undefined,
    workspaceId?: string | null,
    queryOptions?: UseDataStreamQueryOptions
): UseDataStreamResult {
    const args = parseUseDataStreamArgs(request, queryOptions);
    const clientDataStreams = useClientDataStreamsContext();
    const { inEditor: suppressToast } = useTileEditorContext();

    const result = useQuery(
        args.queryKey ?? 'emptyDatastreamRequest',
        getDataStreamDataFn(args, workspaceId, clientDataStreams, suppressToast),
        {
            staleTime: 300_000,
            cacheTime: 60_000,
            enabled: Boolean(request),
            retry: 0,
            keepPreviousData: true,
            refetchInterval,
            ...args.resolvedOptions
        }
    );

    if (
        clientDataStreams.isClientDataStreamsEnabled &&
        args.isDataStreamSelected &&
        /**
         * Don't associate data for a previous query key with
         * the new query key.
         */
        !result.isPreviousData &&
        result.data?.metadata.source === 'server'
    ) {
        clientDataStreams.setBase(args.dataStreamRequest, result.data);
    }

    return {
        ...result,
        data: result.data ?? noData,
        isLoaded: result.data != null,
        warnings: result.data?.metadata.warnings || [],
        isLoadingOrPreviousData: result.isLoading || result.isPreviousData
    };
}

export default useDataStream;
