import { Divider } from '@/components/Divider';
import { AccordionMultipleProps } from '@radix-ui/react-accordion';
import { ChoiceChips } from 'components/forms/choiceChips/ChoiceChips';
import Field from 'components/forms/field/Field';
import { OptionToggle } from 'components/visualisationOptions/OptionToggle';
import { VisualisationOptionAction } from 'dashboard-engine/types/Visualisation';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useFormChange } from 'ui/editor/dataStream/hooks/useFormChange';
import { FormDropdown } from '../DataStreamBarChart/components/FormDropdown';
import { VisualisationConfigAccordion } from '../VisualisationConfigAccordion';
import { DataStreamDonutConfig } from './Config';

const legendPositionOptions = [
    { label: 'Auto', value: 'auto' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Top', value: 'top' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' }
];

interface DonutConfigurationFormProps {
    config: DataStreamDonutConfig;
    accordionControls: AccordionMultipleProps;
    onChange: (action: VisualisationOptionAction) => void;
}

export const DonutConfigurationForm = ({ config, accordionControls, onChange }: DonutConfigurationFormProps) => {
    const defaultValues = {
        legendMode: config.legendMode ?? 'inline',
        legendPosition: config.legendPosition ?? 'auto',
        showValuesAsPercentage: config.showValuesAsPercentage ?? false
    };

    const formMethods = useForm({
        mode: 'onChange',
        defaultValues
    });

    const { watch, handleSubmit } = formMethods;

    useFormChange(watch, (data) => {
        onChange({ action: 'form-change', data });
    });

    return (
        <FormProvider {...formMethods}>
            <form className='w-full' onSubmit={handleSubmit((data) => onChange({ action: 'form-change', data }))}>
                <VisualisationConfigAccordion value='legend' label='Legend' accordionControls={accordionControls}>
                    <ChoiceChips
                        name='legendMode'
                        label='Type'
                        options={[
                            {
                                label: 'Inline',
                                value: 'inline'
                            },
                            {
                                label: 'Table',
                                value: 'table'
                            }
                        ]}
                        defaultValue={config?.legendMode ?? 'inline'}
                    />
                    {config?.legendMode === 'table' && (
                        <Field label='Position' className='mt-[9px]'>
                            <FormDropdown name='legendPosition' options={legendPositionOptions} />
                        </Field>
                    )}
                </VisualisationConfigAccordion>
                <Divider />
                <VisualisationConfigAccordion value='options' label='Options' accordionControls={accordionControls}>
                    <Controller
                        name='showValuesAsPercentage'
                        render={({ field: { onChange: toggleChange, value, onBlur } }) => (
                            <OptionToggle
                                name='showValuesAsPercentage'
                                label='Show as percentage'
                                checked={value}
                                wrapperClassName='mt-3'
                                onChange={(data) => {
                                    toggleChange(data);
                                    onBlur();
                                }}
                            />
                        )}
                    />
                </VisualisationConfigAccordion>
            </form>
        </FormProvider>
    );
};
