import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataStreamBaseTileConfig, isNoData } from '@squaredup/data-streams';
import { LoadingOverlay } from 'components/LoadingOverlay';
import NoDataPlaceholder from 'components/NoDataPlaceholder';
import VisualisationSkeleton from 'components/VisualisationSkeleton';
import { useDashboardContext } from 'contexts/DashboardContext';
import { useTileContext } from 'contexts/TileContext';
import VisualisationContext from 'contexts/VisualisationContext';
import { DataCriteriaList } from 'dashboard-engine/dataStreams/DataCriteriaList';
import { DataStreamErrors } from 'dashboard-engine/dataStreams/DataStreamErrors';
import { matchVisualisations } from 'dashboard-engine/dataStreams/VisualisationMatching';
import { VisualizationState } from 'dashboard-engine/hooks/useVisualizationState';
import { DataStreamVisualisation } from 'dashboard-engine/types/Visualisation';
import { hasTable } from 'dashboard-engine/util/getIsDataStreamConfigured';
import { parseMonitorConfig } from 'dashboard-engine/util/monitoring';
import stringify from 'fast-json-stable-stringify';
import { dashboardHasNoVariableObjectsSelected } from 'pages/dashboard/components/utils/variableDropdown';
import { useDashboardMetricsContext } from 'pages/dashboard/DashboardMetrics';
import { useCallback, useMemo } from 'react';
import { useTileEditorContext } from 'ui/editor/dataStream/contexts/TileEditorContext';
import { useDataStreamType } from 'ui/editor/dataStream/TileEditor/hooks/useDataStreamType';
import { useTileEditorScopes } from 'ui/editor/dataStream/TileEditor/hooks/useScopes';
import { NewTilePrompt } from 'ui/editor/dataStream/TileEditor/newLayout/NewTilePrompt';
import { useIsNewEditorLayout } from 'ui/editor/dataStream/TileEditor/newLayout/useIsNewEditorLayout';
import { DATASTREAM_MESSAGES } from 'ui/tile/TileWarnings';
import { useDataStreamConfig } from '../hooks/useDataStreamConfig';
import { useResolvedConfig } from '../hooks/useResolvedConfig';
import { visualisationOptionsRepo, visualisationsRepo } from '../repositories/visualisationsRepo';

interface DataStreamBaseTileProps {
    config: DataStreamBaseTileConfig;
    // Visualisation is dynamically chosen, so show fallback skeleton as can change between renders
    hasDynamicVisualisation?: boolean;
    isLoading?: boolean;
    initialVisualizationState?: VisualizationState | undefined;
    notConfiguredError?: JSX.Element;
    /** Pass local viz state you want synced to fullscreen tile, merges state */
    onVisualizationStateChange?: (state: VisualizationState) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const DataStreamBaseTile: React.FC<DataStreamBaseTileProps> = ({
    config: tileConfig,
    hasDynamicVisualisation,
    initialVisualizationState,
    isLoading: isForceLoading,
    notConfiguredError,
    onVisualizationStateChange,
    onClick
}) => {
    const config = useMemo(() => tileConfig, [stringify(tileConfig)]); // eslint-disable-line react-hooks/exhaustive-deps

    const { data, isLoading, isFetched, isPreviousData, error, isConfiguredStrict, isConfigured } = useDataStreamConfig(
        config,
        {},
        'dashboard'
    );
    const dashboardContext = useDashboardContext();
    const tileEditorContext = useTileEditorContext();
    const { preview, health, tileId, onChange } = useTileContext();
    const newEditorLayout = useIsNewEditorLayout();

    const { reportTileEnd } = useDashboardMetricsContext();

    const tileScope = config.scope;
    const { data: scopesData, isFetching: isFetchingScopes } = useTileEditorScopes();
    const checkTileScope = !isFetchingScopes && tileScope != null && 'scope' in tileScope;
    const doesScopeExist = scopesData?.some((scope) => checkTileScope && scope.id === tileScope.scope);

    const { dataStreamType } = useDataStreamType(config);

    const isTileWithDataStream = Boolean(config?.dataStream);
    const isSqlAndTableSelected = dataStreamType && hasTable(config?.dataStream);

    const updateVisualisationConfig = useCallback(
        (content: DataStreamBaseTileConfig) => {
            if (!dashboardContext.editing || config?._type !== 'tile/data-stream') {
                return;
            }

            const configType = config?.visualisation?.type;
            if (!configType) {
                return;
            }

            const existingConfig = config?.visualisation?.config?.[configType] ?? {};

            onChange?.({
                ...config,
                visualisation: {
                    config: {
                        [configType]: {
                            ...existingConfig,
                            ...content
                        }
                    },
                    type: configType
                }
            });
        },
        [config, dashboardContext.editing, onChange]
    );

    const hasData = !isNoData(data);

    const [visualisationType, Visualisation, dataMatch, defaultConfig] = useMemo(() => {
        const visualizationConfig =
            config.visualisation?.type && config?.visualisation?.config?.[config.visualisation?.type];

        const validVisualisations = matchVisualisations(data, visualizationConfig);

        if (typeof config?.visualisation?.type === 'string') {
            if (preview || validVisualisations.includes(config?.visualisation?.type)) {
                return [
                    config.visualisation.type,
                    visualisationsRepo.get(config.visualisation.type) as DataStreamVisualisation<any>,
                    visualisationOptionsRepo.get(config.visualisation.type).matchesData(data, visualizationConfig),
                    visualisationOptionsRepo.get(config.visualisation.type).getDefaultConfig?.(data) || {}
                ];
            }
        }

        if (hasData) {
            const [bestVisualisation] = validVisualisations;
            return [bestVisualisation, visualisationsRepo.get(bestVisualisation) as DataStreamVisualisation<any>];
        }

        return [];
    }, [data, config, preview, hasData]);

    const visualisationConfig = useResolvedConfig(
        (visualisationType && config?.visualisation?.config?.[visualisationType]) || defaultConfig || {},
        dashboardContext,
        data
    );

    const noVariableObjectsSelected = dashboardHasNoVariableObjectsSelected(dashboardContext.variables);

    if (!doesScopeExist && checkTileScope && tileScope.scope && scopesData) {
        return (
            <div
                className='flex flex-col justify-center w-full h-full text-center whitespace-normal'
                data-testid='dataStreamBaseTileError'
            >
                <DataStreamErrors
                    error={DATASTREAM_MESSAGES.SCOPE_NOT_FOUND}
                    showDetailedErrors={dashboardContext.showDetailedErrors}
                    showDetailInline={tileEditorContext.inEditor}
                />
            </div>
        );
    }

    const hasScopeIfRequired =
        config?.scope ||
        !dataStreamType.supportsScope ||
        (!dataStreamType.requiresScope && config?.dataStream?.pluginConfigId != null);

    if (!isConfiguredStrict && notConfiguredError) {
        return notConfiguredError;
    }

    if (!hasScopeIfRequired) {
        return <NoDataPlaceholder message='No objects selected' />;
    }

    if (!isTileWithDataStream || (dataStreamType.supportsDatasets && !isSqlAndTableSelected)) {
        return newEditorLayout && preview ?
            <NewTilePrompt /> : 
            <NoDataPlaceholder message='No data stream selected' />;
    }

    // Show a message if the tile has not yet been configured
    if (dashboardContext.editing && preview && !isConfiguredStrict) {
        return <NoDataPlaceholder message='Missing required parameters' />;
    }

    if (!isLoading && error === null && !isConfigured) {
        return <NoDataPlaceholder message='Missing required parameters' />;
    }

    if (!isForceLoading && noVariableObjectsSelected && config.variables?.length) {
        return (
            <NoDataPlaceholder
                message='No objects selected for variable'
                icon={<FontAwesomeIcon icon={faBarsFilter} fixedWidth={true} className='mr-2 text-2xl' />}
            />
        );
    }

    if (isForceLoading || isLoading || (!isFetched && dataMatch === undefined)) {
        return (
            <div className='size-full'>
                <VisualisationSkeleton
                    config={config.visualisation?.type && config?.visualisation?.config?.[config.visualisation?.type]}
                    // if visualisation is dynamically chosen, use fallback as can change as data loads
                    type={!hasDynamicVisualisation ? config.visualisation?.type : undefined}
                    className='size-full loading-spinner'
                />
            </div>
        );
    }

    // When an unsupported timeframe is met display placeholder
    if (isFetched && error === null && dataMatch === undefined) {
        tileId && reportTileEnd(tileId, Date.now(), true);
        return <NoDataPlaceholder />;
    }

    tileId && reportTileEnd(tileId, Date.now(), Boolean(!error && dataMatch?.success));

    return !error && dataMatch?.success ? (
        <>
            {Visualisation && (
                <LoadingOverlay loading={isPreviousData}>
                    <VisualisationContext.Provider value={{ updateVisualisationConfig }}>
                        <Visualisation
                            key={'visualisation'} // ensure we don't lose state when re-rendering
                            data={data}
                            config={visualisationConfig}
                            monitorConditions={parseMonitorConfig(config?.monitor)}
                            monitorConfig={config?.monitor}
                            healthState={health?.state}
                            shapingConfig={{
                                filter: config.dataStream?.filter,
                                group: config.dataStream?.group,
                                sort: config.dataStream?.sort
                            }}
                            initialState={initialVisualizationState}
                            onStateChange={onVisualizationStateChange}
                            onClick={onClick}
                        />
                    </VisualisationContext.Provider>
                </LoadingOverlay>
            )}
            {isFetched && !Visualisation && <NoDataPlaceholder />}
        </>
    ) : (
        <div
            className='flex flex-col justify-center w-full h-full text-center whitespace-normal'
            data-testid='dataStreamBaseTileError'
        >
            {error && (
                <DataStreamErrors
                    error={error}
                    showDetailedErrors={dashboardContext.showDetailedErrors}
                    showDetailInline={tileEditorContext.inEditor}
                />
            )}
            {dataMatch && !dataMatch?.success && (
                <DataCriteriaList key={config?.visualisation?.type} criteria={dataMatch.criteria} />
            )}
        </div>
    );
};

export default DataStreamBaseTile;
