import { cn } from '@/lib/cn';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    shimmer?: boolean
}

function Skeleton({ className, shimmer = true, ...props }: SkeletonProps) {
    return <div 
        className={cn(
            shimmer && 'skeleton-shimmer',
            !shimmer && 'skeleton',
            'rounded-md', className
        )} 
        {...props} 
    />;
}

export { Skeleton };
