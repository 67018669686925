import { FormattedStreamValue } from '@squaredup/data-streams';
import Tooltip from 'components/tooltip/Tooltip';
import { NavLink } from 'react-router-dom';
import { useDrilldownContext } from 'ui/editor/dataStream/contexts/DrilldownContext';

export const DrilldownCell: React.FC<FormattedStreamValue & { href: string }> = ({ href, raw, formatted }) => {
    const rawString = String(raw ?? '');

    const { isDrilldownEnabled } = useDrilldownContext();

    return (
        <Tooltip disabled={false} className='truncate text-textLink' title={rawString}>
            <NavLink
                to={href}
                className='inline-block w-full h-full underline'
                onClick={(e) => e.stopPropagation()}
                target={isDrilldownEnabled ? '_self' : '_blank'}
            >
                {formatted}
            </NavLink>
        </Tooltip>
    );
};
