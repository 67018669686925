import { GlobalTenantId } from '@squaredup/constants';
import LoadingSpinner from 'components/LoadingSpinner';
import { useDashboards } from 'queries/hooks/useDashboards';
import { useDashboardsForWorkspace } from 'queries/hooks/useDashboardsForWorkspace';
import { useDatasourceConfigsForWorkspace } from 'queries/hooks/useDatasourceConfigsForWorkspace';
import { useWorkspaces } from 'queries/hooks/useWorkspaces';
import { Navigate } from 'react-router';

export default function IndexRedirect() {
    // If 1 workspace with no dashboards or datasources, redirect to workspace homepage
    // Otherwise, redirect to status page
    const { data: workspaces = [], isLoading: isLoadingWorkspaces } = useWorkspaces();
    const { data: dashboardsInTenant, isLoading: isLoadingDashboardsInTenant } = useDashboards({
        select: (data) => data.filter(({ workspaceId }) => workspaceId !== `space-${GlobalTenantId}`)
    });
    
    const workspaceId = workspaces?.[0]?.id;

    const { data: dashboards = [], isLoading: isLoadingDashboards } = useDashboardsForWorkspace(workspaceId);
    const { data: datasourceConfigs = [], isLoading: isLoadingDatasourceConfigs } = 
        useDatasourceConfigsForWorkspace(workspaceId);

    const isLoading = isLoadingWorkspaces || 
        isLoadingDashboards || 
        isLoadingDatasourceConfigs ||
        isLoadingDashboardsInTenant;

    if (isLoading) {
        return <LoadingSpinner className='mt-8' />;
    }

    // If a tenant has at least one workspace, no dashboards and no linked plugins push user to 
    // datasources page in the first workspace
    if (workspaceId && dashboardsInTenant?.length === 0) {
        const hasLinkedDatasources = workspaces.some(({ data }) => Boolean(data.links?.plugins?.length));

        if (!hasLinkedDatasources) {
            return <Navigate to={`/datasources/${workspaceId}`} replace={true} />;
        }
    }

    if (!isLoading && 
        workspaces.length === 1 && 
        dashboards.length === 0 && 
        datasourceConfigs.length === 0
    ) {
        return <Navigate to={`/workspace/${workspaceId}`} replace={true} />;
    };

    return <Navigate to='/status' replace={true} />;
}
