import {
    FormattedStreamData,
    FormattedStreamValue,
    FoundColumn,
    RowData,
    StreamDataColumn,
    date,
    findColumn,
    findColumns,
    isNone,
    preferred,
    required,
    state,
    string,
    url
} from '@squaredup/data-streams';
import { HealthState, stateStrings } from '@squaredup/monitoring';
import { Result } from '@squaredup/utilities';
import { dataMatchCriteria } from 'dashboard-engine/dataStreams/dataMatchCriteria';
import { findColumnsWithSourceId, getDrilldownUrlFor, getValueIfAllSame } from 'dashboard-engine/util/drilldown';
import { makeFormatter } from 'dashboard-engine/util/valueToString';
import type { StatusBlockDataItem } from 'pages/status/ui/blocks/StatusBlock';
import type { SetOptional } from 'type-fest';
import { DataStreamBlocksConfig } from './Config';

export const findBlocksStateColumn = (columns: StreamDataColumn[]) =>
    findColumn(columns, required('shapeName', state.name));

export const findBlocksLabelColumn = (columns: StreamDataColumn[]) =>
    findColumn(
        columns,
        preferred('valueShapeName', string.name),
        preferred.not('shapeName', state.name),
        preferred.not('shapeName', date.name),
        preferred('role', 'label')
    );

export const findBlocksLinkColumn = (columns: StreamDataColumn[]): Result<FoundColumn> => {
    const column = findBlocksLinkColumns(columns)[0];
    if (column) {
        return Result.success(column);
    }
    return Result.fail('Could not find column');
};

export const findBlocksLinkColumns = (columns: StreamDataColumn[]) => {
    const findColumnsWithShapeUrl = findColumns(columns, required('shapeName', url.name));
    const findColumnsWithRoleLink = findColumns(columns, required('role', 'link'));
    const findColumnsWithSourceIdResult = findColumnsWithSourceId(columns);

    const columnsWithSourceId = findColumnsWithSourceIdResult.succeeded ? findColumnsWithSourceIdResult.value : [];
    const columnsWithShapeUrl = findColumnsWithShapeUrl.succeeded ? findColumnsWithShapeUrl.value : [];
    const columnsWithRoleLink = findColumnsWithRoleLink.succeeded ? findColumnsWithRoleLink.value : [];

    /**
     * allow auto drilldown option, if only one column has a sourceIdColumn
     */
    const sourceIdColumns = columnsWithSourceId.length === 1 ? [columnsWithSourceId[0]] : [];

    const uniqueRoles = columnsWithRoleLink.filter((c) => !columnsWithShapeUrl.includes(c));

    return [...sourceIdColumns, ...uniqueRoles, ...columnsWithShapeUrl];
};

export const getBlocksColumns = (columns: StreamDataColumn[], config?: DataStreamBlocksConfig) => {
    const blocksCriteria = dataMatchCriteria<{
        labelColumn: FoundColumn;
        stateColumn: FoundColumn;
        linkColumn: FoundColumn;
    }>();

    let labelColumn = findBlocksLabelColumn(columns);

    if (config?.labelColumn) {
        const column = findColumn(columns, required('name', config.labelColumn));
        if (column.succeeded) {
            labelColumn = column;
        }
    }

    if (labelColumn.failed) {
        blocksCriteria.fail('Missing Label column', labelColumn.reason);
    } else {
        blocksCriteria.pass('Automatically selected Label', {
            labelColumn: labelColumn.value
        });
    }

    let stateColumn = findBlocksStateColumn(columns);

    if (config?.stateColumn) {
        const column = findColumn(columns, required('name', config.stateColumn));
        if (column.succeeded) {
            stateColumn = column;
        }
    }

    if (stateColumn.failed) {
        //Do nothing as state is now optional for blocks.
    } else {
        blocksCriteria.pass('Automatically selected State', {
            stateColumn: stateColumn.value
        });
    }

    let linkColumn = findBlocksLinkColumn(columns);

    if (config?.linkColumn) {
        const column = findColumn(columns, required('name', config.linkColumn));
        if (column.succeeded) {
            linkColumn = column;
        }
    }

    if (linkColumn.succeeded && config?.linkColumn !== 'none') {
        blocksCriteria.pass('Automatically selected Link', {
            linkColumn: linkColumn.value
        });
    }

    return blocksCriteria;
};

export const matchesData = (data: FormattedStreamData | undefined, config?: DataStreamBlocksConfig) => {
    const columns = data?.metadata?.columns ?? [];
    return getBlocksColumns(columns, config);
};

/**
 * Generate link for status block, using either a shape_url column or a sourceIdColumn (drilldown)
 */
export const getBlockLink = (
    row: FormattedStreamValue[],
    columns: StreamDataColumn[],
    rowData?: RowData,
    linkColumn?: FoundColumn
) => {
    if (linkColumn?.column.shapeName === 'shape_url' || linkColumn?.column.role === 'link') {
        return row[linkColumn.dataIndex].value as string;
    }

    if (!linkColumn?.column.sourceIdColumn || !rowData?.pluginConfigIds) {
        return undefined;
    }

    const pluginConfigId = getValueIfAllSame(rowData.pluginConfigIds);
    const sourceIdColumn = findColumn(columns, required('name', linkColumn.column.sourceIdColumn));

    if (!pluginConfigId || !sourceIdColumn.succeeded || !row[sourceIdColumn.value.dataIndex]) {
        return undefined;
    }

    return getDrilldownUrlFor(row[sourceIdColumn.value.dataIndex].value as string, pluginConfigId);
};

export const getRawBlocksData = (
    data: FormattedStreamData,
    config: DataStreamBlocksConfig
): SetOptional<StatusBlockDataItem, 'id'>[] => {
    const { labelColumn, stateColumn, linkColumn } = getBlocksColumns(data.metadata.columns, config).throwIfFailed();
    const idColumn = findColumn(data.metadata.columns, required('role', 'id'));
    const type = findColumn(data.metadata.columns, required('displayName', 'Type'));
    const sourceName = findColumn(data.metadata.columns, required('displayName', 'Source Name'));
    const sourceType = findColumn(data.metadata.columns, required('displayName', 'Source Type'));
    const sublabelColumnResult = config.sublabel
        ? findColumn(data.metadata.columns, required('name', config.sublabel))
        : undefined;
    const sublabelColumn = sublabelColumnResult?.succeeded ? sublabelColumnResult.value : undefined;

    const subLabelformatter = makeFormatter(sublabelColumn?.column.shapeName);

    return (stateColumn ? data.rows.filter((r) => !isNone(r[stateColumn.dataIndex].value)) : data.rows).map((r, i) => {
        const link = getBlockLink(r, data.metadata.columns, data.metadata.rowData?.[i], linkColumn);

        return {
            name: r[labelColumn.dataIndex].formatted,
            state: stateColumn ? (r[stateColumn.dataIndex].value as HealthState) : stateStrings.unmonitored,
            ...(idColumn.succeeded && { id: r[idColumn.value.dataIndex].value as string }),
            ...(link && { link }),
            ...(type.succeeded && { type: r[type.value.dataIndex].formatted as string }),
            ...(sourceName.succeeded && { sourceName: r[sourceName.value.dataIndex].formatted as string }),
            ...(sourceType.succeeded && { sourceType: r[sourceType.value.dataIndex].formatted as string }),
            ...(sublabelColumn && {
                sublabel: r[sublabelColumn.dataIndex].formatted,
                sublabelDetail: subLabelformatter(r[sublabelColumn.dataIndex], { style: 'long' })
            })
        };
    });
};
