import { Tabs } from 'ui/Tabs';
import { AddDatasetButton } from './AddDatasetButton';
import { useDatasetContext } from 'ui/editor/dataStream/contexts/DatasetContext';

export const DatasetEditor = () => {
    const { datasetTabs, activeDataset, setActiveDataset, setActiveDataTab } = useDatasetContext();

    return (
        <Tabs
            tabs={datasetTabs}
            tabsID='datasetTabs'
            className='w-full h-full'
            tabListClassName='w-auto flex-initial'
            selectedIndex={activeDataset}
            onSelect={(index) => {
                setActiveDataset(index);
                setActiveDataTab(index + 1);
            }}
            additionalControl={
                <div className='flex px-4 space-x-4'>
                    <AddDatasetButton />
                </div>
            }
        />
    );
};
